import { Component, ViewChild, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiResponse, gridNames, PersistenceData, GridPersistRequests, OrderTypes } from 'src/app/shared/models/app.model';
import { GridComponent, EditService, ExcelExportProperties, CommandClickEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, gridActionBeginForFiltering, gridActionsForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-cycle-counts-open-exception',
  templateUrl: './cycle-counts-open-exception.component.html',
  styleUrls: ['./cycle-counts-open-exception.component.scss']
})
export class CycleCountOpenExceptionComponent implements OnInit {
  showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountOpenExceptionGrid;
  columns: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 420;
  showPopup: boolean =false;
  object;
  query: Query;
  public pageSettings: Object
  public selectionOptions: SelectionSettingsModel;
  adjustmentOrdersList = null
  showAdjustmentOrderListPopup: boolean = false;
  linkedPopupData: { id: string; type: string; } = null;
  disableCreateAdjustIcon = true
  @Output() exceptionExist = new EventEmitter();
  @Output() exceptionGridExist = new EventEmitter();
  @Output() adjustmentOrderPopupClosed = new EventEmitter();
  @Input() openExceptionList: any = []


  @ViewChild('cycleCountOpenExceptionGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  USER: any;
  preference;
  showCycleCountOpenExceptionGrid: boolean = false;

  constructor(
    private commonService : CommonService,
  ) {
    this.USER = this.commonService.USER;
  }

  ngOnInit(): void {
    this.selectionOptions = { type: 'Multiple', checkboxOnly: true };
    this.grid = null;
    this.getColumns();
    this.getData();
    this.commonService.getUserPreference(this.gridName, this.USER.employeeId).subscribe((res: ApiResponse) => {
      if (res && res.result && res.result.length && res.result[0].value) {
        this.preference = res.result[0].value;
        localStorage.setItem(`grid${this.gridName}`, this.preference);
      } else {
          localStorage.removeItem(`grid${this.gridName}`);
      }
      this.showCycleCountOpenExceptionGrid = true;
      this.showLoader = false;
    }, error => {
      localStorage.removeItem(`grid${this.gridName}`);
      this.showLoader = false;
    });
    this.showLoader = false;
  }

  getData() {
    let value =null;
    
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { type:'checkbox', width: 40 },
        { field: 'shipmentOrderId', headerText: 'LN Shipment No', type: 'String', format: 'n', textAlign: 'Left', width: 100, isPrimaryKey: true },
        { field: 'status', headerText: 'Status', type: 'String', format: 'n', textAlign: 'Left', width: 100 },
        { field: 'warehouseOrderLN', headerText: 'LN Warehouse No', type: 'String', format: 'n', textAlign: 'Left', width: 100 },
        { field: 'itemCode', headerText: 'Item', type: 'd', format: 'n', textAlign: 'Left', width: 100, },
        { field: 'itemDescription', headerText: 'Description', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
        { field: 'quantity', headerText: 'Qty Ordered', type: 'String', format: 'n', allowEditing: true, textAlign: 'Left', width: 100 , },
        { field: 'quantitySent', headerText: 'Qty Sent', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
        { field: 'quantityReceived', headerText: 'Qty Received', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
        { field: 'reasonDescription', headerText: 'Reason', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
        { field: 'exceptionStatus', headerText: 'Exception status', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
        // { field: 'createdDate', headerText: 'Action', type: 'String', format: 'n', textAlign: 'Left', width: 100 , },
      //   {
      //     field: 'Actions', headerText: 'Actions', width: 300, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
      //     commands: [
      //         { title: 'Approve', buttonOption: { iconCss: `fa fa-check`, cssClass: 'e-flat' } },
      //         { title: 'Reject', buttonOption: { iconCss: `fa fa-pause`, cssClass: 'e-flat' } },
      //     ]
      // }
      ]};
  }
  
  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
    if (GridPersistRequests.includes(args.requestType)) {
      this.savePersistance(grid);
  }
  }

  getStatusClass(status: string) {
    return this.commonService.getStatusClass(status);
  }

  setGridToolbar() {
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        // { id: 'reset-persistence',align: 'Right', prefixIcon: 'fas fa-sync', cssClass: '', tooltipText: 'Reset Persistence' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
        { text: '', id: 'create-adjust-order', prefixIcon: 'fas fa-balance-scale', cssClass: this.disableCreateAdjustIcon? 'e-flat disabled-grid-button' : 'e-flat', tooltipText: 'Create Adjustment Order' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
  }

  onGridCreated() {
    if(this.grid) updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  rowSelected() {
    let selectedExceptions : any = this.grid.getSelectedRecords();
    if (selectedExceptions.length > 0) {
      this.disableCreateAdjustIcon = false
    } else {
      this.disableCreateAdjustIcon = true
    }
    this.setGridToolbar()
  }

  rowDeselected() {
    let selectedExceptions : any = this.grid.getSelectedRecords();
    if (selectedExceptions.length == 0) {
      this.disableCreateAdjustIcon = true
    }
    this.setGridToolbar()
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'create-adjust-order') {
      let selectedExceptions : any = this.grid.getSelectedRecords();
     
      this.commonService.showConfirmation(`Do you want to Approve all selected exceptions?`, 'Yes', 'No', 'Approve All Exceptions?')
      .then( result => {
          if ( result.isConfirmed ) {
              this.showLoader = true;
              this.approveAllExceptions(selectedExceptions)
          }
      });
      this.showPopup = true;
    }
    if (args.item.id === 'excel-export') {
        const dataSource = getExcelDataSource(this.grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Open Exceptions (${getCurrentDate()}).xlsx`
        };
        this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    } else if (args.item.id === 'clear-filter') {
        this.grid.clearFiltering();
        this.grid.search('');
    }  else if (args.item.id === 'refresh') {
      this.getData();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountOpenExceptionGrid, value: '' }).subscribe((response) => {
            grid.search('');
            grid.clearFiltering();
            grid.clearSorting();
            this.showLoader = false;
          });
        }
        else{
          this.showLoader = false;
          return
        }
      });
    }
  }

  openShipmentOrderPopup(shipmentOrderId){
    this.linkedPopupData = {
        id: shipmentOrderId,
        type: OrderTypes.shipmentOrder
    };
  }

  approveAllExceptions(selectedExceptions){
    this.commonService.callApi('ShipmentOrder/UpdateExceptionStatus?status=Adjusted', 'post', selectedExceptions)
    .subscribe( (res: ApiResponse) => {
        if ( res.isSuccessful ) {
            this.commonService.callApi('ShipmentOrder/CreateAdjustmentOrder', 'post', selectedExceptions)
            .subscribe( (response: ApiResponse) => {
                if ( response.isSuccessful ) {
                    this.adjustmentOrdersList = response.result;

                    setTimeout(() => {
                        this.showAdjustmentOrderListPopup = true;
                    }, 500);
                    this.commonService.showNotification('success', response.message || 'Status updated! successfully. Adjustment orders created');
                } else {
                    this.commonService.showNotification('error', response.message || 'Something went wrong. Kindly, try again.');
                    this.showLoader = false;
                }
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
            });
        } else {
            this.commonService.showNotification('error', res.message || 'Something went wrong. Kindly, try again.');
            this.showLoader = false;
        }
    }, (error: HttpErrorResponse) => {
        this.showLoader = false;
    });
  }

  savePersistance(grid) {
    const persistenceData: PersistenceData = {
        userId: this.USER.userId,
        name: gridNames.cycleCountOpenExceptionGrid,
        value: grid.getPersistData()
    }
    this.commonService.saveUserPreference(persistenceData);
    localStorage.setItem(`grid${gridNames.cycleCountOpenExceptionGrid}`, grid.getPersistData());
  }

  onCommandClick(args: CommandClickEventArgs) {
   if (args.commandColumn.title === 'eye') {
           this.showPopup=true;
           this.object={"item": args.rowData['item'], "company": args.rowData['company'], "unit": args.rowData['unit']}
      }
  }

  adjustmentOrderListPopupClosed(event){
    this.showLoader = false;
    this.showAdjustmentOrderListPopup = false;
    this.adjustmentOrdersList = [];
    this.adjustmentOrderPopupClosed.emit()
}

  onClose(){
    this.showPopup=false;
  }

}
