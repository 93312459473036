<div class="modal fade show"
     [ngClass]="{'expanded': isExpanded}"
     id="service-order-modal"
     tabindex="-1"
     aria-labelledby="service-order-modalLabel"
     aria-hidden="true"
     [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                  <div class="mr-2 id flex-fill">
                    <h4 class="modal-title mb-0 item-id">Wrong parts received in shipment ID: {{ shipmentOrderId }}</h4>
                  </div>
                  <div class="btns-header">
                    <button class="header-icon close p-0 m-0"
                            (click)="close()"
                            aria-label="Close">
                      <i class="fas fa-times"
                        title="Close Popup"></i>
                    </button>
                    <button class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded=!isExpanded">
                      <i class="fas ml-2 mr-2"
                        [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                        [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                        ></i>
                    </button>
                  </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col mt-4">
                        <ejs-grid #wrongPartsGrid
                            id="shipmentOrderWrongShippedItemsGrid"
                            [dataSource]="wrongItemsGridList"
                            [toolbar]="gridToolbar"
                            [allowPaging]="false"
                            [allowFiltering]="true"
                            [allowSorting]="true"
                            [allowResizing]="true"
                            [allowReordering]="true"
                            [enablePersistence]="false"
                            [allowExcelExport]="true"
                            [editSettings]=" { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', allowEditOnDblClick: true }"
                            [filterSettings]="{ type: 'Menu' }"
                            (toolbarClick)="toolbarClick($event)"
                            (commandClick)="onCommandClick($event)"
                            (created)='onGridCreated($event)'
                            (actionBegin)="actionBegin($event)"
                            (actionComplete)="actionComplete($event)"
                            >
                            <e-columns>
                                <e-column 
                                    *ngFor="let column of columns;" 
                                    [field]="column.field" 
                                    [headerText]="column.headerText"
                                    [isPrimaryKey]="column.isPrimaryKey || false"
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [foreignKeyField]="column.foreignKeyField"
                                    [foreignKeyValue]="column.foreignKeyValue"
                                    [validationRules]="column.validationRules"
                                    [dataSource]="column.dataSource"
                                    [edit]="column.edit"
                                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                    [format]="column.format ? column.format : null"
                                    [textAlign]="column.textAlign" 
                                    [visible]="column.visible"
                                    [commands]="column?.commands ? column.commands : null"
                                    [allowEditing]="column.allowEditing" 
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-success close-modal" [disabled]="!(wrongPartsGrid.dataSource.length > 0) || wrongPartsGrid.isEdit" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #itemsGridColumnChooser>
    <app-column-chooser
        [grid]="wrongPartsGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
  </ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>
