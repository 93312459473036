<div class="modal fade show" [ngClass]="{'expanded': isExpanded}" id="service-order-modal" tabindex="-1"
    aria-labelledby="service-order-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-xl">
     <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header
                [id]="data.serviceOrderId"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="data.serviceOrderId ? data.serviceOrderId + ' - ' + data.customerDescription : 'Service Order'"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{technicianDropdown.label}}:<span
                                            class="required-asterik">*</span></label>
                                    <div class="col no-padding mt-1">
                                        <ejs-dropdownlist #technicianDDL
                                            [ngClass]="{'readonly-dropdown': technicianIdClaim}"
                                            [placeholder]="technicianDropdown.placeholder"
                                            [dataSource]="technicianDropdown.dataSource"
                                            [allowFiltering]="true"
                                            [fields]="{text: 'text', value: 'value'}"
                                            [filterType]="'contains'"
                                            [popupWidth]="'300px'"
                                            [enabled]="(isIscManager || isIscSupervisor) && ![serviceOrderStatuses.inProgress, serviceOrderStatuses.completed, serviceOrderStatuses.cancelled].includes(data.status)"
                                            [readonly]="technicianIdClaim"
                                            [value]="data?.technicianId"
                                            (change)="onTechnicianChange($event)"
                                        ></ejs-dropdownlist>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{statusDropdown.label}}:<span
                                            class="required-asterik">*</span></label>
                                    <div class="col no-padding mt-1">
                                        <ejs-dropdownlist #statusDDL
                                            [placeholder]="statusDropdown.placeholder"
                                            [dataSource]="statusDropdown.dataSource"
                                            [allowFiltering]="true"
                                            [readonly]="isStatusReadonly"
                                            [filterType]="'contains'"
                                            [fields]="{text: 'text', value: 'value'}"
                                            [value]="data?.status"
                                            [popupWidth]="'300px'"
                                            [title]="data?.status"
                                            (select)="onStatusSelect($event)"
                                            (change)="statusChanged($event);"
                                        ></ejs-dropdownlist>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="data.serviceOrderId">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:" #commentsButton
                                        (click)="showComments = !showComments"><i class="fas fa-comments mr-2"
                                            [ngClass]="{'color-orange': commentCount}"></i>
                                        <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup
                                        *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="id"
                                            [moduleId]="id"
                                            [mentionUsersList]="mentionUsersList"
                                            module="IscServiceOrder"
                                            [comments]="data?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-auto">
                                <div class="comments-section position-relative">
                                    <a *ngIf="showChecklistButton" class="comments-opener" href="javascript:" #checklistButton
                                        (click)="checklistClicked()"><i class="fa fa-list fa-fw"></i> Checklist</a>
                                    <app-showchecklist *ngIf="showcheckpopup"
                                        [customerData]="generalSectionData"
                                        [serviceOrderData]="data"
                                        [technicianName]="data?.technicianName"
                                        [company]="data?.company"
                                        [serializedItemGroupDescription]="data?.serializedItemGroupDescription"
                                        [serializedItemGroupCode]="data?.serializedItemGroupCode"
                                        [serviceOrderId]="data?.serviceOrderId"
                                        [technicianId]="data?.technicianId"
                                        [createdDate]="data?.createdDate"
                                        [createdBy]="data?.createdBy"
                                        [lastUpdatedDate]="data?.lastUpdatedDate"
                                        [lastUpdatedBy]="data?.lastUpdatedBy"
                                        (onClose)="showcheckpopup = false; checklistPopupClosed($event)"
                                        (checklistId)="setChecklistId($event)"
                                        [checklistIdInput]="checklistId"
                                        [hasChecklist]="hasChecklist"
                                        [increment]="increment"
                                        [newchecklistid]="checklistID"
                                        (certificateGenerate)="updateAttachments($event)"
                                    ></app-showchecklist>
                                </div>
                            </div> -->

                            <div class="col-2 pt-2" *ngIf="data.nteAmount">
                                <ejs-switch #toggler
                                    cssClass="switch-green"
                                    (click)="switchClicked($event)"
                                    [disabled]="!isEditable"
                                    [checked]="data.mode === 'Estimate'"
                                    [onLabel]="'Estimate'"
                                    [offLabel]="'Actual'"
                                    (change)="modeChanged($event);"
                                ></ejs-switch>
                                <label class="ml-2" for="#estimateMode">{{ data.mode }}</label>
                            </div><span *ngIf="invoiceAmountExceeded" class="color-red ml-2 pt-2">NTE Amount exceeded by {{invoiceAmountExceeded}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a class="nav-link position-relative" [ngClass]="{'active': tab === 'details'}"
                                            data-toggle="tab" href="#details-tab" title="Details">Details <i
                                                *ngIf="validationApplied && (!detailsTab?.validateGeneralSectionData() || !detailsTab?.validateEquipmentSectionData() || !detailsTab?.validateCommentsNotesData( paymentButtonClicked ))"
                                                class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>
                                    <li class="nav-item" *ngIf="data.status !== serviceOrderStatuses.open && USER.company === companies.kingslynn">
                                        <a class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'calibration'}" data-toggle="tab"
                                            href="#calibration-tab" title="Calibration">Calibration </a>
                                    </li>
                                    <li class="nav-item" *ngIf="data.status !== serviceOrderStatuses.open && data?.callGroup !== callGroups.initialCalibration">
                                        <a class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'execution'}" data-toggle="tab"
                                            href="#execution-tab" title="Executions">Execution <i
                                                *ngIf="validationApplied && (!executionTab?.isTabValid)"
                                                class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"
                                        data-toggle="tab"
                                        [ngStyle]="{'color': data.linkColorCode }"
                                        [ngClass]="{'active': tab === 'links'}"
                                            href="#links-tab" title="Links" aria-expanded="true"><i
                                                class="fas fa-link"></i></a>
                                    </li>
                                    <li class="nav-item" *ngIf="data.serviceOrderId">
                                        <a class="nav-link" [ngClass]="{'active': tab === 'attachments'}"
                                            data-toggle="tab" href="#attachments-tab" title="Attachments"
                                            aria-expanded="true"><i class="fas fa-file-upload"
                                                [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" [ngClass]="{'active': tab === 'history'}" data-toggle="tab"
                                            href="#history-tab" title="History"><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="container-fluid">
                      <div class="tab-content custom-scrollbar flex-fill">
                        <div class="tab-pane" [ngClass]="{'active show': tab === 'details'}" id="details-tab">
                              <app-isc-so-details-tab #detailsTab *ngIf="generalSectionData"
                                [eqData]="equipmentInfoData"
                                [gsData]="generalSectionData"
                                [notesData]="notesData"
                                [serviceDepartment]="data.serviceDepartment"
                                [fieldRules]="fieldRules"
                                [isEditable]="isEditable"
                                [hasCheckedInServiceOrder]="hasCheckedInServiceOrder"
                                [hasAssignments]="data && data.assignments && data.assignments.length"
                                [status]="data.status"
                                [id]="data.serviceOrderId"
                                [reasonsForCancellation]="cancelReasonsList"
                                [paymentButtonClicked]="paymentButtonClicked"
                                (statusChanged)="data.status = $event; statusChanged($event)"
                                (checkedIn)="data.checkInTime = $event; checkedIn($event)"
                                (checkedOut)="checkedOut($event)"
                                (configurationChanged)="configurationChanged($event)"
                                (distanceZoneChanged)="distanceZone = $event.distanceZone;  customerCurrency = $event.currency"
                              ></app-isc-so-details-tab>
                            </div>
                            <div id="execution-tab" [ngClass]="{'active show': tab === 'execution'}"
                                class="tab-pane active show">
                                <app-isc-execution-tab #executionTab
                                    *ngIf="data.status !== serviceOrderStatuses.open && executionTabData"
                                    [costTypes]="costTypes"
                                    [taxRates]="taxRate"
                                    [checkinTime]="data.checkInDate"
                                    [distanceZone]="distanceZone"
                                    [customerCurrency]="customerCurrency"
                                    [configurationId]="data.configuration"
                                    [data]="executionTabData"
                                    [iscEmployeeDetails]="iscEmployeeDetails"
                                    [isEditable]="isExecutionEditable"
                                    [fieldRules]="fieldRules"
                                    [assignments]="assignments || []"
                                    [assignmentDetails]="transactions || []"
                                    (tabUpdated)="getExecutionData($event)"
                                    (showPartsOrderPopup)="showPartsOrderPopup.emit($event)"
                                ></app-isc-execution-tab>
                            </div>
                            <div id="links-tab" class="tab-pane active show"
                                [ngClass]="{'active show': tab === 'links'}">
                                <app-links-tab-shared
                                    [serviceOrderId]="id"
                                    [links]="links"
                                    (onLinkIdClick)="onLinkIdClick.emit($event)"
                                ></app-links-tab-shared>
                            </div>
                            <div id="attachments-tab" class="tab-pane active show"
                                [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab *ngIf="generalSectionData"
                                    [serviceOrderId]="id"
                                    [serviceOrderIdLN]="data?.serviceOrderIdLN"
                                    [moduleId]="id"
                                    module="IscServiceOrder"
                                    [isEditable]="isEditable || data.status === serviceOrderStatuses.pendingApproval"
                                    [fieldRules]="fieldRules"
                                    [attachments]="data?.attachments"
                                    [customerData]="generalSectionData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="history-tab" class="tab-pane active show"
                                [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab *ngIf="generalSectionData" [serviceOrder]="data"></app-history-tab>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         <div class="modal-footer">
             <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" id="" (click)="reset()"
                    [disabled]="!canEdit" *ngIf="![serviceOrderStatuses.completed, serviceOrderStatuses.cancelled, serviceOrderStatuses.costed].includes(this.data.status)">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1 reset" (click)="paymentButtonClicked = false; save();"
                    [disabled]="!canEdit" *ngIf="![serviceOrderStatuses.completed, serviceOrderStatuses.cancelled, serviceOrderStatuses.costed].includes(this.data.status)">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1 reset" (click)="paymentButtonClicked = false; saveAndClose();"
                    [disabled]="!canEdit" *ngIf="![serviceOrderStatuses.completed, serviceOrderStatuses.cancelled, serviceOrderStatuses.costed].includes(this.data.status)">Save &amp; Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="so-payment-button"
                    [disabled]="data.nteAmount && data.mode === 'Estimate'? true : data.nteAmount && data.mode === 'Actual' && invoiceAmountExceeded ? true  : canNotPay || disablePaymentButton || (invoice && invoice.invoiceId && invoice.status === 'Signed') || !canEdit || ![serviceOrderStatuses.inProgress, serviceOrderStatuses.awaitingPayment].includes(this.data.status)"
                    *ngIf="![serviceOrderStatuses.completed, serviceOrderStatuses.cancelled, serviceOrderStatuses.costed].includes(data.status)" (click)="paymentButtonClicked = true; onPaymentButtonClick();">Payment</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="so-invoice-button"
                    [disabled]="data.nteAmount && data.mode === 'Estimate'? true : data.nteAmount && data.mode === 'Actual' && invoiceAmountExceeded ? true : canNotInvoice || !invoice || (invoice && !invoice.invoiceId) || !canEdit || [serviceOrderStatuses.cancelled, serviceOrderStatuses.completed, serviceOrderStatuses.importPublishError, serviceOrderStatuses.exportPublishError].includes(this.data.status)"
                    *ngIf="![serviceOrderStatuses.completed, serviceOrderStatuses.cancelled, serviceOrderStatuses.costed].includes(this.data.status)"(click)="paymentButtonClicked = true; makeInvoice()">Invoice</button>
                <!-- <button type="button" class="btn btn-sm btn-success mr-1 reset"
                    *ngIf="(USER.role === userRoles.iscManager || USER.role === userRoles.isc) && !offlineBillableOnly" [disabled]="disableClaimsButton" (click)="showClaimsPopup = true">Claims</button> -->
                <button type="button" class="btn btn-sm btn-success mr-1 reset"
                    *ngIf="data?.mode === 'Estimate' && data.serviceOrderId && [serviceOrderStatuses.inProgress].includes(this.data.status)" (click)="openEstimatedInvoicePDF()">Estimate</button>
            </div>
        </div>
    </div>
</div>

<app-confirmation-box *ngIf="confirmationBox.show"></app-confirmation-box>

<app-payment-selection-popup *ngIf="showPaymentSelectionPopup"
    [serviceOrderData]="data"
    [fieldRules]="fieldRules"
    [invoice]="invoice"
    (onClose)="onPaymentSelectionPopupClosed($event)"
></app-payment-selection-popup>

<app-payment-type-popup *ngIf="showPaymentPopup"
    [data]="data"
    [invoice]="invoice"
    [fieldRules]="fieldRules"
    [customerData]="generalSectionData"
    (onClose)="onPaymentTypePopupClose()"
    (onNext)="onPaymentTypePopupNext($event)"
></app-payment-type-popup>

<app-invoice-popup *ngIf="showInvoicePopup"
    [invoice]="invoice"
    [popupOpenedFrom]="InvoicePopupOpened.popupModal"
    [serviceOrderData]="data"
    [serviceOrderId]="id"
    [isDraft]="false"
    [fieldRules]="fieldRules"
    [customerData]="generalSectionData"
    [isSigned]="isSigned"    
    (onSigned)="onInvoiceSigned($event)"
    (onSignedAndClose)="onInvoiceSigned($event, true)"
    (onClose)="onInvoicePopupClosed($event)"
></app-invoice-popup>

<!-- <app-credit-card-popup
    [serviceOrderId]="id"
    [data]="data"
    [customerData]="generalSectionData"
    [invoice]="invoice"
    (onPay)="onPay($event)"
    (onCancel)="onCancel()"
></app-credit-card-popup> -->

<!-- <app-barclay-payment
    *ngIf="showKingslynnPaymentPopup"
    [serviceOrderId]="id"
    [data]="data"
    [customerData]="generalSectionData"
    [invoice]="invoice"
    (onPay)="onPay($event)"
    (onCancel)="onCancel()"
></app-barclay-payment> -->

<app-cancel-status-popup
    *ngIf="statusCancelPopup"
    [cancelReasonsList]="cancelReasonsList"
    (onClose)="cancellationPopupClosed($event)"
></app-cancel-status-popup>

<app-claims-popup
    *ngIf="showClaimsPopup"
    [claimId]="claimId"
    [serviceOrderId]="data.serviceOrderId"
    (onClose)="showClaimsPopup = false"
    (onSave)="onClaimSave($event)"
></app-claims-popup>

<app-iframe-popup *ngIf="showChasePaymentIframe"
    [serviceOrderId]="data.serviceOrderId"
    [serviceOrderData]="data"
    (ccPaymentCompleted)="ccPaymentCompleted($event)"
    (ccPaymentAuthcode)="ccPaymentAuthcode($event)"
    (ccPaymentCancel)="ccPaymentCancel($event)"
></app-iframe-popup>

<app-pdf-viwer *ngIf="showEstimatedInvoice"
    title="Estimated Invoice"
    [receiverEmail]="customerData?.email"
    [attachment]="estimateInvoiceFileAttachment"
    [isEstimateInvoice]="showEstimatedInvoice"
    [serviceOrderId]="data?.serviceOrderId"
    [serviceOrderIdLN]="data?.serviceOrderIdLN"
    [customerData]="generalSectionData"
    [certificateUrl]='estimateInvoiceUri'
    (onClose)="showEstimatedInvoice = false"
></app-pdf-viwer>
