<div
    class="modal fade show in" 
    [ngClass]="{'expanded': isExpanded}"
    id="claims-modal" 
    tabindex="-1" 
    aria-labelledby="claims-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <form [formGroup]="claimPopupForm" class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex w-100">
                <div class="mr-2 id" *ngIf="claimId">
                    <h4 class="modal-title mb-0 item-id">{{title}}</h4>
                </div>
        
                <div class="flex-fill" >
                    <h3 *ngIf="!claimId" class="mb-0 modal-title item-id">Claims</h3>
                </div>
        
                <div class="created-date pr-4">
                    <span>Creation Date & Time:</span> {{claimPopupForm.value.createdDate | date: 'MMM d, y hh:mm a'}}
                </div>

                <div class="btns-header">
                    <button 
                        type="button"
                        class="header-icon close p-0 m-0" 
                        (click)="close()" 
                        aria-label="Close"
                    ><i class="fas fa-times" 
                        title="Close Popup"
                    ></i>
                    </button>
                    <button 
                        type="button"
                        class="close header-icon expand-modal p-0 m-0"
                        (click)="isExpanded=!isExpanded"
                    ><i class="fas ml-2 mr-2" 
                        [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                        [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                    ></i>
                </button>
                </div>
            </div>
            </div>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label for="" class="control-label col-md-auto mt-2">{{ 'Technician' }}:<span class="required-asterik">*</span></label>
                                    <div class="col pt-2"> {{ claimPopupForm.value?.technicianName }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label for="" class="control-label col-md-auto mt-2"> Status: </label>
                                    <div class="col no-padding mt-1">
                                        <ejs-dropdownlist #statusDDL
                                            [dataSource]="[{displayName: claimPopupForm.value.status, status: claimPopupForm.value.status}]" 
                                            [allowFiltering]="true"
                                            [filterType]="'contains'"
                                            [fields]="{text: 'displayName', value: 'status'}" 
                                            [popupWidth]="'300px'" 
                                            [enabled]="false"
                                            formControlName="status"
                                        ></ejs-dropdownlist>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="claimId">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments">
                                        <i class="fas fa-comment mr-2" [ngClass]="{'color-orange': claimPopupForm.value.commentCount}"></i> 
                                        <span>{{claimPopupForm.value.commentCount}}</span> Comment<span *ngIf="claimPopupForm.value.commentCount>1">s</span>
                                    </a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [serviceOrderId]="claimId"
                                            [moduleId]="claimId"
                                            module="Claims"
                                            [isIscModule]="true"
                                            [comments]="claimPopupForm.value.comments || []"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                    <div class="comments-container position-absolute" #advanceCommentsPopup *ngIf="showLineComments">
                                        <app-advance-comments
                                            [statusList]="statusList"
                                            [serviceOrderId]="claimId"
                                            [lineId]="lineId"
                                            [defaultComment]="defaultCommentValue"
                                            [moduleId]="claimId"
                                            module="Claims"
                                            (status)="claimPopupForm.value.status = $event"
                                            [comments]="lineComment || []"
                                            (commentUpdated)="onLineCommentUpdate($event)"
                                            (onClose)="showLineComments = false;"
                                        ></app-advance-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}" 
                                            data-toggle="tab"
                                            href="#claims-details-tab"
                                            title="Details"
                                            (click)="claimItemsTab?.claimsGrid?.refresh()"
                                            >Details 
                                            <i *ngIf="validationApplied && !claimItemsTab.isTabValid" class="fas fa-exclamation-circle validation-exclamation"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'links'}" 
                                            data-toggle="tab"
                                            href="#claims-links-tab"
                                            title="Links" 
                                            aria-expanded="true"
                                        ><i class="fas fa-link" [ngClass]="{'color-green': claimPopupForm.value.linkCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'attachments'}" 
                                            data-toggle="tab"
                                            href="#claims-attachments-tab"
                                            title="Attachments" 
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': claimPopupForm.value.attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'history'}" 
                                            data-toggle="tab"
                                            href="#claims-history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="overflow: scroll">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="claims-details-tab">
                                <div style="margin: 5px; margin-bottom: 20px" class="header-form">
                                    <span>ISC Code: &nbsp; {{claimPopupForm.value?.iscSoldToBP}}</span>
                                    <span (click)="onServiceOrderClick()" onkeypress="">Service Order:<a href="javascript:">&nbsp; {{ claimPopupForm.value?.serviceOrderId }} {{ claimPopupForm.value?.serviceOrderIdLN ? ' - ' + claimPopupForm.value?.serviceOrderIdLN : ''}}</a></span>
                                    <span>Model: &nbsp; {{claimPopupForm.value?.model}}</span>
                                    <span>Serial Number: &nbsp; {{claimPopupForm.value?.serialNumber}}</span>
                                    <span>Service Completion Date: &nbsp; {{claimPopupForm.value?.serviceCompletionDate | date: 'MMM d, y hh:mm a'}}</span>
                                    <span>Estimated Miles: &nbsp; {{claimPopupForm.value?.estimatedMiles}}</span>
                                    <span>Service Manager: &nbsp; {{claimPopupForm.value?.serviceManagerName}}</span>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#isc-claim-so-claims-accordion">
                                        <a class="card-link flex-fill">Serivce Order Claims</a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                    <div id="isc-claim-so-claims-accordion" class="collapse show">
                                        <app-isc-so-claims #claimItemsTab
                                            *ngIf="dataFetchedFromAPI"
                                            [soReferenceActivities]="soReferenceActivities"
                                            [referenceActivities]="referenceActivities"
                                            [claimsForm]="claimPopupForm"
                                            [iscRulesForSO]="iscRulesForSO"
                                            [businessRules]="claimPopupForm.value.iscBusinessRules"
                                            [businessRulesPassed]="claimPopupForm.value?.businessRulesPassed"
                                            [costTypes]="costTypes"
                                            (lineId)="lineId = $event"
                                            (lineComment)="lineComment = $event"
                                            [iscEmployeeDetails]="iscEmployeeDetails"
                                            (businessRulesUpdated)="udpateStatusList()"
                                            (defaultComment)="defaultCommentValue = $event; showLineComments = true"
                                        ></app-isc-so-claims>
                                    </div>
                                </div>
                                <div class="card"  *ngIf="showRulesSection">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#isc-claims-rules-accordion">
                                        <a class="card-link flex-fill">Rules</a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                    <div id="isc-claims-rules-accordion" class="collapse show">
                                        <div style="display: flex">
                                            <div style="flex:1; margin: 20px">
                                                <div style="margin: 5px; font-weight: 500; font-size: large">Business Rules</div>
                                                <app-isc-claims-business-rules #businessRulesRef
                                                    *ngIf="claimPopupForm.value.iscBusinessRules"
                                                    [businessRules]="claimPopupForm.value.iscBusinessRules"
                                                ></app-isc-claims-business-rules>
                                            </div>
                                            <div style="flex:1.8; margin: 20px" *ngIf="claimPopupForm.value.isNationalAccount">
                                                <div style="margin: 5px; font-weight: 500; font-size: large">Customer Rules</div>
                                                <app-isc-claims-customer-rules-tab
                                                    *ngIf="claimPopupForm.value.iscCustomerRules"
                                                    [claimForm]="claimPopupForm"
                                                ></app-isc-claims-customer-rules-tab>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; gap: 100px; margin-top: 10px; margin-left: 25px; margin-right: 25px; margin-bottom: 100px;">
                                    <div style="display: flex; flex: 1; gap: 45px; justify-content: space-between;">
                                        <div *ngIf="showAlternateInvoiceNumber">
                                            <label for="" class="control-label required">Alternate Invoice Number:</label>
                                            <ejs-textbox 
                                                formControlName="alternateInvoiceNumber"
                                                placeholder="Alternate Invoice Number"
                                                [enabled]="editAlternateInvoiceNumber"
                                            ></ejs-textbox>
                                        </div>
                                        <div *ngIf="showReviewer">
                                            <label for="" class="control-label required">Reviewer:</label>
                                            <ejs-dropdownlist 
                                                [dataSource]='reviewerData' 
                                                [fields]="{text: 'employeeName', value: 'employeeId'}" 
                                                formControlName="reviewer" 
                                                placeholder='Select Reviewer'
                                                [enabled]="editReviewer"
                                            ></ejs-dropdownlist>
                                        </div>
                                        <div *ngIf="showPayVia">
                                            <label for="" class="control-label required">Pay Via:</label>
                                            <ejs-dropdownlist 
                                                [dataSource]='paymentMethodData' 
                                                [fields]="{text: 'text', value: 'value'}" 
                                                formControlName="payVia" 
                                                placeholder='Pay Via'
                                                [enabled]="editPayVia"
                                            ></ejs-dropdownlist>
                                        </div>
                                        <div *ngIf="showClosingDate">
                                            <label for="" class="control-label required">Completed Date:</label>
                                             <ejs-datetimepicker 
                                                placeholder="Completed Date"
                                                format="d MMM, y h:mm a"
                                                [showClearButton]="false"
                                                [allowEdit]="false"
                                                formControlName="closingDate"
                                                [showPopupButton]="false"
                                                [enabled]="false"
                                            ></ejs-datetimepicker>
                                        </div>
                                        <div *ngIf="showPausedReason">
                                            <label for="" class="control-label required">Paused Reason:</label>
                                            <ejs-dropdownlist 
                                                [dataSource]='pausedReasonData' 
                                                [fields]="{text: 'description', value: 'value'}" 
                                                formControlName="pausedReason" 
                                                placeholder='Paused Reason'
                                                [enabled]="editPausedReason"
                                            ></ejs-dropdownlist>
                                        </div>
                                        <div *ngIf="showRejectionReason">
                                            <label for="" class="control-label required">Rejection Reason:</label>
                                            <ejs-textbox
                                                formControlName="rejectionReason"
                                                [enabled]="editRejectionReason"
                                            ></ejs-textbox>
                                            <div *ngIf="claimPopupForm.get('rejectionReason').invalid && (claimPopupForm.get('rejectionReason').dirty || claimPopupForm.get('rejectionReason').touched)">
                                                <span class="error-message">Rejection Reason is required</span>
                                            </div>
                                        </div>
                                        <div *ngIf="showInstallDate">
                                            <label for="" class="control-label required">Install Date:</label>
                                             <ejs-datetimepicker 
                                                placeholder="Install Date"
                                                format="d MMM, y h:mm a"
                                                [showClearButton]="false"
                                                [allowEdit]="false"
                                                formControlName="installationDate"
                                                [showPopupButton]="false"
                                                [enabled]="false"
                                            ></ejs-datetimepicker>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex: 0.15; flex-direction: column;">
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Claim Amount:</span>
                                            <span>{{claimPopupForm.value?.claimAmount.toFixed(2)}}</span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Disputed Amount:</span>
                                            <span>{{claimPopupForm.value?.disputedAmount.toFixed(2)}}</span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Net Claim Amount:</span>
                                            <span>{{claimPopupForm.value?.netClaimAmount.toFixed(2)}}</span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Tax Amount ({{claimPopupForm.value.includeTax ? 'Included' : 'Excluded'}}):</span>
                                            <span>{{claimPopupForm.value?.taxAmount.toFixed(2)}}</span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Total Claim Amount:</span>
                                            <span>{{claimPopupForm.value?.totalClaimAmount.toFixed(2)}}</span>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <span>Currency:</span>
                                            <span>{{claimPopupForm.value?.currency}}</span>
                                        </div>
                                    </div>                                            
                                </div>
                            </div>
                            <div id="claims-links-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'links'}">
                                <app-links-tab-shared
                                    [links]="links"
                                    (onLinkIdClick)="onLinkIdClick.emit($event)"
                                ></app-links-tab-shared>
                            </div>
                            <div id="claims-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab 
                                    [moduleId]="claimPopupForm.value?.claimId"
                                    module="Claims"
                                    [attachments]="claimPopupForm.value?.attachments"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="claims-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [history]="claimPopupForm.value.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <div class="btn-group dropup">
                    <button type="button" (click)="promptUserBeforeSave()" class="btn btn-sm btn-success comment-action-item">
                        Save & Close
                    </button>
                    <button type="button" class="btn btn-sm btn-success comment-action-item dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="promptUserBeforeSave(item.status, item.option)" *ngFor="let item of statusMenuItem">{{ item.option }}</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<app-isc-popup
    *ngIf="showServiceOrderPopup"
    [id]="serviceOrderId"
    [data]="serviceOrderData"
    (onClose)="showServiceOrderPopup = false"
></app-isc-popup>

<app-reason-popup *ngIf="showReasonCodePopup"
    popupTitle="{{statusChangeAction == 'Paused' ? 'Pause':'Reject'}} claim {{ claimPopupForm.value.claimId }}"
    [id]="claimPopupForm.value.claimId"
    [reasons]="pausedReasonData"
    [reasonFieldName]="statusChangeAction"
    (closeReasonPopup)="closeReasonPopup()"
    (saveReasonCodePopup)="saveReasonCodePopup($event)"
></app-reason-popup>