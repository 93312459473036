import { Component, ViewChild, OnInit, AfterViewInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef, ElementRef, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { ApiResponse, gridNames, PersistenceData, GridPersistRequests } from 'src/app/shared/models/app.model';
import { GridComponent, EditService, ExcelExportProperties, CommandClickEventArgs, ExcelQueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, gridActionBeginForFiltering, gridActionsForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CycleCountService } from '../../cycle-counts.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getValue } from '@syncfusion/ej2-base';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-cycle-counts-cost-analysis',
  templateUrl: './cycle-counts-cost-analysis.component.html',
  styleUrls: ['./cycle-counts-cost-analysis.component.scss']
})
export class CycleCountCostAnalysisComponent implements OnInit, OnChanges, OnDestroy {
  showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountCostAnalysisGrid;
  columns: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 300;
  showPopup: boolean =false;
  object;
  query: Query;
  public pageSettings: Object
  @Input() costAnalysisList;
  @Input() warehouseId;
  @Input() status;
  @Input() cycleCountId;
  @Output() reloadPopupData: EventEmitter<any> = new EventEmitter();
  @Output() updatedcostAnalysisList: EventEmitter<any> = new EventEmitter();
  @Output() isFileUploaded: EventEmitter<any> = new EventEmitter();
  @Output() totalExtCost: EventEmitter<any> = new EventEmitter();
  @Output() totalCostDifference: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<void>();

  BASE_URL = environment.apiUrl;
  showUploadDetailPopup: boolean = false
  uploadDetailData
  showColumnUnitCost: boolean = false
  showColumnCostVarLN: boolean = false
  showColumnExtCostLN: boolean = false
  canEditCostAnalysisGrid: boolean = false
  aggreagtes
  fileInvalid: boolean = false
  allowEdit: boolean = true
  fileUploaded = false;
  costDifference: number = 0
  dataSource
  showItemLinkPopup: boolean = false
  inventoryQuantityData = null
  refreshOnClick: boolean = true
  itemCode
  warehouse
  popupTitle
  openLinkSecondDescrption

  @ViewChild('cycleCountCostAnalysisGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;
  @ViewChild('fileInputRef', { static: false }) fileInputRef!: ElementRef;
  @ViewChild('rimsVarCheckbox') rimsVarCheckboxElement;
  @ViewChild('lnVarCheckbox') lnVarCheckboxElement;
  @ViewChild('adjustedCheckbox') adjustedCheckboxElement;

  @ViewChild('rimsVar') rimsVarCheckbox;
  @ViewChild('lnVar') lnVarCheckbox;
  @ViewChild('adjusted') adjustedCheckbox;



  USER: any;
  preference;
  showCycleCountCostAnalysisGrid: boolean = false;

  constructor(
    private commonService : CommonService,
    private cycleCountService: CycleCountService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.USER = this.commonService.USER;
    this.showColumnUnitCost = this.commonService.roleClaims['CostAnalysisGrid_Column_UnitCost']?.visible // Except Techs
    this.showColumnCostVarLN = this.commonService.roleClaims['CostAnalysisGrid_Column_CostVarLN']?.visible // Except Techs
    this.showColumnExtCostLN = this.commonService.roleClaims['CostAnalysisGrid_Column_ExtCostLN']?.visible // Except Techs
    this.canEditCostAnalysisGrid = this.commonService.roleClaims['CostAnalysisGrid_AllowRowEdit']?.edit // only true for manager
    this.dataSource = this.costAnalysisList
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = this.costAnalysisList
    this.updateList()
    if (changes.status) {
      this.setGridToolbar()
    } 
  }

  ngOnInit(): void {
    this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
    this.grid = null;
    this.getColumns();
    if(this.status !== 'Counted') {
      this.allowEdit = false
    } else {
      this.allowEdit = true
    }
    this.showLoader = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
  var clickedElement = event.target as HTMLElement;
  var isClickedOnEditedRow = clickedElement.classList.contains('e-editedrow') || clickedElement.closest('.e-editedrow') !== null;
  if (this.grid.isEdit && !isClickedOnEditedRow) {
      this.grid.endEdit();
    }
  }

  disableEdit() {
    if(this.status !== 'Counted') {
      this.grid.endEdit()
      this.allowEdit = false
    } else {
      this.allowEdit = true
    }
  }

  public refreshOnce(): void {
    if(this.refreshOnClick) {
      this.grid.changeDataSource()
      this.refreshOnClick = false
    }
  }

  public endEdit(): void {
    this.grid.endEdit()
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { field: 'itemCode', headerText: 'Items', type: 'String', textAlign: 'Left', width: 200, isPrimaryKey: true },
        { field: 'itemDescription', headerText: 'Description', type: 'String', textAlign: 'Left', width: 200 },
        { field: 'cost', headerText: 'Unit Cost', format: 'C2', visible: this.showColumnUnitCost, showInColumnChooser: this.showColumnUnitCost, textAlign: 'Left', width: 200 },
        { field: 'rimsQtyOnhand', headerText: 'On hand (RIMS)', format: 'n', textAlign: 'Left', width: 200 },
        { field: 'lnQtyOnhand', headerText: 'On hand (LN)', format: 'n', textAlign: 'Left', width: 200 },
        { field: 'extCost', visible: this.showColumnExtCostLN, showInColumnChooser: this.showColumnExtCostLN, headerText: 'Ext Cost (LN)', format: 'C2', textAlign: 'Left', width: 200 },
        { field: 'actualQtyOnhand', headerText: 'Cycle Count', format: 'n', textAlign: 'Left', width: 200 },
        { field: 'actualQtyCorrected', headerText: 'Adj Count', format: 'N', textAlign: 'Left', width: 200, allowEditing: true },
        { field: 'rimsVarianceQty', headerText: 'VAR (ADJ-RIMS)', format: 'n', textAlign: 'Left', width: 200 },
        { field: 'lnVarianceQty', headerText: 'VAR (ADJ-LN)', format: 'n', textAlign: 'Left', width: 200 },
        { field: 'costVariance', visible: this.showColumnCostVarLN, showInColumnChooser: this.showColumnCostVarLN, headerText: 'Cost VAR (LN-ADJ)', format: 'C2', textAlign: 'Left', width: 200 },
        { field: 'comments', headerText: 'Comments', type: 'String', textAlign: 'Left', width: 200, allowEditing: true },
        // { field: 'totalCostLn', headerText: 'Total Ext Cost (LN)', type: 'String', format: '{0:C}', visible: this.showColumnExtCostLN, textAlign: 'Left', width: 200 },
        // { field: 'totalRimVar', headerText: 'Total VAR (RIMS-ADJ)', type: 'String', format: 'n', textAlign: 'Left', width: 200 },
        // { field: 'totalLnVar', headerText: 'Total VAR (LN-ADJ)', type: 'String', format: 'n', textAlign: 'Left', width: 200 },
        // { field: 'totalCostLnAdj', headerText: 'Total Cost VAR (LN-ADJ)', type: 'String', format: '{0:C}', textAlign: 'Left', width: 200 },
        {
          field: 'Actions', headerText: 'Actions', width: 170, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
          commands: [
              { title: 'Item Link', buttonOption: { iconCss: `fa fa-link`, cssClass: 'e-flat linked-items' } },
          ]
        }
      ]};
  }

  updateList() {
    if(this.dataSource) {
      for(var item of this.dataSource) {
        if(item.actualQtyOnhand) {
          this.isFileUploaded.emit(true) 
          this.fileUploaded = true
          break;
        }
      }
      let totalExtCost = 0
      let totalCostVariance = 0
      if(this.fileUploaded) {
          for(var item of this.dataSource) {
            let cost: string = item.cost != null ? parseFloat(item.cost).toFixed(2) : '0.00';
            let lnQtyOnhand: string = item.lnQtyOnhand != null ? parseFloat(item.lnQtyOnhand).toFixed(2) : '0.00';
            let extCost: number = parseFloat((parseFloat(cost) * parseFloat(lnQtyOnhand)).toFixed(2));
            item.extCost = extCost;
            totalExtCost += item.extCost;

            let actualQtyCorrected: number = item.actualQtyCorrected != null ? item.actualQtyCorrected : item.actualQtyOnhand;
            item.actualQtyCorrected = actualQtyCorrected
            let rimsQtyOnhand: string = item.rimsQtyOnhand != null ? parseFloat(item.rimsQtyOnhand).toFixed(2) : '0.00';
            item.rimsVarianceQty = actualQtyCorrected - parseFloat(rimsQtyOnhand);
            item.lnVarianceQty = actualQtyCorrected - parseFloat(lnQtyOnhand);

            let calculatedExtCost: number = actualQtyCorrected * parseFloat(cost);
            let parsedExtCost: number = isNaN(calculatedExtCost) ? 0.00 : extCost;
            item.costVariance = calculatedExtCost - parsedExtCost;
            totalCostVariance += item.costVariance
        }
        this.costDifference = totalExtCost != 0? ((totalCostVariance/totalExtCost)*100) : null
        this.costDifference = this.costDifference? parseFloat(this.costDifference.toFixed(2)) : null
        this.totalExtCost.emit(totalExtCost)
        this.totalCostDifference.emit(totalCostVariance)
      } else {
        for(var item of this.dataSource) {
          item.extCost = null;
          item.rimsVarianceQty = null
          item.lnVarianceQty = null
          item.costVariance = null
        }
      }
      this.updatedcostAnalysisList.emit(this.dataSource)
    }
  }

  onCommandClick(args: any) {
    switch (args.commandColumn.title) {
      case 'Item Link':
          setTimeout(() => {
              this.openLinkedOrderPopup(args);
          }, 100);
        break;
  
      default:
        break;
    }
  }

  openLinkedOrderPopup(data) {
    this.itemCode = data.rowData.itemCode;
    this.warehouse = this.warehouseId;
    this.openLinkSecondDescrption = "Pending Inventory"
    this.popupTitle = this.itemCode + " " + data.rowData.itemDescription;
    this.showItemLinkPopup = true;
    this.showLoader = false;
  }
  
  onRowDataBound(args: any, grid: GridComponent) {
    const data = args.data;
    const $row = args.row;
    if($row.getElementsByClassName('linked-items')[0]) $row.getElementsByClassName('linked-items')[0].style.color = data.linkColorCode || this.commonService.defaultLinkColor;

}

  getCostDifferenceClass(): string {
    if (this.costDifference >= 3) {
      return 'negative-color';
    } else{
      return 'positive-color';
    }
  }
  
  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    if(args.action == 'edit') {
      this.updateList()
      this.grid.refresh()
    }
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
    // if (GridPersistRequests.includes(args.requestType)) {
    //   this.savePersistance(grid);
    // }
  }

  checkIfNegative(data) {
    if(data < 0) {
      return 'negative-color'
    }
  }

  setGridToolbar() {
    if(!this.grid) return
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        { id: 'upload-file', prefixIcon: 'fas fa-upload', cssClass: this.status !== 'In Progress'? 'e-flat disabled-grid-button' : 'e-flat', tooltipText: 'Upload File' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
        { id: 'rimsVar-checkbox', align: 'Right', template: this.rimsVarCheckboxElement, tooltipText: '' },
        { id: 'lnVar-checkbox', align: 'Right', template: this.lnVarCheckboxElement, tooltipText: '' },
        { id: 'adjusted-checkbox', align: 'Right', template: this.adjustedCheckboxElement, tooltipText: '' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
  }

  errorMessage: string;

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.uploadCsvFile(file);
    }
    this.cdr.detectChanges();
  }
  

  uploadCsvFile(file) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append('warehouse', this.warehouseId)
    const headers = new HttpHeaders({
      'Content-Type': `FileUpload`,
    });
    

    this.showLoader = true
    this.http.post(`${BASE_URL}CycleCount/ImportCycleCountDetail`, formData, { headers: headers }).pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: ApiResponse) => {
          if (res.isSuccessful) {
            this.showUploadDetailPopup = true
            this.uploadDetailData = res.result
            this.fileInvalid = this.checkUploadData(this.uploadDetailData)
            this.showLoader = false
            this.fileInputRef.nativeElement.value = ''
          } else {
            this.fileInputRef.nativeElement.value = ''
            this.commonService.showNotification('error',res.message);
            this.showLoader = false
          }
        }, (error: HttpErrorResponse) => {
          this.fileInputRef.nativeElement.value = ''
          this.commonService.showNotification('error',error.message);
          this.showLoader = false
          throw error;
        });

  }

  checkUploadData(uploadDetailData): boolean {    
    let isFileInvalid = false;
    for (const detail of uploadDetailData) {
      if (!detail.warehouseIsValid || !detail.itemCodeIsValid || !detail.countIsValid) {
        isFileInvalid = true;
        break;
      }
    }
    return isFileInvalid;
  }

  onBrowseFile() {
    this.fileInputRef.nativeElement.click()
  }

  checkRimsVar(checked) {
    if (checked) {
        this.grid.filterByColumn('rimsVarianceQty', 'notequal', 0);
    } else {
        this.grid.clearFiltering(['rimsVarianceQty']);
    }
  }
  
  checkLnVar(checked) {
    if (checked) {
        this.grid.filterByColumn('lnVarianceQty', 'notequal', 0);
    } else {
        this.grid.clearFiltering(['lnVarianceQty']);
    }
  }

  checkAdjusted(checked) {
    if (checked) {
      const filteredList = this.costAnalysisList.filter(item => item.actualQtyOnhand !== item.actualQtyCorrected);
      this.dataSource = filteredList
    } else {
      this.dataSource = this.costAnalysisList
    }
  }

  queryCellInfo(args) {
    if (args.column.field) {
      if(getValue('actualQtyOnhand', args.data) != null && getValue('actualQtyCorrected', args.data) != null && getValue('actualQtyOnhand', args.data) != getValue('actualQtyCorrected', args.data)) {
           args.cell.bgColor = '#FFFF00';
        }
    }
  }

  excelQueryCellInfo(args: ExcelQueryCellInfoEventArgs): void {
    if(getValue('actualQtyOnhand', args.data) != null && getValue('actualQtyCorrected', args.data) != null && getValue('actualQtyOnhand', args.data) != getValue('actualQtyCorrected', args.data)) {
      args.style = { backColor: '#FFFF00' };
    }
  }

  onGridCreated() {
    if(this.grid) updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'add-new-cycle-count') {
      this.showPopup = true;
    } else if (args.item.id === 'excel-export') {
        const dataSource = getExcelDataSource(this.grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Cost Analysis (${getCurrentDate()}).xlsx`
        };
        this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    } else if (args.item.id === 'clear-filter') {
        this.rimsVarCheckbox.checked = false;
        this.lnVarCheckbox.checked = false;
        this.adjustedCheckbox.checked = false;
        this.grid.clearFiltering();
        this.grid.search('');
        this.dataSource = this.costAnalysisList
    }  else if (args.item.id === 'refresh') {
      this.grid.refresh();
    } else if(args.item.id === 'upload-file') {
      this.fileInputRef.nativeElement.click();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountCostAnalysisGrid, value: '' }).subscribe((response) => {
            grid.search('');
            grid.clearFiltering();
            grid.clearSorting();
            this.showLoader = false;
          });
        }
        else{
          this.showLoader = false;
          return
        }
      });
    }
  }

  // savePersistance(grid) {
  //   const persistenceData: PersistenceData = {
  //       userId: this.USER.userId,
  //       name: gridNames.cycleCountCostAnalysisGrid,
  //       value: grid.getPersistData()
  //   }
  //   this.commonService.saveUserPreference(persistenceData);
  //   localStorage.setItem(`grid${gridNames.cycleCountCostAnalysisGrid}`, grid.getPersistData());
  // }

  onClose(){
    this.showPopup=false;
  }

}
