import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private _customerFiltering = new Subject<string>();
  public _customerFiltering$ = this._customerFiltering.asObservable();
  constructor(private http: HttpClient) { }


  setSearchCustomerFiltering(value: string): void {
    this._customerFiltering.next(value)
  }

  getSearchCustomerFiltering(): Observable<string> {
    return this._customerFiltering$
  }


  getLatLongViaAddress(address: string, addressCode?: string): Observable<any> {
    return this.http.post(`${BASE_URL}address/GetLatLong`, {addressCode, address});
  }

  getAllAddressesOfFSTs(searchTerm: string = '', roles?: string, zipCode?: string) {
    let payload = {
      "requiresCounts": true,
      "onSearch": {
          "fieldNames": [
              "name",
              "name2",
              "address",
              "address2",
              "addressCode",
              "city",
              "country",
              "zipCode",
          ],
          "operator": "wildcard",
          "searchKey": searchTerm,
          "ignoreCase": true,
          "ignoreAccent": false,
          "oprtr": "wildcard"
      },
      "onPage": {
          "pageIndex": 1,
          "pageSize": 20
      },
          "onWhere": {
          "ignoreAccent": false,
          "isComplex": true,
          "condition": "and",
          "predicates": [
              {
                  "ignoreAccent": false,
                  "isComplex": false,
                  "field": "zipCode",
                  "operator": "contains",
                  "value": zipCode,
                  "ignoreCase": true,
                  "oprtr": "contains"
              }
          ],
          "ignoreCase": true
      },
      "parameters": [],
      "skip": 0,
      "take": -1
  }
    if(!zipCode) {
      delete payload.onWhere;
    }
    let url = `${BASE_URL}Maps/GetAllAddressesOfFSTs`;
    if (roles) url += `?role=${roles}`;
    return this.http.post(url, payload);
  }


  searchAddressInAzureMaps(query: string): any {
    return this.http.get(`https://atlas.microsoft.com/search/address/json?api-version=1.0&query=${query}&typeahead=true&subscription-key=${environment.azureMapKey}`);
  }
  getOpenServiceOrders(searchTerm?: string, roles?: string, zipCode?: string): Observable<any> { //this API needs to take a searchTerm as well
    let url = `${BASE_URL}Maps/GetOpenServiceOrders`;
    if(searchTerm || roles || zipCode) {
      url += '?';
      if(searchTerm && roles && zipCode) url += `searchTerm=${searchTerm}&role=${roles}&zipCode=${zipCode}`;
      else if(searchTerm && zipCode) url += `searchTerm=${searchTerm}&zipCode=${zipCode}`;
      else if(searchTerm && roles) url += `searchTerm=${searchTerm}&role=${roles}`;
      else if(zipCode && roles) url += `zipCode=${zipCode}&role=${roles}`;
      else if (roles) url += `role=${roles}`;
      else if (zipCode) url += `zipCode=${zipCode}`;
      else if (searchTerm) url += `searchTerm=${searchTerm}`;
    }
    return this.http.get(url);
  }

}
