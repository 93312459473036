import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateTimePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { ChangeEventArgs, ComboBox } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBox, NumericTextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ApiResponse, Companies } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { CostTypes, CostTypesList, IscCostTypes } from '../../models/service-order.model';
import { ExecutionTabService } from '../../services/execution-tab.service';
import { ServiceOrderService } from '../../services/service-order.service';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';

@Component({
    selector: 'app-isc-assignment-details-popup',
    templateUrl: './isc-assignment-details-popup.component.html',
    styleUrls: ['./isc-assignment-details-popup.component.scss']
})
export class IscAssignmentDetailsPopupComponent implements OnInit {

    isKingslynn: boolean = false;
    isExpanded: boolean = false;
    showPopup: boolean = false;
    showLoader: boolean = false;
    getPriceForItem: boolean = false;
    itemChangedFromAdvanceSearch: boolean = false;

    today: Date = new Date();

    costLineDefaults: any;
    CostTypes = CostTypes;

    @Input() serviceOrderId: string = null;
    @Input() assignments = [];
    @Input() assignmentDetails = [];
    @Input() loadedAssignmentItemDetails = [];
    @Input() units = [];
    @Input() returnReasons = [];
    @Input() configurationId = null;
    @Input() customerCode = null;
    @Input() distanceZone = null;
    @Input() taxRates = [];
    @Input() checkinTime: any = null;
    @Input() costTypes = CostTypesList;
    @Input() serviceItemGroup;
    @Input() technicianId: string;

    @Input() data = null;
    @Input() equipmentInfo = null;
    @Input() soCreatedDate = null;

    minDate = new Date();    
    onItemUpdate = new Subject<string>();
    itemsList = [];

    @ViewChild('transactionsForm') transactionsForm: NgForm;
    @ViewChild('qty') quantityField: NumericTextBoxComponent;
    @ViewChild('startDatePicker') startDatePicker: DateTimePickerComponent;
    @ViewChild('endDatePicker') endDatePicker: DateTimePickerComponent;
    @ViewChild('discountAmount') discountAmount: NumericTextBox;
    @ViewChild('costTypeDDL') costTypeDDL;
    @ViewChild('itemsCombo') itemsCombo;

    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();

    coreItemGroup = ['450SVC'];
    
    laborRate: any = 0;
    calloutRate: any = 0;
    warehouse: string;
    onHandCount;
    showItemAdvancedSearchPopup: boolean = false;
    itemsObject: ComboBox;
    USER: any;

    constructor(
        private executionTabService: ExecutionTabService,
        private serviceOrderService: ServiceOrderService,
        private inventoryService: InventoryService,
        private commonService: CommonService
    ) {
        this.USER = commonService.USER
    }

    ngOnInit(): void {
        this.data = JSON.parse(JSON.stringify(this.data));
        this.warehouse = this.data?.warehouse || this.USER.warehouse;
        this.setData();
        this.showPopup = true;
        this.getLaborRate();
        this.getCalloutChargeRate();
        this.data.currency = localStorage.getItem('currency');
        this.data.taxpercent = this.getTaxRate(this.data.costType);
        this.setCostComponent(this.data.costType);
        this.isKingslynn = this.USER.company === Companies.kingslynn
        
        this.getItemList(this.data.item);
        
        this.onItemUpdate
            .pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).subscribe(value => {
                this.getItemList(value);
            });
    }

    getItemList(value: string = null) {
        this.showLoader = true;
        let serviceItemGroup = '';
        if ( this.data.costType === CostTypes.otherCost ) {
            serviceItemGroup = '350SVC';
        } else {
            serviceItemGroup = ''
        }
        this.commonService.getItems(value, 30, this.USER.company, serviceItemGroup)
            .subscribe((res: ApiResponse) => {
                if ( res && res.isSuccessful ) {                
                    const existingItems = [];
                    if(this.itemsCombo){
                        (this.itemsCombo.dataSource as any).forEach(item => {
                            if ( item.itemCode !== this.itemsCombo?.value ) {
                                existingItems.push(item.itemCode);
                            }
                        });
                    }
                    this.itemsList = res.result.filter(item => {
                        item.value = item.item;
                        item.text = `${item.item} | ${item.itemDescription}`;
                        if (existingItems.length) {
                            return !existingItems.includes(item.value)
                        } else {
                            return true;
                        }
                    });
                    if ( this.itemsObject ) {
                        this.itemsObject.dataSource = this.itemsList;
                        this.itemsObject.hideSpinner();
                    }
                    this.showLoader = false;
                } else {
                    this.itemsList = [];
                    if ( this.itemsObject ) {
                        this.itemsObject.hideSpinner();
                        this.itemsObject.dataSource = [];
                    }
                    this.showLoader = false;
                }
            });
    }

    ngOnChanges(change: SimpleChanges): void {
        if (change && change.taxRate && change.taxRate.currentValue) {
            this.taxRates = change.taxRate.currentValue;
        }
    }

    getLaborRate() {
        this.executionTabService.getLaborSalesRate(this.configurationId).subscribe((response: any) => {
            if (response.Data) {
                this.laborRate = response.Data;
            }
        });
    }

    getCalloutChargeRate() {
        this.executionTabService.getCallOutCharge(this.distanceZone, 0).subscribe((response: any) => {
            if (response && response.Data) {
                this.calloutRate = response.Data.callOutCharge;
            }
        });
    }

    getTaxRate(costType) {
        if (this.taxRates) {
            const laborTypeKeys = {
                [CostTypes.labor]: 'LABMOBILE',
                [CostTypes.material]: 'MATERIAL',
                [CostTypes.other]: 'TRAVEL',
                [CostTypes.otherCost]: 'OTHER'
            }
            let taxRateItem = this.taxRates.find(taxRate => taxRate.productCategory === laborTypeKeys[costType]);
            if (taxRateItem && taxRateItem.taxRatePercentage) {
                return taxRateItem.taxRatePercentage;
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    setData() {
        if (!this.data) {
            this.data = {
                assignmentId: '',
                activityId: null,
                assignmentDetailId: '',
                comments: null,
                referenceActivityDescription: null,
                referenceActivityCode: null,
                serviceTypeCode: null,
                serviceTypeDescription: null,
                costType: 'Labor',
                costSubType: 'Mileage',
                itemCode: null,
                itemDescription: null,
                qty: null,
                currency: null,
                price: null,
                totalAmount: null,
                unit: null,
                startDate: null,
                endDate: null,
                task: null,
                laborType: null,
                costComponent: null,
                discountAmount: 0,
                warrantyCoverage: 0,
                contractCoverage: 0,
                taxpercent: this.getTaxRate(CostTypes.labor),
                taxAmount: 0,
                serviceItem: null
            };
        } else {
            // this.checkOnHandQuantity(this.data.itemCode, this.data.qty);
        }
        if (this.assignments && this.assignments.length) {
            this.assignments.map(assignment => {
                assignment.tempId = assignment.assignmentId ? assignment.assignmentId : assignment.tempId;
            });
        }
    }

    // getCostTypes() {
    //     this.executionTabService.getCostTypes()
    //     .subscribe( (res: any) => {
    //         res.result.map( row => {
    //             row.text = row.description;
    //         });
    //     });
    // }

    itemAdvanceSearch(args){
        if ( this.data.costType === CostTypes.otherCost ) {
            this.serviceItemGroup = '350SVC'
        } else {
            this.serviceItemGroup = '';
        }
        this.showItemAdvancedSearchPopup = true;
    }

    getUnits() {
        return this.executionTabService.getUnits(this.USER.company);
    }

    getReturnReasons() {
        return this.executionTabService.getReturnReasons(this.USER.company);
    }

    onAssignmentChange(args: ChangeEventArgs) {
        this.data.assignmentId = args.itemData['tempId'];
        this.data.activityId = args.itemData['activityId'];
        this.data.referenceActivityDescription = args.itemData['referenceActivityDescription'];
        this.data.referenceActivityCode = args.itemData['referenceActivityCode'];
        this.data.serviceTypeDescription = args.itemData['serviceTypeDescription'];
        this.data.serviceTypeCode = args.itemData['serviceTypeCode'];
        this.discountAmount.enabled = args.itemData['referenceActivityDescription'] === 'Billable Service';
        this.data.costType = null;
        this.onCostTypeChange({value: null});
        this.updateCostTypeDataSource(args);
    }

    // onTotalAmountChange(args) {
    //     this.calculateTaxAmount();
    // }

    updateCostTypeDataSource(args) {
        let costTypes = this.costTypes;
        if (args.itemData.referenceActivityDescription === 'Warranty Service' && this.equipmentInfo) {
            this.equipmentInfo.warrantyDescriptionObj.forEach(ws => {
                if (ws.costType === 'all' && new Date(ws.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = [];
                } else if (ws.costType.toLowerCase() === 'material' && new Date(ws.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = costTypes.filter(x => x.value !== 'Material');
                } else if (ws.costType.toLowerCase() === 'labor' && new Date(ws.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = costTypes.filter(x => x.value !== 'Labor');
                } else if (ws.costType.toLowerCase() === 'travel' && new Date(ws.expiryDate) < new Date(this.soCreatedDate)) { // Update the data (Instead of travel it should be callOut from the API.)
                    costTypes = costTypes.filter(x => x.value !== 'CallOut');
                }
            });
            if ( this.equipmentInfo.warrantyDescriptionObj.findIndex(x => x.costType === CostTypes.material) === -1 ) {
                costTypes = costTypes.filter(x => x.value !== CostTypes.material);
            }
            if ( this.equipmentInfo.warrantyDescriptionObj.findIndex(x => x.costType === CostTypes.labor) === -1 ) {
                costTypes = costTypes.filter(x => x.value !== CostTypes.labor);
            }
            if ( this.equipmentInfo.warrantyDescriptionObj.findIndex(x => x.costType === CostTypes.other) === -1 ) {
                    costTypes = costTypes.filter(x => x.value !== 'CallOut');
            }
        } else if (args.itemData.referenceActivityDescription === 'Contract Service' && this.equipmentInfo) {
            this.equipmentInfo.serviceContractDescriptionObj.forEach(cs => {
                if (cs.costType === 'all' && new Date(cs.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = [];
                } else if (cs.costType.toLowerCase() === 'material' && new Date(cs.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = costTypes.filter(x => x.value !== 'Material');
                } else if (cs.costType.toLowerCase() === 'labor' && new Date(cs.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = costTypes.filter(x => x.value !== 'Labor');
                } else if (cs.costType.toLowerCase() === 'travel' && new Date(cs.expiryDate) < new Date(this.soCreatedDate)) {
                    costTypes = costTypes.filter(x => x.value !== 'CallOut');
                }
            });
            if ( this.equipmentInfo.serviceContractDescriptionObj.findIndex(x => x.costType === 'material') === -1 ) {
                costTypes = costTypes.filter(x => x.value !== CostTypes.material);
            }
            if ( this.equipmentInfo.serviceContractDescriptionObj.findIndex(x => x.costType === 'labor') === -1 ) {
                costTypes = costTypes.filter(x => x.value !== CostTypes.labor);
            }
            if ( this.equipmentInfo.serviceContractDescriptionObj.findIndex(x => x.costType === 'travel') === -1 ) {
                    costTypes = costTypes.filter(x => x.value !== 'CallOut');
            }
        }
        this.costTypeDDL.dataSource = costTypes;
    }

    updateTotalAmount() {
        this.data.warrantyCoverage = this.data.referenceActivityDescription === 'Warranty Service' && this.getWarrantyAmount() || 0;
        this.data.contractCoverage = this.data.referenceActivityDescription === 'Contract Service' && this.getContractAmount() || 0;
        this.data.totalAmount = this.data.qty && (this.data.price * this.data.qty) - (this.data.discountAmount || 0) - (this.data.warrantyCoverage || 0) - (this.data.contractCoverage || 0);
        this.data.taxAmount = +(this.data.totalAmount * (this.data.taxpercent/100)).toFixed(2);
    }

    getWarrantyAmount(row?) { //row: if called from calculateAmounts()
        if (row) {
            return row.warrantyCoverage;
        } else {
            let warrantyPercent = 0, warrantyCoverageIndex;
            let costType = this.data.costType.toLowerCase();
            if ( this.data.costType === CostTypes.other ) {
                costType = 'travel';
            }
            if ( this.data.costType === CostTypes.otherCost ) {
                costType = 'other'; // for kingslynn contract and warranty, we will get 'other' in the API getContractAndWarranty
            }
            warrantyCoverageIndex = this.equipmentInfo?.warrantyDescriptionObj?.findIndex(war => war.costType.toLowerCase() === costType || war.costType === 'all');
            if (warrantyCoverageIndex > -1) {
                warrantyPercent = this.equipmentInfo.warrantyDescriptionObj[warrantyCoverageIndex].percentageCoverage;
            }
            let totalPrice = (this.data.qty * this.data.price) - this.data.discountAmount;
            return totalPrice > 0 ? +(totalPrice * warrantyPercent / 100)?.toFixed(2) : 0;
        }
    }

    getContractAmount(row?) { //row: if called from calculateAmounts()
        if (row) {
            return row.contractCoverage;
        } else {
            let contractPercent = 0, contractCoverageIndex;
            let costType = this.data.costType.toLowerCase();
            if ( this.data.costType === CostTypes.other ) {
                costType = 'travel';
            }
            if ( this.data.costType === CostTypes.otherCost ) {
                costType = 'other'; // for kingslynn contract and warranty, we will get 'other' in the API getContractAndWarranty
            }
            contractCoverageIndex = this.equipmentInfo?.serviceContractDescriptionObj?.findIndex(contract => contract.costType === costType || contract.costType === 'all');
            if (contractCoverageIndex > -1) {
                contractPercent = this.equipmentInfo?.serviceContractDescriptionObj[contractCoverageIndex].percentageCoverage;
            }
            let totalPrice = (this.data.qty * this.data.price) - this.data.discountAmount;
            return totalPrice > 0 ? +(totalPrice * contractPercent / 100)?.toFixed(2) : 0;
        }
    }

    onQuantityChange(args: any) {
        this.data.qty = +args.value.toFixed(2);
        if (this.data.costType !== CostTypes.other) {
            if ( this.data.costType === CostTypes.material && this.data.itemCode ) {
                this.quantityChangeForMaterial(args);
            } else {
                if (!isNaN(parseInt(this.data.price))) {
                    const costTypeOther = this.costTypes.find(costType => costType.text === 'Other')?.value;
                    if ( this.data.costType !== costTypeOther ) {
                        this.updateTotalAmount();
                    }
                }
            }
        }
    }

    quantityChangeForMaterial(args) {
        const data = {
            company: this.USER.company,
            item: this.data.itemCode,
            unit: this.data.unit,
            quantity: this.data.qty,
            configuration: this.customerCode
        };
        if ( args.value != null ) {
            this.showLoader = true;
            this.serviceOrderService.getPrice(data)
            .subscribe((res: ApiResponse) => {
                if (res && res.isSuccessful && res.result) {
                    this.data.price = res.result.bookPrice ? +res.result.bookPrice.bookPriceValue.toFixed(2) : 0;
                    this.data.discountAmount = res.result.discountAmount ? +res.result.discountAmount.discountAmountValue.toFixed(2) : 0;
                    
                    if (!isNaN(parseInt(this.data.price))) {
                        const costTypeOther = this.costTypes.find(costType => costType.text === 'Other')?.value;
                        if ( this.data.costType !== costTypeOther ) {
                            this.updateTotalAmount();
                        }
                    }
                } else {
                    this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                }
                this.showLoader = false;
            }, (error: HttpErrorResponse) => {
                this.data.price = 0;
                this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                this.data.discountAmount = 0;
                this.showLoader = false;
            });
        }
    }

    onQuantityChangeBlur(args: any) {
        this.data.qty = args.value;
        if (!isNaN(parseInt(this.data.qty))) {
            this.updateTotalAmount();
        }
    }

    onDiscountChange(args: any) {
        this.data.discountAmount = args.value;
        if (!isNaN(parseInt(this.data.discountAmount))) {
            this.updateTotalAmount();
        }
    }
    
    onPriceChange(args: any) {
        this.data.price = +args.value.toFixed(2);
        if (!isNaN(parseInt(this.data.qty))) {
            this.updateTotalAmount();
        }
    }

    onServiceItemChange(args: any) {
        if (args && args.itemData) {
            this.getPriceForItem = args.isInteracted || this.itemChangedFromAdvanceSearch;
            // this.checkOnHandQuantity(args.itemData.value, this.data.qty);
            this.data.itemCode = args.itemData.value;
            this.data.itemDescription = args.itemData.description || args.itemData.itemDescription;
            
            if ( this.isKingslynn && args.itemData && this.coreItemGroup.includes(args.itemData.serviceItemGroup) ) {
                this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
            }
        }
        if ( args.value && args.value !== 'LOCAL' && this.getPriceForItem) {
            this.getItemPrice(args.value, this.data.unit, this.data.qty);
        }
        this.itemChangedFromAdvanceSearch = false;
    }

    getItemPrice(itemCode, unit, quantity = 1) {
        const data = {
            company: this.USER.company,
            item: itemCode,
            unit: unit,
            quantity,
            configuration: this.customerCode,
            iscCode: this.USER.iscCode
        };
        this.serviceOrderService.getPrice(data)
            .subscribe((res: ApiResponse) => {
                if (res && res.isSuccessful && res.result) {
                    this.data.price = res.result.bookPrice ? +res.result.bookPrice.bookPriceValue.toFixed(2) : 0;
                    this.data.discountAmount = res.result.discountAmount ? +res.result.discountAmount.discountAmountValue.toFixed(2) : 0;
                    this.updateTotalAmount();
                } else {
                    this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                }
            }, (error: HttpErrorResponse) => {
                this.data.price = 0;
                this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                this.data.discountAmount = 0;
            });
    }

    updateItemOnHandQuantity(itemCode, onHandQty){
        let calculatedOnHand = onHandQty;
        this.loadedAssignmentItemDetails.forEach(x => {
            if(itemCode === x.itemCode){
                calculatedOnHand += x.issuedQty;
            }
        });
        this.assignmentDetails.forEach(x => {
            if(itemCode === x.itemCode && !x.isDeleted){
                if(this.data.rowId != x.rowId)
                    calculatedOnHand -= x.qty;
            }
        });
        return calculatedOnHand;
    }

    calculateTaxAmount() {
        this.data.taxAmount = +(this.data.totalAmount * (this.data.taxpercent / 100)).toFixed(2);
    }

    onStartDateChange(args: any) {
        this.data.startDate = args.value;
        this.endDatePicker.min = args.value;
    }

    onEndDateChange(args: any) {
        this.data.endDate = args.value;
        this.startDatePicker.max = args.value;
    }

    onCostTypeChange(args: any) {
        this.data.costType = args.value || null;

        if (args.value === CostTypes.labor) {
            this.data.price = this.laborRate;
            this.data.itemCode = null;
            this.data.itemDescription = 'Labor charge';
        } else if (args.value === CostTypes.other) {
            this.data.price = this.calloutRate;
            this.data.itemCode = null;
            this.data.itemDescription = 'Travel call-out charge';
        } else if (args.value === CostTypes.material || args.value === CostTypes.otherCost) {
            this.data.price = 0;
            this.data.itemCode = null,
            this.data.itemDescription = null;
            if (this.USER.company === Companies.kingslynn ) {
                this.getItemList();
                this.quantityField.min = args.value === CostTypes.otherCost ? 1 : 0;
            }
        } else {
            this.data.itemCode = null;
            this.data.itemDescription = null;
            this.data.price = 0;
        }

        if ( !['Billable Service','Warranty Service', 'Contract Service'].includes(this.data.referenceActivityDescription) ) {
            this.data.discountAmount = this.data.price * this.data.quantity || 0;
        } else {
            this.data.discountAmount = 0;
        }

        this.data.laborType = this.getLaborType(args.value);
        
        this.quantityField.format = args.value === CostTypes.material ? 'n' : 'n2';
        this.quantityField.step = args.value === CostTypes.material ? 1 : 0.1;
        this.quantityField.decimals = args.value === CostTypes.material ? 0 : 2; 

        this.data.qty = 1;
        this.data.taxAmount = this.data.totalAmount = this.data.warrantyCoverage = this.data.contractCoverage = 0; 
        //this.data.qty * (this.data.referenceActivityDescription === 'Warranty Service' && this.getWarrantyAmount() || 0);
        //this.data.qty * (this.data.referenceActivityDescription === 'Contract Service' && this.getContractAmount() || 0);
        this.updateUnit(args.value);
        this.setCostComponent(args.value);
    }

    getLaborType(costType: string) {
        if (costType === CostTypes.labor) {
            return this.commonService.userDefaults && this.commonService.userDefaults.lbrCHLT ? this.commonService.userDefaults.lbrCHLT : null;
        } else {
            return null;
        }
    }

    setCostComponent(costType: string) {
        if (costType === IscCostTypes.labor) {
            this.data.costComponent = this.commonService.userDefaults && this.commonService.userDefaults.lbrCCMP ? this.commonService.userDefaults.lbrCCMP : null;
            this.data.task = this.commonService.userDefaults && this.commonService.userDefaults.lbrCTSK ? this.commonService.userDefaults.lbrCTSK : null;
            this.data.laborType = this.commonService.userDefaults && this.commonService.userDefaults.lbrCHLT ? this.commonService.userDefaults.lbrCHLT : null;
        } else if (costType === IscCostTypes.material) {
            this.data.costComponent = this.commonService.userDefaults && this.commonService.userDefaults.mtlCCMP ? this.commonService.userDefaults.mtlCCMP : null;
        } else if (costType === IscCostTypes.other) {
            this.data.costComponent = this.commonService.userDefaults && this.commonService.userDefaults.othCCMP ? this.commonService.userDefaults.othCCMP : null;
        } else {
            this.data.costComponent = null;
            this.data.task = null;
            this.data.laborType = null;
        }
    }

    updateUnit(costType: string) {
        if (costType === CostTypes.labor) {
            return this.units.find(unit => unit.description === 'Hours').value;
        } else if (costType === CostTypes.material || costType === CostTypes.other || costType === CostTypes.otherCost  ) {
            const unit = this.units.find(unit => unit.description === 'Each');
            return unit? unit.value :this.units[0].value;
        } else {
            return null;
        }
    }

    close() {
        if (this.transactionsForm.touched) {
            this.commonService.showConfirmation('Are you sure? All the changes will be lost and cannot be undone!')
            .then( (res) => {
                if ( res.isConfirmed ) {
                    this.equipmentInfo = null;
                    this.onClose.emit();
                }
            });
        } else {
            this.equipmentInfo = null;
            this.onClose.emit();
        }
    }

    saveAssignment() {
        if (this.data.costType === CostTypes.labor) { // labor
            this.data.itemCode = '';
            this.data.itemDescription = '';
            this.data.warehouse = '';
            this.data.returnReasonCode = null;
        } else if (this.data.costType === CostTypes.material) { // material
            this.data.laborType = null;
            this.data.task = null;
            this.data.startDate = null;
            this.data.endDate = null;
            if (!this.data.itemCode) {
                this.data.itemDescription = '';
            }
        } else { // other
            this.data.laborType = null;
            this.warehouse = '';
            this.data.returnReasonCode = null;
            this.data.task = null;
            this.data.startDate = null;
            this.data.endDate = null;
            if (!this.data.itemCode) {
                this.data.itemDescription = '';
            }
        }
        this.equipmentInfo = null;
        this.onSave.emit(this.data);
        this.onClose.emit();
    }

    onItemAdvancedSearchPopupClose(data: any) {
        this.itemChangedFromAdvanceSearch = true;
        // this.serviceOrderService.popupLoader.next(true);
        this.showItemAdvancedSearchPopup = false;

        this.itemsList.push({
            value : data.item,
            text : `${data.item} | ${data.itemDescription}`
        });

        this.data.itemDescription = data.itemDescription;
        this.data.itemCode = data.item;
        this.onServiceItemChange({itemData: {value: data.item, ...data}, value: data.item});
    }
}
