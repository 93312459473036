import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GridComponent, ExcelExportProperties, ForeignKeyService } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CommonService } from 'src/app/shared/services/common.service';
import { excelQueryCellInfo, getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { ReturnOrderReasons, returnOrderStatuses, returnOrdersCategoryTypes } from '../../../models/orders.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiResponse, UserRoles, gridNames } from 'src/app/shared/models/app.model';
import { InventoryService } from '../../../services/inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MouseEventArgs } from '@syncfusion/ej2-base';

@Component({
    selector: 'app-return-order-items-tab',
    templateUrl: './return-order-items-tab.component.html',
    styleUrls: ['./return-order-items-tab.component.scss'],
    providers: [
        ForeignKeyService
    ]
})
export class ReturnOrderItemsTabComponent implements OnChanges, OnInit, OnDestroy {

    
    onItemUpdate = new Subject<string>();
    private destroy$: Subject<null> = new Subject();
    
    @Input() status: any;
    @Input() items = [];
    @Input() category: any;
    @Input() reasonsDDL: any;
    @Input() warehouse: any;
    
    @ViewChild('returnOrderItemsGrid') returnOrderItemsGrid: GridComponent;
    @ViewChild('returnOrderItemsColumnChooser') columnChooser;
    
    columns: any;
    gridHeight: number;
    gridName = gridNames.returnOrderItemsGrid;
    itemsQuery: any;
    showColumnChooser: boolean = false;
    canEdit: boolean = false;
    skipBeginEditEvent: boolean = false;
    showItemAdvancedSearchPopup: boolean = false;
    selectedRowId: string;
    itemsList: any;
    reasonDescription: any;
    validationApplied: boolean = false;
    reasonValidationFail: boolean = false;

    itemsField;
    itemsObject;

    itemDescriptionField;
    itemDescriptionObject: any;

    itemGroup: any;
    itemGroupField;
    itemGroupObject: any;

    itemQuantityField;
    itemQuantityObject: any;

    itemReasonsField;
    itemReasonsObject: any;

    constructor(
        private commonService: CommonService,
        private inventoryService: InventoryService,
        private eRef: ElementRef
    ) {

    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (this.returnOrderItemsGrid.isEdit) {
            if (!this.eRef.nativeElement.contains(event.target)
                && !document.getElementById(gridNames.returnOrderItemsGrid + 'itemCode_popup')?.contains(event.target) && !document.getElementById(gridNames.returnOrderItemsGrid + 'reasonCode_popup')?.contains(event.target)) {
                this.returnOrderItemsGrid.endEdit();
            }
        }
    }

    ngOnInit(): void {
        this.gridHeight = document.getElementById('return-order-popup-body').clientHeight - 330;
        this.canEdit = this.commonService.roleClaims['Return_ItemsTabGrid_Toolbar_Add_Item']?.visible;
        this.warehouse = this.commonService.USER.role === UserRoles.fst ? this.commonService.USER.warehouse : this.warehouse;
        this.columns = this.getGridColumns();
        this.setItemsData();
        this.getItemList();
        this.onItemUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        ).subscribe(value => {
            if ( !value ) {
                this.getItemList();
            } else {
                this.getItemList(value);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.category.previousValue || changes.status.previousValue){
            this.resetLineNumbers();
            this.toggleButtonDisable();
            this.returnOrderItemsGrid?.refresh();
        }
    }

    load(args) {
        this.returnOrderItemsGrid.element.addEventListener('mouseup', (e: MouseEventArgs) => {
            if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
                const cellIndex = +(e.target as HTMLElement).getAttribute('data-colindex');
                this.returnOrderItemsGrid.isEdit && this.returnOrderItemsGrid.endEdit();
                let rowIndex: number = parseInt((e.target as HTMLElement).getAttribute("Index"));
                this.returnOrderItemsGrid.selectRow(rowIndex);
                this.returnOrderItemsGrid.startEdit();
                const clickedColumnName = this.returnOrderItemsGrid.getColumnByIndex(cellIndex)?.field;
                clickedColumnName && document?.getElementById(gridNames.returnOrderItemsGrid + clickedColumnName)?.focus();
            };
        });
    }

    getItemList(searchString: string = null) {
        this.inventoryService.popupLoader.next(true);
        const category = this.category.toLowerCase();
        this.inventoryService.getItems(this.warehouse, category, searchString)
        .subscribe((res: ApiResponse) => {
            if (res && res.result && res.result.length) {
                const existingItems = [];
                (this.returnOrderItemsGrid.dataSource as any).forEach(item => {
                    if (item.itemCode !== this.itemsObject?.value) {
                        existingItems.push(item.itemCode);
                    }
                });
                this.itemsList = res.result.filter(item => {
                    item.value = item.item;
                    item.text = `${item.item} | ${item.itemDescription}`;
                    if (existingItems.length) {
                        return !existingItems.includes(item.value)
                    } else {
                        return true;
                    }
                });
                const items = res.result;
                let isCore = existingItems.length && items.find(item => item.item === existingItems[0])?.core;
                if (existingItems.length) {
                    this.itemsList = items.filter(item => {
                        item.text = item.item + ' | ' + (item.description || item.itemDescription);
                        return !existingItems.includes(item.item) && isCore === item.core;
                    });
                } else {
                    this.itemsList = items.map(item => {
                        item.text = item.item + ' | ' + (item.description || item.itemDescription);
                        return item;
                    });
                }

                if (this.itemsObject) {
                    this.itemsObject.dataSource = this.itemsList;
                    this.itemsObject.hideSpinner();
                }
                this.inventoryService.popupLoader.next(false);
            } else {
                this.itemsList = [];
                if (this.itemsObject) {
                    this.itemsObject.hideSpinner();
                    this.itemsObject.dataSource = [];
                }
                this.inventoryService.popupLoader.next(false);
            }
        });
    }


    onGridCreated() {
        this.itemsQuery = new Query().where('isDeleted', 'equal', false);
        this.returnOrderItemsGrid.dataSource = this.items;
        this.returnOrderItemsGrid.toolbar = [
            { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
            'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = this.returnOrderItemsGrid.toolbar.findIndex(x => x === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.returnOrderItemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
        if (this.commonService.roleClaims['Return_ItemsTabGrid_Toolbar_Add_Item']?.visible) {
            this.returnOrderItemsGrid.toolbar.splice(1, 0, { text: '', id: 'add-item', align: 'Left', prefixIcon: 'e-add', tooltipText: 'Add Item' });
        }
        updateFilterIcon({ requestType: 'filtering' }, this.returnOrderItemsGrid);
    }

    actionBegin(args) {
        if (args.requestType === 'sorting') {
            if (this.returnOrderItemsGrid.isEdit) {
                args.cancel = true;
            }
        }
        if (args && args.requestType === 'beginEdit') {
            if ( args.rowData?.itemCode ) {
                this.getTechnicianOnHandCount({value: args.rowData.itemCode});
                if ( this.skipBeginEditEvent ) {
                    this.skipBeginEditEvent = false;
                } else {
                    this.getItemList(args.rowData?.itemCode);
                }
            }
            else{
              this.getItemList();
            }
        }
    }

    addEmptyRowsToItemsData() {
        for (let i = 0; i < 2; i++) {
          this.items.push(this.setItemsRow({}, this.items.length));
        }
    }

    setItemsData() {
        this.items?.map((item, index) => {
            return this.setItemsRow(item, index);
        });
        if (this.status === returnOrderStatuses.open) {
          this.addEmptyRowsToItemsData();
        }
    }

    setItemsRow(item, index) {
        item.id = item.id || 0,
        item.lineNumber = index + 1;
        item.itemCode = item.itemCode || item.item || '';
        item.itemDescription = item.itemDescription || item.description || '';
        item.itemGroup = item.itemGroup || '';
        item.serviceItemGroup = item.serviceItemGroup || '';
        item.quantity = item.quantity || 1;
        item.reasonCode = item.reasonCode || (
            this.category === returnOrdersCategoryTypes.serviceable
                ? ReturnOrderReasons.overStock.value
                : this.category === returnOrdersCategoryTypes.defective
                    ? ReturnOrderReasons.defective.value
                    : ReturnOrderReasons.damaged.value
        );
        item.reasonDescription  = item.reasonDescription  || null;
        item.createdBy = item.createdBy || '';
        item.createdByName = item.createdByName || null;
        item.lastUpdatedBy = item.lastUpdatedBy || '';
        item.lastUpdatedByName = item.lastUpdatedByName || '';
        item.isDeleted = item.isDeleted || false;
        return item;
    }

    actionComplete(args, grid) {
        // updateFilterIcon({ requestType: 'filtering' }, this.returnOrderItemsGrid);
        if (args.requestType === 'save') {
            this.toggleButtonDisable();
            const rowData = (this.returnOrderItemsGrid.dataSource as any).find(item => item.lineNumber === args.rowData.lineNumber);
            rowData.itemGroup = this.itemGroup;
            rowData.reasonDescription = this.reasonDescription;

            const emptyRecordsList = (this.returnOrderItemsGrid.dataSource as any).filter((item, index) => {
              if (!item.itemCode) {
                item.rowIndex = index;
                return true;
              }
            });
            if (emptyRecordsList?.length < 2) {
              this.addItem();
              setTimeout(() => {
                this.returnOrderItemsGrid.selectRow(emptyRecordsList[0].rowIndex);
                this.returnOrderItemsGrid.startEdit();
              }, 100);
            }

        }

        if (args.requestType === 'beginEdit') {
            if (this.reasonValidationFail) {
                const elementId = this.returnOrderItemsGrid.element.id + 'reasonCode';
                args.form.elements.namedItem(elementId).focus();
                this.reasonValidationFail = false;
            }
        }

        // const emptyRecordsList = (this.returnOrderItemsGrid.dataSource as any).filter((item, index) => {
        //   if (!item.itemCode) {
        //     item.rowIndex = index;
        //     return true;
        //   }
        // });
        // if (emptyRecordsList?.length < 2) {
        //   this.addItem();
        //   setTimeout(() => {
        //     this.returnOrderItemsGrid.selectRow(emptyRecordsList[0].rowIndex);
        //     this.returnOrderItemsGrid.startEdit();
        //   }, 100);
        // }

    }

    dataBound(args) {
        this.returnOrderItemsGrid?.toolbarModule?.enableItems(['add-item'], this.status === returnOrderStatuses.open);
    }


    getData() {
        return {
            itemList: (this.returnOrderItemsGrid?.dataSource as any).filter(x => x.itemCode)
        }
    }

    rowDataBound(args) {
        if (args.data && args.data.quantityReceived) {
            args.data.quantityReceived < args.data.quantity ? args.row.classList.add('bg-custom-danger') :
            args.data.quantityReceived === args.data.quantity ? args.row.classList.add('bg-custom-success') : args.row.classList.add('bg-custom-warning');
        }
    }
    commandColumnClick(args) {
        if (args.commandColumn.title === 'Delete Item') {
            this.returnOrderItemsGrid.endEdit();
            if (args.rowData.id || args.rowData.itemCode) {
            this.commonService.showConfirmation('Are you sure? You want to delete this item?').then(result => {
                if (result.isConfirmed) {
                const row = (this.returnOrderItemsGrid?.dataSource as any).find(x => x.lineNumber === args.rowData.lineNumber);
                row.lineNumber = args.rowData.lineNumber + '';
                row.isDeleted = true;
                this.resetLineNumbers();
                this.toggleButtonDisable();
                }
            })
            } else {
            const index = (this.returnOrderItemsGrid?.dataSource as any).findIndex(x => x.lineNumber === args.rowData.lineNumber);
            (this.returnOrderItemsGrid.dataSource as any).splice(index, 1);
            this.resetLineNumbers();
            this.toggleButtonDisable();
            }
        }
    }

    toggleButtonDisable() {
        const elem: any = document.getElementById('return-order-btn');
        elem.disabled = this.status !== returnOrderStatuses.open;
        if ( !elem.disabled) {
            const index = this.returnOrderItemsGrid ? this.findValidRow() : -1;
            elem.disabled = index < 0;
        }  
    }

    resetLineNumbers() {
        (this.returnOrderItemsGrid?.dataSource as any).filter(item => item.isDeleted === false).map((item, index) => {
            item.lineNumber = index + 1;
            return item;
        });
        if((this.returnOrderItemsGrid?.dataSource as any).filter(item => !item.itemCode).length < 2){
            if((this.returnOrderItemsGrid?.dataSource as any).filter(item => !item.itemCode).length === 0){
            this.items.push(this.setItemsRow({}, this.items.length));
            }
            this.items.push(this.setItemsRow({}, this.items.length));
        }
        this.returnOrderItemsGrid.refresh();
    }

    getGridColumns() {
        const disabled = this.status === returnOrderStatuses.open && !this.commonService.roleClaims['Return_ItemsTabGrid_Action_Delete_Item']?.disable ? 'e-flat' : 'e-flat disabled-grid-button';
        return [
            { field: 'lineNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, isPrimaryKey: true, visible: false, showInColumnChooser: false },
            { field: 'itemCode', headerText: 'Item', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, width: 250, edit: this.initItemsDDL(), editType: 'dropdownedit', validationRules: { required: false },},
            { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left', edit: this.initItemDescription() },
            { field: 'serviceItemGroup', headerText: 'Service Item Group', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, width: 150, edit: this.initItemGroup() },
            { field: 'quantity', headerText: 'Quantity', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'Left', width: 142, edit: this.initItemQuantity(), editType: 'numericedit', validationRules: { required: false } },
            { field: 'reasonCode', headerText: 'Reason', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, width: 150, edit: this.initItemReason(), editType: 'dropdownedit', foreignKeyField: 'value', foreignKeyValue: 'text', dataSource: this.reasonsDDL, validationRules: { required: false } },
            {
                field: 'Actions', headerText: 'Actions', textAlign: 'Center', allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 130,
                commands: [
                    { title: 'Delete Item', buttonOption: { iconCss: 'fas fa-trash', cssClass: `${disabled}` } }
                ]
            }
        ];
    }

    toolbarClick(args: ClickEventArgs, grid: GridComponent) {
        if (this.returnOrderItemsGrid.isEdit) {
            this.commonService.showNotification('warning', 'Item is in edit mode!');
        } else {
            if (args.item.id === 'grid_excelexport') {
                const dataSource = getExcelDataSource(grid);
                let excelExportProperties: ExcelExportProperties = {
                    dataSource,
                    hierarchyExportMode: 'Expanded',
                    theme: {
                        header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
                    },
                    fileName: `Return Orders Items(${getCurrentDate()}).xlsx`
                };
                grid.excelExport(excelExportProperties);
            } else if (args.item.id === 'grid_pdfexport') {
                grid.pdfExport();
            } else if (args.item.id === 'clear-filter') {
                grid.clearFiltering();
                grid.search('');
            } else if (args.item.id === 'column-chooser') {
                this.showColumnChooser = !this.showColumnChooser;
            }  else if (args.item.id === 'add-item') {
                this.inventoryService.popupLoader.next(true);
                if(this.items[this.items.length-1]?.itemCode == '' || this.items[this.items.length-2]?.itemCode == ''){
                    this.commonService.showNotification('warning', 'There are already empty row(s) present');
                    this.inventoryService.popupLoader.next(false);
                } else{
                    this.addItem();
                }
            }
            else if (args.item.id === 'add-item') {
                this.returnOrderItemsGrid.endEdit();
                grid.endEdit();
                if (!this.returnOrderItemsGrid.isEdit) {
                    grid.clearFiltering();
                    grid.search('');
                    grid.clearSorting();
                    this.addItem();
                } else {
                    this.commonService.showNotification("warning", "Item is in edit mode!");
                }
            }
        }
    }

    addItem() {
        let newIndex = (this.returnOrderItemsGrid.dataSource as any).filter(item => !item.isDeleted).length;
        let newRow = this.setItemsRow({}, newIndex);
        (this.returnOrderItemsGrid.dataSource as any).push(newRow);
        this.returnOrderItemsGrid.refresh();
        this.inventoryService.popupLoader.next(false);
    }

    validate() {
        this.returnOrderItemsGrid.endEdit();
        const index = this.findInvalidRow();
        if (!this.returnOrderItemsGrid.isEdit && index === -1) {
            return true;
        } else {
            return this.validationFailed();
        }
    }

    findValidRow(){
        return (this.returnOrderItemsGrid.dataSource as any).findIndex(element => ( element.reasonCode && element.quantity && element.itemCode) && !element.isDeleted);
    }

    findInvalidRow(){
        return (this.returnOrderItemsGrid.dataSource as any).findIndex(element => (!element.reasonCode ||!element.quantity) && element.itemCode && !element.isDeleted);
    }

    validationFailed(){
        this.commonService.showNotification('error', 'Mandatory fields in items are missing!');
        this.reasonValidationFail = true;
        const index = this.findInvalidRow();
        this.returnOrderItemsGrid.selectRow(index);
        setTimeout(() => {
            this.returnOrderItemsGrid.startEdit();
        }, 500);
        document.getElementById('items-tab-button').click();
        return false;
    }

    removeItems() {
        this.returnOrderItemsGrid.dataSource = [];
        this.returnOrderItemsGrid.refresh();
    }

    showItemPopup() {
        this.showItemAdvancedSearchPopup = true;
    }

    onItemAdvancedSearchPopupClose(data: any) {
        this.showItemAdvancedSearchPopup = false;
        const rowIndex = this.returnOrderItemsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
        this.returnOrderItemsGrid.selectRow(rowIndex);
        setTimeout(() => {
            this.returnOrderItemsGrid.startEdit();
            this.skipBeginEditEvent = true;
            setTimeout(() => {
                this.itemsObject.value = data.item;
                this.itemsObject.dataSource = [{
                    text: `${data.item} | ${data.itemDescription}`,
                    value: data.item,
                    ...data
                }];
            }, 400);
        }, 300);
    }

    addAdvancedSearchIcon() {
        const itemAnchor = document.createElement('a');
        itemAnchor.classList.add('item-advance-search-icon');
        itemAnchor.setAttribute('href', 'javascript:');
        itemAnchor.setAttribute('title', 'Advanced Search');
        itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
        const icon = document.createElement('i');
        icon.classList.add('fas');
        icon.classList.add('fa-info');
        itemAnchor.appendChild(icon);
        this.itemsField.parentElement.parentElement.append(itemAnchor);
    }

    initItemsDDL() {
        return {
            create: args => {
                this.selectedRowId = args.data.rowId;
                this.itemsField = document.createElement('input');
                return this.itemsField;
            },
            read: () => {
                if (this.itemsObject) {
                    return this.itemsObject.value;
                }
            },
            destroy: () => {
                this.itemsObject.destroy();
            },
            write: (args) => {
                this.itemsObject = new DropDownList({
                    dataSource: this.itemsList,
                    fields: { value: 'item', text: 'text' },
                    value: args.rowData.itemCode,
                    cssClass: 'generic-items-dropdown-list',
                    allowFiltering: true,
                    showClearButton: true,
                    popupWidth: '350px',
                    filterType: 'Contains',
                    filtering: args => { this.onItemUpdate.next(args.text); },
                    focus: args => { this.itemsObject.showPopup() },
                    blur: args => {
                        if(this.itemsObject['isSelectCustom']){
                            this.itemsObject.value = null;
                        }
                    },
                    change: (event: any) => {
                        if ( event.value && event.item ) {
                            this.itemGroupObject.value = event.itemData.serviceItemGroup;
                            this.itemDescriptionObject.value = event.itemData.description || event.itemData.itemDescription;
                            this.itemGroup = event.itemData.itemGroup;
                            if ( event.value ) {
                                this.getTechnicianOnHandCount(event);
                            }

                            if (event.isInteracted) {
                                const key = this.category === 'Serviceable' ? 'onHand' : this.category === 'Defective' ? 'isDefective' : 'inQuarantine';
                                // const row = this.inventoryItems.find(x => x.item === event.itemData.itemCode);
                                this.itemQuantityObject.value = 1;
                            }
                        }
                    }
                });
                this.itemsObject.appendTo(this.itemsField);
                this.addAdvancedSearchIcon();
            }
        }
    }

    getTechnicianOnHandCount(args) {
        this.inventoryService.popupLoader.next(true);
        this.inventoryService.getTechnicianOnHandCount(args.value, this.warehouse, this.category)
        .subscribe((res: ApiResponse) => {
            if (res && res.result) {
                this.returnOrderItemsGrid.getColumnByField('quantity').validationRules['max'] = [res.result.onHandCount, 'On Hand: ' + res.result.onHandCount];
                this.itemQuantityObject.max = res.result.onHandCount;
            }
            this.inventoryService.popupLoader.next(false);
        }, (error: HttpErrorResponse) => {
            this.inventoryService.popupLoader.next(false);
            throw error;
        });
    }

    initItemGroup() {
        return {
            create: () => {
                this.itemGroupField = document.createElement('input');
                return this.itemGroupField;
            },
            read: () => {
                if (this.itemGroupObject) {
                    return this.itemGroupObject.value;
                }
            },
            destroy: () => {
                this.itemGroupObject.destroy();
            },
            write: (args) => {
                this.itemGroupObject = new TextBox({
                    value: args.rowData.serviceItemGroup,
                    enabled: false
                });
                this.itemGroupObject.appendTo(this.itemGroupField);
            }
        }
    }

    initItemDescription() {
        return {
            create: () => {
                this.itemDescriptionField = document.createElement('input');
                return this.itemDescriptionField;
            },
            read: () => {
                if (this.itemDescriptionObject) {
                    return this.itemDescriptionObject.value;
                }
            },
            destroy: () => {
                this.itemDescriptionObject.destroy();
            },
            write: (args) => {
                this.itemDescriptionObject = new TextBox({
                    value: args.rowData.itemDescription,
                    enabled: false
                });
                this.itemDescriptionObject.appendTo(this.itemDescriptionField);
            }
        }
    }

    initItemQuantity() {
        return {
            create: () => {
                this.itemQuantityField = document.createElement('input');
                return this.itemQuantityField;
            },
            read: () => {
                if (this.itemQuantityObject) {
                    return this.itemQuantityObject.value;
                }
            },
            destroy: () => {
                this.itemQuantityObject.destroy();
            },
            write: (args) => {
                const key = this.category === 'Serviceable' ? 'onHand' : this.category === 'Defective' ? 'isDefective' : 'inQuarantine';
                // const row = this.inventoryItems.find(x => x.item === args.rowData.itemCode);
                this.itemQuantityObject = new NumericTextBox({
                    value: args.rowData.quantity,
                    showSpinButton: false,
                    min: 1,
                    format: 'n',
                    step: 1,
                    decimals: 0,
                    validateDecimalOnType: true,
                    // max: row ? row[key] : undefined,
                    created: args => {
                        this.itemQuantityField.onkeyup = event => {
                            if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                                this.itemQuantityObject.value = +event.target.value;
                            }
                        }
                    }
                });
                this.itemQuantityObject.appendTo(this.itemQuantityField);
            }
        }
    }

    initItemReason() {
        return {
            create: () => {
                this.itemReasonsField = document.createElement('input');
                return this.itemReasonsField;
            },
            read: () => {
                if (this.itemReasonsObject) {
                    return this.itemReasonsObject.value;
                }
            },
            destroy: () => {
                this.itemReasonsObject.destroy();
            },
            write: (args) => {
                this.itemReasonsObject = new DropDownList({
                    dataSource: this.reasonsDDL,
                    fields: { value: 'value', text: 'text' },
                    placeholder: 'Select Reason',
                    value: args.rowData.reasonCode,
                    allowFiltering: true,
                    filterType: 'Contains',
                    focus: args => { this.itemReasonsObject.showPopup() },
                    change: (args: any) => {
                        this.reasonDescription = args.itemData.description;
                    }
                });
                this.itemReasonsObject.appendTo(this.itemReasonsField);
            }
        }
    }

    ngOnDestroy() {
        this.onItemUpdate.unsubscribe();
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
