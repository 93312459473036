import { formatDate } from "@angular/common";
import {
    DataManager,
    DataUtil,
    QueryList,
    Query,
    ParamOption,
    ODataAdaptor,
    ODataV4Adaptor,
    QueryOptions
} from '@syncfusion/ej2-data';
import { Ajax } from '@syncfusion/ej2-base';
import { GridComponent, ActionEventArgs } from "@syncfusion/ej2-angular-grids";
import { TreeGridComponent } from "@syncfusion/ej2-angular-treegrid";


let filteredColType: string;
/**
 * Grid data manager custom adaptor
 */
export class CustomAdaptor extends ODataAdaptor {
    currentColFilterType: string;
    constructor() {
        super();
    }

    setQueryParams(query) {
        let obj: any = {
            requiresCounts: true
        };
        let params: ParamOption[] = query.params;
        let singles: QueryList = Query.filterQueryLists(query.queries, ['onSelect', 'onPage', 'onSkip', 'onTake', 'onRange']);
        let temp: QueryOptions;
        let skip: number;
        let take: number = null;
        let onSortBy = [];

        if ('onPage' in singles) {
            temp = singles.onPage;
            skip = DataUtil.getValue(temp.pageIndex, query);
            take = DataUtil.getValue(temp.pageSize, query);
            skip = (skip - 1) * take;
        } else if ('onRange' in singles) {
            temp = singles.onRange;
            skip = temp.start;
            take = temp.end - temp.start;
        } else if ('onTake' in singles) {
            temp = singles.onTake;
            skip = 0;
            take = temp.nos;
        }
        query.queries.length && query.queries.forEach(q => {
            if (q.fn === 'onSortBy') {
                onSortBy.push(q.e);
            } else {
                obj[q.fn] = q.e;
            }
        });

        if ( obj.onPage ) {
            filteredColType = '';
        }

        if (obj.onWhere ) {
            if ( obj.onWhere.predicates ) {

                obj.onWhere.predicates.map(p => {
                    p.oprtr = p.operator;
                    p.value = p.value === null || p.value === undefined ? '' : p.value;
                    if(typeof p.value === 'string'){
                        p.value = p.value;
                    } else if(typeof p.value === 'number' || typeof p.value === 'boolean'){
                        p.value = p.value + '';
                    } else if(Object.prototype.toString.call(p.value) === '[object Date]'){
                        p.value = p.value.toISOString();
                    }

                    if (p.predicates && p.predicates.length) {
                        p.predicates.map(pp => {
                            pp.oprtr = pp.operator;
                            pp.value = pp.value === null || pp.value === undefined ? '' : pp.value;
                            if(typeof pp.value === 'string'){
                                pp.value = pp.value;
                            } else if(typeof pp.value === 'number' || typeof pp.value === 'boolean'){
                                pp.value = pp.value + '';
                            } else if(Object.prototype.toString.call(pp.value) === '[object Date]'){
                                pp.value = pp.value.toISOString();
                            }
                        })
                    }
                });
            } else {
                // obj.onWhere.oprtr = obj.onWhere.operator;
            }
        }

        if (obj.onSearch) {
            obj.onSearch.oprtr = obj.onSearch.operator;
            obj.onSearch.fieldNames = obj.onSearch.fieldNames.filter(field => field && field !== 'Actions');
        }
        if (onSortBy.length) {
            obj.onSortBy = onSortBy;
        }
        let iscIndex = params.findIndex(param => param.key === 'isIsc');
        if ( iscIndex !== -1 ) {
            params.find(param => param.key === 'bpDescription')['oprtr'] = 'contains';
            params.find(param => param.key === 'city')['oprtr'] = 'contains';
            params.find(param => param.key === 'stateProvince')['oprtr'] = 'contains';
            const zipIndex = params.findIndex(param => param.key === 'zipCode');
            zipIndex > -1 ? params[zipIndex]['oprtr'] = 'contains' : '';
            const addressIndex = params.findIndex(param => param.key === 'address');
            addressIndex > -1 ? params[addressIndex]['oprtr'] = 'contains' : '';
            params.splice(iscIndex, 1); // Remove this parameter from the parameters list
        }

        const resourceSelectionIndex = params.findIndex(param => param.key === 'fromResourceSelectionPopup');
        if ( resourceSelectionIndex !== -1 ) {
            params.find(param => param.key === 'employeeId')['oprtr'] = 'notequal';
            params.splice(resourceSelectionIndex, 1); // Remove this parameter from the parameters list
        }

        iscIndex = params.findIndex(param => param.key === 'serialSearch');
        if ( iscIndex !== -1 ) {
            params.find(param => param.key === 'model')['oprtr'] = 'contains';
            params.find(param => param.key === 'serialNumber')['oprtr'] = 'contains';
            params.splice(iscIndex, 1); // Remove this parameter from the parameters list
        }

        const statusListIndex = params.findIndex(param => param.key === 'statusList');
        if( statusListIndex > -1 ) {
            obj.statusList = JSON.parse(params[statusListIndex].value);
            params.splice(statusListIndex, 1);
        }
        
        obj.parameters = params;
        obj.skip = skip || 0;
        obj.take = take || 20;

        return obj;
    }

    beforeSend(dm: DataManager, request: XMLHttpRequest, settings?: Ajax): void {
        const token: string = localStorage.getItem('access_token');
        request.setRequestHeader("Authorization", `Bearer ${token}`);
    }

    processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]) {
        // console.log(query);
        // this.action = query.action;
        let url = dm.dataSource.url;
        let obj = this.setQueryParams(query);
        return {
            type: "POST",
            url,
            data: JSON.stringify(obj),
            // headers: JSON.stringify(headers)
        };
    }

    processResponse() {
        // calling base class processResponse function
        const original = super.processResponse.apply(this, arguments);
        const response = {
            result: original && original.result ? original.result : [],
            count:  original && original.paginationData && original.paginationData.totalRecords ? original.paginationData.totalRecords : 0
        };
        if ( filteredColType ) {
            return original && original?.result || [];
        } else {
            return response;
        }
    }
}

export class CustomOdataV4Adaptor extends ODataV4Adaptor {
    currentColFilterType: string;
    constructor() {
        super();
    }

    beforeSend(dm: DataManager, request: XMLHttpRequest, settings?: Ajax): void {
        const token: string = localStorage.getItem('access_token');
        request.setRequestHeader("Authorization", `Bearer ${token}`);
    }
}

export const updateFilterIcon = (args: any, grid: GridComponent | TreeGridComponent) => {
    let isFiltered = false;
    if ( args?.requestType === 'filtering' || args?.requestType === 'searching' || args?.requestType === 'refresh') {
        isFiltered = grid.filterSettings.columns.length || (grid.searchSettings.key && grid.searchSettings.key.length) ? true : false;
        const gridElement = document.getElementById(grid.element.id);
        if ( isFiltered ) {
            gridElement.getElementsByClassName('grid-filter-icon')[0]?.classList.add('filtered');
        } else {
            gridElement.getElementsByClassName('grid-filter-icon')[0]?.classList.remove('filtered');
        }
    }
}

export const excelQueryCellInfo = (args: any) => {
    if ( args.column.field === 'status' || args.column.field === 'reasonDescription' ) {
        args.style = {
            backColor: getStatusColor(args.value),
            fontColor: '#ffffff',
            bold: true
        };
    } else if ( args.column.field.includes('OrderId') ) {
        args.style = {
            bold: true
        };
    }
}

export const firstLetterUpperCase = (word: string) => {
    return word.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

const getStatusColor = (status: string) => {
    status = status.toLowerCase();
    if (['in progress', 'inprogress', 'delivery acknowledged'].includes(status)) {
        return '#199efd';
    }else if  ([ 'partially shipped', 'partiallyshipped',].includes(status)) {
        return '#FF7F50';
    }
     else if (['open', 'total', 'draft', 'unknown'].includes(status)) {
        return '#656363';
    } else if (['scheduled', 'ready to be shipped', 'order sent', 'pending approval', 'short shipment'].includes(status)) {
        return '#f3ae4b';
    } else if (['completed', 'delivered', 'closed', 'estimate approved'].includes(status)) {
        return '#479e47';
    } else if (['interrupted', 'cancelled', 'canceled', 'estimate rejected', 'damaged'].includes(status)) {
        return '#9e1414';
    } else if (['received in ln', 'received in ms', 'en route'].includes(status)) {
        return '#9399ff';
    } else if (['enroute'].includes(status)) {
        return '#0bf9b7';
    } else if (['exceptions','paused', 'wrong part'].includes(status)) {
        return '#ca7671';
    } else if (['shipped', 'awaiting payment', 'excess shipment'].includes(status)) {
        return '#f7a56c';
    }
}

export const updateRow = row => {
    if ( row.startDate ) { row.startDate = formatDate( row.startDate, 'mediumDate', 'en' ); }
    if ( row.startTime ) { row.startTime = formatDate( row.startTime, 'medium', 'en' ); }
    if ( row.endDate ) { row.endDate = formatDate( row.endDate, 'mediumDate', 'en' ); }
    if ( row.endTime ) { row.endTime = formatDate( row.endTime, 'medium', 'en' ); }
    if ( row.createdDate ) { row.createdDate = formatDate( row.createdDate, 'mediumDate', 'en' ); }
    if ( row.CreatedDate ) { row.CreatedDate = formatDate( row.CreatedDate, 'mediumDate', 'en' ); }
    if ( row.lastUpdatedDate ) { row.lastUpdatedDate = formatDate( row.lastUpdatedDate, 'mediumDate', 'en' ); }
}

export const getExcelDataSource = (grid: any) => {
    const selectedRecords = JSON.parse(JSON.stringify(grid.getSelectedRecords()));

    const data = selectedRecords.length && selectedRecords || grid.getCurrentViewRecords();
    const dataSource = JSON.parse(JSON.stringify(data));
    dataSource.map( row => {
        updateRow(row);
    });
    return dataSource;
}

export const getXMLDataSource = (grid: any) => {
    const selectedRecords = JSON.parse(JSON.stringify(grid.getSelectedRecords()));

    const data = selectedRecords.length && selectedRecords || grid.getCurrentViewRecords();
    const dataSource = JSON.parse(JSON.stringify(data));
    dataSource.map( row => {
        updateRow(row);
    });
    return dataSource;
}

export const gridActionBeginForFiltering = (args, grid?) => {
    if(args.requestType === 'filterbeforeopen') {
        filteredColType = '';
    }
}

// To fix the Excel filter issue in OData V4
export const gridActionBeginForFilteringOdataV4 = (args) => {
    if (args.requestType === "filterchoicerequest" || args.requestType === "filtersearchbegin") {
        var filterfields = [];
        var objFilter = Object.keys(args.filterModel.existingPredicate);
        for (var i = 0; i < objFilter.length; i++) {
          filterfields.push(objFilter[i]);
        }
        filterfields.push(args.filterModel.options.field);
        args.query.distincts = [];
        args.query.select(filterfields);
    }
}

export const gridActionsForFiltering = (args, grid) => {
    if (args.requestType === "filterafteropen") {
        filteredColType = args.filterModel.isExcel ? 'Excel' : args.filterModel?.col?.filter?.type;
        // if ( filteredColType === 'Menu') {
        //     let autoCompleteElem = args.filterModel?.dlgObj?.element?.querySelector('.e-autocomplete');
        //     let autoCompleteInst : AutoComplete = autoCompleteElem.ej2_instances[0];
        //     if ( autoCompleteElem ) {
        //         autoCompleteInst.filtering = (f_args) => {
        //             f_args.preventDefaultAction = true;
        //           };
        //     }
        // }
    } else {
        filteredColType = '';
    }
}
