
<div class="row">
    <div class="col mt-4">
        <ejs-grid #shipmentOrderItemsGrid
            id="itemsGrid"
            [dataSource]="items"
            [height]="gridHeight"
            [allowPaging]="false"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [enablePersistence]="false"
            [allowExcelExport]="true"
            [query]="itemsQuery"
            [editSettings]="{ allowEditing: 'canEditItemGrid', allowAdding: false, allowDeleting: false }"
            [filterSettings]="{ type: 'Menu' }"
            (toolbarClick)="toolbarClick($event)"
            (created)="onGridCreated()"
            (actionBegin)="actionBegin($event)"
            (actionComplete)="actionComplete($event)"
            (load)="load($event)"
        >
            <e-columns>
                <e-column
                    *ngFor="let column of columns;"
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [clipMode]="'EllipsisWithTooltip'"
                    [type]="column.type"
                    [edit]="column.edit"
                    [width]="column.width"
                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                    [format]="column.format ? column.format : null"
                    [textAlign]="column.textAlign"
                    [visible]="column.visible"
                    [isPrimaryKey]="column.isPrimaryKey || false"
                    [validationRules]="column?.validationRules || null"
                    [commands]="column?.commands ? column.commands : null"
                    [allowEditing]="column.allowEditing || false"
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.field === 'partsOrderRef'" #template let-data>
                        <a href="javascript:" (click)="onPartOrderClick(data)">{{data.partsOrderRef}}</a>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #shipmentOrderItemsGridColumnChooser>
    <app-column-chooser
        [grid]="shipmentOrderItemsGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>


<app-linked-popup-opener
    *ngIf="linkedPopupData"
    [data]="linkedPopupData"
    (onPopupClose)="linkedPopupData = null;"
></app-linked-popup-opener>

<app-wrong-parts-list
    *ngIf="showWrongItemPopup"
    [shipmentOrderId]="currentRowData.shipmentOrderId"
    [wrongItemsList]="currentRowData"
    [quantity]="wrongPartObject.value"
    (onClose)="onWrongItemPopupClose($event)"
></app-wrong-parts-list>
