import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { TokenRefreshService } from 'src/app/shared/services/token-refresh.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnInit {

    isLogged: boolean = false;
    redirectUrl: string; // if user is not logged in user will be redirected to the accessed page after login
    route;

    constructor(
        private http: HttpClient,
        private router: Router,
        private tokenRefreshService: TokenRefreshService,
        private errorHandler: ErrorHandlerService,
    ) {
        this.isLogged = window.localStorage.getItem('access_token') ? true : false;

        
        this.tokenRefreshService.startTokenRefresh()
        .subscribe(
            (res:any) => {
                if(res){
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('token_expiry');
                    localStorage.setItem('access_token', res.accessToken);
                    localStorage.setItem('refresh_token', res.refreshToken);
                    localStorage.setItem('token_expiry', ''+new Date(res.expiration).getTime());
                    this.tokenRefreshService.calculateIntervalDuration();
                }
            }, // Log success or handle error
            (error) => {
                localStorage.clear();
                this.errorHandler.handleHttpError(error);
            }
          );
    }

    ngOnInit() {
    }

    login(email: string, password?: string) {
        return this.http.post(`${environment.loginUrl}Account/login`, { email, password });
    }

    validateToken() {
        return this.http.get(`${environment.apiUrl}Account/ValidatedToken`);
    }

    signOut(isIsc?: boolean) {
        localStorage.clear();
        this.isLogged = false;
        // If the user is an ISC employee he should be redirected to login app
        window.location.href = environment.loginAppUrl;
    }

    handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.status === 401) {
            this.signOut();
        }
        // console.log(errorResponse);
    }    

    refreshSSOToken(token: string, refreshToken: string) {
        return this.http.post(`${environment.apiUrl}Account/refreshSSO`, {token, refreshToken});
    }
    
    generateApplicationToken() {
        return this.http.get(`${environment.apiUrl}Account/GenerateApplicationToken`);
    }
    
    getExternalToken(token: string, refreshToken?: string) {
            const data = {
              'Token':token, 
              'RefreshToken':refreshToken
            }
        return this.http.post(`${environment.webAuthUrl}Authentication/generateExternalToken`, data);
    }

}
