import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-service-order',
    templateUrl: './service-order.component.html'
})
export class ServiceOrderComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
