import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuComponent, SelectEventArgs, SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { MenuItemModel } from '@syncfusion/ej2-navigations';
import { CommonService } from '../../shared/services/common.service';
import jwt_decode from "jwt-decode";
import { HomeService } from './services/home.service';
import { AuthService } from '../auth/services/auth.service';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

interface MenuItem {
  id: string;
  text: string;
  iconCss: string;
  items: MenuItem[];
  subChild?: MenuItem[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [CommonService],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  @ViewChild('sidebarMenuInstance')

  public sidebarMenuInstance: SidebarComponent;
  public width: string = '220px';
  public mediaQuery: string = ('(min-width: 600px)');
  public target: string = '.main-content';
  public dockSize: string = '50px';
  public enableDock: boolean = true;
  public USER;
  private claims = []
  // public menuItemsArray: any[];
  isManager: boolean = false
  userId: string;
  employeeId: string
  showCycleCountExceptionPopup: boolean = false
  exceptionPopupData;

  isIsc: boolean;
  isIscManager: boolean;
  isFst: boolean;

  companies = Companies;

  public menuItemsArray:any = [];

  public menuItems: Object = { 
    id: 'id', 
    text: 'text', 
    child: 'subChild', 
    iconCss: 'iconCss',
    dataSource: [{ id: '/home/dashboard', text: 'Dashboard', iconCss: 'fas fa-tachometer-alt', items: []}] 
  };

  @ViewChild('sidebarMenuInstance') mainMenu: MenuComponent;
  public AccountMenuItem: Object[] = [];
  editLayout: boolean = false;
  dashboardEnabled: boolean = false;
  editDashboardPods: boolean = false;
  company: any;

  constructor(
      private router: Router,
      public commonService: CommonService,
      private auth: AuthService,
      private homeService: HomeService
  ) {
      this.commonService.onSideNavToggle().subscribe(
          (opening) => {
              if (opening) {
                  this.dashboardEnabled = true;
              } else {
                  this.dashboardEnabled = false;
              }
          }
      );
  }

  ngOnInit(): void {
      this.setLoggedUserData();
  }
  
  setOpenDashboardPanelDialog() {
    this.commonService.setOpenDashboardPanelDialog(true);
  }

  resetDashboardLayout() {
    this.commonService.resetDashboardLayout(true);
  }

  editDashboardLayout() {
    this.editLayout = true;
    this.commonService.editDashboardLayout(true);
  }

  saveDashboardLayout() {
    this.editLayout = false;
    this.commonService.saveDashboardLayout(true);
  }

  setLoggedUserData() {
    this.commonService.getUserDataByUserId()
    .subscribe((userDataResponse: any) => {
      // api subscribe start
      if ( userDataResponse && userDataResponse.isSuccessful ) {
        const user = userDataResponse.result;
        this.employeeId = user.employeeId
        this.company = user.company;
        localStorage.setItem('employeeId', user.employeeId);
        localStorage.setItem('company', user.company);
        this.commonService.getUserById(this.employeeId).subscribe((res: ApiResponse) => {
          if (res.isSuccessful) { 
            res.result.userName = res.result.employeeName; // we are getting full name in employee name
            res.result.userId = res.result.employeeId;
            this.commonService.USER = res.result;
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  this.commonService.USER.lat = position.coords.latitude;
                  this.commonService.USER.long = position.coords.longitude;
                },
                (positionDisabled) => {
                  this.commonService.USER.lat = 47.61397;
                  this.commonService.USER.long = -122.3352;
                }
                );
            }
            this.AccountMenuItem = [
              {
                text: res.result.firstName + ' ' + res.result.lastName,
                items: [
                  {
                    id: '/home/my-profile',
                    text: 'My Profile'
                  },
                  {
                    id: '/app/logout',
                    text: 'Sign out'
                  }
                ]
              }
            ];
            this.getRuleActionsByRole(res.result)
          }
        });
      }

      //Forkjoin api subscribe end
    }, error => {
        throw error;
    });
  }

  getRuleActionsByRole(user) {
    forkJoin([
      this.commonService.getRuleActionsByRole(user.role), 
    ]).subscribe((res: any) => {
        // first api sub
        // this.commonService.getRuleActionsByRole(role).subscribe((rules: any) => {
        res[0].forEach(rule => {
            this.claims[rule.RuleAction] = {
                visible: rule.Visible,
                readOnly: rule.ReadOnly,
                edit: rule.Edit,
                disable: rule.Disable,
            }
        });
        this.commonService.roleClaims = this.claims
        localStorage.setItem('roleClaims', JSON.stringify(res[0]));
        this.cycleCountAlerts();
        
        if (!user?.isSignatureCreated && this.commonService.roleClaims['UserProfile_MissingSignaturePopup']?.visible) {
          this.commonService.showConfirmation("You have not added a signature yet, Do you wish to add it now?", "Yes", "Later", "Missing Signature")
          .then((resp: any) => {
            if (resp.isConfirmed) {
              this.router.navigate(['/home/my-profile']);
            }
          });
        }
        this.populateNavigationItems();        
      }, () => {
        this.commonService.showNotification('error', "Something went wrong in fetching data.");
    });
  }

  populateNavigationItems() {
    this.showDashboardInNavigation();
    this.showMaintenanceDashboardInNavigation();
    this.showMapsInNavigation();
    this.showServiceOrderInNavigation();
    this.showCalibrationInNavigation();
    this.showClaimsAndEmployeesInNavigation();
    this.showPriceBookInNav();
    this.showInventory();
    this.showInventoryOrders();
    this.showInventoryTransfer();
    this.showInventoryAdjustment();
    this.showInventoryTransactionHistory();
    this.showPartsCatalogInNavigation();
    this.showPurchaseOrdersInNavigation();
    this.showCycleCountInNavigation();
    this.showMasterDataInNavigation();
    this.showUsersInNavigation();
    this.showIscRulesInNavigation();
    this.showFreightChargeInNavigation();
    this.showRefEquipmentInNavigation();
  }

  showDashboardInNavigation() {
    this.menuItemsArray.push({
      id: '/home/dashboard', 
      text: 'Dashboard', 
      iconCss: 'fas fa-tachometer-alt', 
      items: []
    });
    this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
  }

  showMaintenanceDashboardInNavigation() {
    if (this.commonService.roleClaims['MenuItem_MaintenanceDashboard']?.visible) {
        this.menuItemsArray.push({
          id: '/home/maintenance-dashboard',
          text: 'Maintenance Dashboard',
          iconCss: 'fas fa-tachometer-alt',
          items: []
        });
        this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showMapsInNavigation() {
    if(this.commonService.roleClaims['MenuItem_Maps_SO_Locator']?.visible || this.commonService.roleClaims['MenuItem_Maps_Technichian_Locator']?.visible) {
      const mapsNavItem = {
        id: 'maps',
        text: 'Maps',
        iconCss: 'fas fa-map',
        items: [],
        subChild: []
      }

      if(this.commonService.roleClaims['MenuItem_Maps_Technichian_Locator']?.visible) {
        mapsNavItem.subChild.push({
          id: '/home/tecnichian-locator',
          text: 'Technician Locator',
          iconCss: 'fas fa-map',
          items: []
        });
      }
      if(this.commonService.roleClaims['MenuItem_Maps_SO_Locator']?.visible) {
        mapsNavItem.subChild.push({
          id: '/home/service-order-locator',
          text: 'Service Order Locator',
          iconCss: 'fas fa-map',
          items: []
        });
      }
      
      this.menuItemsArray.push(mapsNavItem);
    }
    this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
  }

  showServiceOrderInNavigation() {
    const srvcOrdVisible = this.commonService.roleClaims['MenuItem_SrvcOrd']?.visible;
    const iscSrvcOrdVisible = this.company === Companies.conway && this.commonService.roleClaims['MenuItem_IscSrvcOrd']?.visible;
  
    if (srvcOrdVisible || iscSrvcOrdVisible) {
      const menuItems = [];

      if (srvcOrdVisible) {
        menuItems.push({
          id: '/home/service-order',
          text: 'Service Orders',
          iconCss: 'fas fa-tasks',
          items: [],
        });
      }
  
      if (iscSrvcOrdVisible) {
        menuItems.push({
          id: '/home/service-order/isc',
          text: 'ISC Service Orders',
          iconCss: 'fas fa-tasks',
          items: [],
        });
      }
  
      if (menuItems.length > 1) {
        this.menuItemsArray.push({
          id: 'service-order',
          text: 'Service Orders',
          iconCss: 'fas fa-tasks',
          items: [],
          subChild: menuItems,
        });
      } else {
        this.menuItemsArray.push(menuItems[0])
      }
  
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }
  

  showCalibrationInNavigation() {
    if (this.company === this.companies.kingslynn ) {
          this.menuItemsArray.push({
              id: '/home/calibration',
              text: 'Calibrations',
              iconCss: 'fas fa-drafting-compass',
              items: []
          });
      }
      this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
  }
  
  showClaimsAndEmployeesInNavigation() {
    if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_Claims']?.visible ) {
        this.menuItemsArray.push({
            id: '/home/claims',
            text: 'Claims',
            iconCss: 'fas fa-boxes',
            items: []
        });
        this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }

    if (this.commonService.roleClaims['MenuItem_Employees']?.visible) {
      this.menuItemsArray.push({
        id: '/home/employees',
        text: 'Employees',
        iconCss: 'fas fa-users',
        items: []
      });
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showPriceBookInNav() { 
    this.menuItemsArray.push({
      id: '/home/books',
      text: 'Price Book',
      iconCss: 'fas fa-coins',
      items: []
    });
    this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
  }

  showInventory(){
    if ( this.commonService.roleClaims['MenuItem_InvView']?.visible ) {
      this.menuItemsArray.push({
        id: 'inventory-view',
        text: 'Inventory View',
        iconCss: 'fas fa-warehouse',
        items: [],
        subChild: [
          {
            id: '/home/inventory/serviceable',
            text: 'Serviceable',
            iconCss: 'fas fa-warehouse',
            items: []
          },
          {
            id: '/home/inventory/defective-core-return',
            text: 'Core Returns',
            iconCss: 'fas fa-warehouse',
            items: []
          },
          {
            id: '/home/inventory/quarantined',
            text: 'Damaged',
            iconCss: 'fas fa-warehouse',
            items: []
          },
          {
            id: '/home/inventory/wrong-parts',
            text: 'Wrong Parts',
            iconCss: 'fas fa-warehouse',
            items: []
          },
          {
            id: '/home/inventory/unknown',
            text: 'Unknown',
            iconCss: 'fas fa-warehouse',
            items: []
          },
          {
            id: '/home/inventory/exceptions-list',
            text: 'Exceptions',
            iconCss: 'fas fa-warehouse',
            items: []
          },
        ]
      });
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showInventoryOrders(){
    if ( this.commonService.roleClaims['MenuItem_InvOrders']?.visible ) {
      const inventoryAdjustmentNavItem = {
        id: 'inventory-orders',
        text: 'Inventory Orders',
        iconCss: 'fas fa-boxes',
        items: [],
        subChild: [
          {
            id: '/home/orders/parts-orders',
            text: 'Parts',
            iconCss: 'fas fa-boxes',
            items: []
          },
          {
            id: '/home/orders/return-orders',
            text: 'Serviceable Return',
            iconCss: 'fas fa-boxes',
            items: []
          },
          {
            id: '/home/orders/core-return-orders',
            text: 'Core Returns',
            iconCss: 'fas fa-boxes',
            items: []
          },
          {
            id: '/home/orders/damaged-return-orders',
            text: 'Damaged Returns',
            iconCss: 'fas fa-boxes',
            items: []
          },
          {
            id: '/home/orders/shipment-orders',
            text: 'Receipts',
            iconCss: 'fas fa-boxes',
            items: []
          }
        ]
      };
      if (this.commonService.roleClaims['Inv_Order_showAdjustmentTab']?.visible) {
        inventoryAdjustmentNavItem.subChild.push({
          id: '/home/orders/adjustment-orders',
          text: 'Adjustment',
          iconCss: 'fas fa-boxes',
          items: []
        });
      }
      this.menuItemsArray.push(inventoryAdjustmentNavItem);
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showInventoryTransfer() {
    if ( this.commonService.roleClaims['MenuItem_InvTransfer']?.visible ) {
      this.menuItemsArray.push({
        id: 'inventory-transfer',
        text: 'Inventory Transfer',
        iconCss: 'fas fa-people-carry',
        items: [],
        subChild: [
            {
                id: '/home/inventory-transfer',
                text: 'Technician Inventory',
                iconCss: 'fas fa-people-carry',
                items: []
            },
            {
                id: '/home/order-history',
                text: 'Order History',
                iconCss: 'fas fa-people-carry',
                items: []
            }
        ]
      });
      this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }
  
  showInventoryAdjustment() {
    if ( this.commonService.roleClaims['MenuItem_InvAdjust']?.visible ) {
          this.menuItemsArray.push({
            id: '/home/inventory-adjustment-orders',
            text: 'Inventory Adjustment',
            iconCss: 'fas fa-pallet',
            items: []
          });
          this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
      }
  }

  showInventoryTransactionHistory() {
    if ( this.commonService.roleClaims['MenuItem_InvTransactionHistory']?.visible ) {
          this.menuItemsArray.push({
              id: '/home/inventory-transaction-history',
              text: 'Inventory Transaction History',
              iconCss: 'fas fa-history',
              items: []
          });
          this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
      }
  }

  showCycleCountInNavigation() {
      if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_CycleCounts']?.visible) {
          this.menuItemsArray.push({
              id: '/home/cycle-count',
              text: 'Cycle Counts',
              iconCss: 'fas fa-tasks',
              items: []
          });
          this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
      }
  }

  showMasterDataInNavigation() {
      if (this.commonService.roleClaims['MenuItem_MasterData']?.visible) {
          this.menuItemsArray.push({
              id: '/master-data',
              text: 'Master Data',
              iconCss: 'fas fa-database',
              items: []
          });
          this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
      }
  }

  showUsersInNavigation() {
    if( this.commonService.roleClaims['MenuItem_Users']?.visible ) {
      this.menuItemsArray.push({
              id: '/home/users',
              text: 'Users',
              iconCss: 'fas fa-users',
              items: []
          });
      this.menuItems ={ dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showIscRulesInNavigation() {
    if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_IscRules']?.visible) {
        const inventoryAdjustmentIndex = this.menuItemsArray.findIndex(item => item.text === 'Inventory Adjustment');
        this.menuItemsArray.push({
            id: '/home/isc-rules',
            text: 'ISC Rules',
            iconCss: 'fas fa-clipboard-list',
            items: []
        });
        this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showPartsCatalogInNavigation() {
    if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_PartsCatalog']?.visible) {
      this.menuItemsArray.push({
        id: '/home/parts-catalog',
        text: 'Parts Catalog',
        iconCss: 'fas fa-shopping-cart',
        items: []
      });
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showPurchaseOrdersInNavigation() {
    if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_MyOrders']?.visible) {
      this.menuItemsArray.push({
        id: '/home/my-orders',
        text: 'Purchase Orders',
        iconCss: 'fas fa-file-invoice-dollar',
        items: []
      });
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showFreightChargeInNavigation() {
    if (this.company === Companies.conway && this.commonService.roleClaims['MenuItem_FreightCharges']?.visible) { //MenuItem_freightCharges
      this.menuItemsArray.push({
        id: '/home/frieght-charges',
        text: 'Frieght Charges',
        iconCss: 'fas fa-truck-loading',
        items: []
      });
      this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
    }
  }

  showRefEquipmentInNavigation() {
      if ( this.company === this.companies.kingslynn ) {
          this.menuItemsArray.push( {
              id: '/home/refEquipment',
              text: 'Ref. Equipment',
              iconCss: 'fas fa-drafting-compass',
              items: []
          });
          this.menuItems = { dataSource: this.menuItemsArray, id: 'id', text: 'text', child: 'subChild', iconCss: 'iconCss' };
      }
  }

  // showInventoryAdjustmentOrders() {
  //     const role = this.commonService.USER.role;
  //     if ( role === UserRoles.conwayServiceManager ) {  //User role check has been removed after addition of technician role in the creation of inventory adjustment order

  //         this.menuItems.push({
  //             id: '/home/inventory-adjustment-orders',
  //             text: 'Inventory Adjustment',
  //             iconCss: 'fas fa-boxes',
  //             items: []
  //         });
  //         this.menuItems = [...this.menuItems];
  //     }
  // }

  checkIfReminderDialogShown(): boolean {
    const dialogShown = localStorage.getItem('ReminderDialogShown');
    return dialogShown === 'true';
  }

  setReminderDialogShown(): void {
    localStorage.setItem('ReminderDialogShown', 'true');
  }

  checkIfExceptionDialogShown(): boolean {
    const dialogShown = localStorage.getItem('ExceptionDialogShown');
    return dialogShown === 'true';
  }

  setExceptionDialogShown(): void {
    localStorage.setItem('ExceptionDialogShown', 'true');
  }

  cycleCountAlerts() {

    const apiList = [
      this.commonService.getTechnicianCycleCountReminder(this.employeeId),
    ];
    if (this.commonService.roleClaims['CycleCountsExceptionsAlert']?.visible) {
      apiList.push(this.commonService.getServiceManagerCycleCountsForAlerts(this.employeeId))
    }

    forkJoin(apiList).subscribe((res: any) => {
      if (res[0]?.isSuccessful) {
        if (res[0]?.result != null) {
          if (!this.checkIfReminderDialogShown()) {
            Swal.fire({
              icon: 'info',
              title: 'Reminder',
              text: res[0]?.result,
              confirmButtonColor: '#479e47',
              width: '400px'
            }).then((result) => {
              this.setReminderDialogShown();
            });
          }
        }
      }
      if (res[1]?.length > 0) {
        if (!this.checkIfExceptionDialogShown()) {
          this.exceptionPopupData = res[1];
          this.showCycleCountExceptionPopup = true
        }
      }
    }, (error: HttpErrorResponse) => {
      this.commonService.showNotification('error', error.message)
      throw error;
    });
  }

  onCloseCycleCountExceptionPopup() {
    this.showCycleCountExceptionPopup = false
    this.setExceptionDialogShown()
  }

  openClick() {
    this.sidebarMenuInstance.toggle();
  }

  sidebarToggle(args) {
    this.homeService.sidebarToggle.next(args)
  }

  onMenuItemSelect(args: any) {
      const id = args?.node?.dataset?.uid;
      if(!['inventory-transfer', 'inventory-view', 'maps', 'inventory-orders', 'service-order'].includes(id)) {
          this.router.navigate([id]);
      }else {
          if(!this.sidebarMenuInstance.isOpen) {
              this.sidebarMenuInstance.toggle();
          }
      }
  }

  onUserMenuClick(args: any) {
      const item = args.item.properties;
      if (item.text === 'My Profile') {
        this.router.navigate([item.id])
      }
      if (item.text === 'Sign out') {
          this.auth.signOut(this.commonService.USER.role === UserRoles.isc);
      }
  }
}
