
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class EquipmentInformationSectionService {

    constructor(
        private http: HttpClient
    ) { }

    getObjects(configurationId: string, serviceDepartment: string) {
        return this.http.get(`${BASE_URL}EquipmentObject/GetObjectsForDDL?configuration=${configurationId}&serviceDepartment=${serviceDepartment}`);
    }

    getSerialNumbers(configurationId: string, objectId: string) {
        let payload = '';
        if (objectId) {
            payload = `eObject=${objectId}&configuration=${configurationId}`
        } else {
            payload = `configuration=${configurationId}`
        }
        return this.http.get(`${BASE_URL}EquipmentObject/GetSerialNumbersForDDL?${payload}`);
    }

    getSerialNumbersForSearch(serviceDepartment: string, company, configurationId: string, objectId: string, serialNumberSearchString: string = null, offlineOrder: boolean) {
        let predicatesList = [];
        if (configurationId) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'configuration',
                operator: "contains",
                value: configurationId,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        if (objectId) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'model',
                operator: "contains",
                value: objectId,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        if (serialNumberSearchString) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'serialNumber',
                operator: "contains",
                value: serialNumberSearchString,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        const filterData = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize: 10
            },
            parameters: [{
                key: "company",
                value: company
            }, {
                key: "serviceDepartment",
                value: serviceDepartment
            }, {
                key: 'offlineOrder',
                value: offlineOrder+''
            }
        ],
            skip: 0,
            take: 10
        };
        // if (serialNumberSearchString) {
        filterData['onWhere'] = {
            ignoreAccent: false,
            isComplex: true,
            condition: "and",
            predicates: predicatesList
        };
        // }
        return this.http.post(`${BASE_URL}EquipmentObject/GetSerialNumberSearchView`, filterData);
    }

    getContractAndWarranty(objectId: string, serialNumber: string, serviceContractCode: string, warrantyCode: string, company: string, serviceTypeCode: string = '', soCreatedDate: string) {
        const coverageTypeCode = serviceTypeCode ? `&coverageTypeCode=${serviceTypeCode}` : '';
        let payload = `item=${objectId}&serie=${serialNumber}&serviceContractCode=${serviceContractCode}&company=${company}&warrantyCode=${warrantyCode}${coverageTypeCode}&soCreatedDate=${soCreatedDate}`;
        return this.http.get(`${BASE_URL}EquipmentObject/GetContractAndWarranty?${payload}`);
    }
}
