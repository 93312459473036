import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { inventoryRequestStatuses } from 'src/app/modules/home/models/orders.model';
import { inventoryResponseStatuses } from 'src/app/modules/home/models/orders.model';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { ApiResponse, UserRoles } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inventory-transfer-request-popup',
  templateUrl: './inventory-transfer-request-popup.component.html',
  styleUrls: [
    './inventory-transfer-request-popup.component.scss',
    '../../../../assets/scss/popup.scss',
    '../../../../assets/scss/service-order.scss'
  ]
})
export class InventoryTransferRequestPopupComponent implements OnInit {

  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = 'details';
  // @Input() transferOrderId: string = null;
  @Input() showComments: boolean = false;
//   @Input() itemsData = [];
  @Input() popupSource;
  @Input() rowData = null;
  @Input() transferOrderType;
  data;

  USER;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  technicianList;
  technician: any;
  techAddresses;
  statusList: any;
  transferOrderId;
  onHandCount;

  disablePlaceOrder: boolean = true;
  validateDetails: boolean = false;
  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  showResourceSelectionPopup: boolean = false;
  showServiceOrderSelectionPopup: boolean = false;
  isAdmin: boolean = false;
  isCsa: boolean = false;
  isManager: boolean = false;
  skipQuantityValidation: boolean = false;
  status = inventoryRequestStatuses.open;
  statusesDDL;
  itemDescription: string;
  statusDropdownReadOnly: boolean = false;

  commentCount = 0;
  attachmentCount = 0;
  links = [];
  attachmentsData = [];

  @ViewChild('requesterddl') technicianDDL;
  @ViewChild('statusddl') statusDDL;
  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('attachmentsTab') attachments;
  @ViewChild('addressDDL') addressDDL;

  constructor(
      private commonService: CommonService,
      private serviceOrderService: ServiceOrderService,
      private inventoryService: InventoryService,
      private route: ActivatedRoute,
  ) {
      this.USER = this.commonService.USER;
      this.statusDropdownReadOnly = this.commonService.roleClaims['Inv_Transfer_Request_Popup_Status_DropDown']?.readOnly
  }

  ngOnInit(): void {
    this.transferOrderId = this.rowData?.transferOrderId;
    this.isAdmin = this.USER.role === UserRoles.administratorRole; // if logged in user is Administrator than he will have same functionality as CSA and Manager
    this.isCsa = this.USER.role === UserRoles.csa;
    this.isManager = [UserRoles.conwayServiceManager, UserRoles.klNationalMGR, UserRoles.klServiceManager].includes(this.USER.role);
    
    this.route.paramMap.pipe(
      takeUntil(this.destroy$)).subscribe(params => {
      if (params && params.get('id') && params.get('showComments')) {
        this.transferOrderId = params.get('id')
        this.showComments = params.get('showComments') == 'showComments' ? true : false
      }
    });
    console.log(this.transferOrderId)
    this.inventoryService.popupLoader.pipe(
        takeUntil(this.destroy$),
    ).subscribe((showLoader: any) => {
        this.showLoader = showLoader;
    });
    this.loadPopup();
  }

  loadPopup() {
    this.loadAPIs();
  }

  setAttachmentsTabData() {
      this.attachmentsData = this.data?.attachments?.length && this.data?.attachments || [];
  }

  loadAPIs() {
      this.showLoader = true;
      const apiList = [
        this.commonService.getStatusesForDDL('TransferOrderRequester')
      ];

      if ( this.transferOrderId ) {
        apiList.push(this.inventoryService.getTransferOrderById(this.transferOrderId));
      }

      forkJoin(apiList).subscribe((res: any) => {
        if ( res[0].isSuccessful || res[1]?.isSuccessful) {
          this.statusList = res[0].result;
          if ( this.transferOrderId ) {
            this.setPopupData(res[1].result);
            this.skipQuantityValidation = [inventoryRequestStatuses.open, inventoryRequestStatuses.submitted].includes(this.data?.requester?.status);
            this.statusesDDL = this.getStatuses(this.data?.requester?.status);
            this.setTechnicianDDL();
            this.techAddresses = [this.data?.requester];
            this.attachmentsData = this.data.attachments;
          } else {
            this.setPopupData(this.rowData);
            this.skipQuantityValidation = [inventoryRequestStatuses.open, inventoryRequestStatuses.submitted].includes(this.data?.requester?.status);
            this.statusesDDL = this.getStatuses(this.data?.requester?.status);
            if ( !this.isCsa && !this.isManager && !this.isAdmin) {
              this.technicianList = [{
                technicianId: this.USER.employeeId,
                technicianName: this.USER.employeeName,
                warehouse: this.USER.warehouse
              }];
              this.data.requester.technicianId = this.USER.employeeId+'';
            }
          }
          this.inventoryService.getTechnicianOnHandCount(this.data.itemCode, this.data.responder.warehouse).subscribe((res: ApiResponse) => this.onHandCount = res.result.onHandCount);
          this.itemDescription = this.data.itemCode + ' - ' + this.data.itemDescription;
          this.loadedData = JSON.stringify(this.data);
        } else {
          this.commonService.showNotification('error', res.message);
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.commonService.showNotification('error', error.message)
        throw error;
      });
  }

  setPopupData(data) {
    this.data = {
      transferOrderId: data?.transferOrderId || '',
      company: data?.company || this.USER.company,
      itemCode: data?.itemCode || '',
      itemDescription: data?.itemDescription || '', // "DRIVER TIP NO6 SPANNER",
      quantity: data?.quantity ?? 1,
      orderSubmissionDate: data?.orderSubmissionDate || null,
      notes: data?.notes || null,
      serviceOrderList: data?.serviceOrderList || [],
      requester: {
        warehouse: data?.requester?.warehouse || '',
        status: data?.requester?.status || inventoryRequestStatuses.open,
        technicianId: data?.requester?.technicianId || '',
        technicianName: data?.requester?.technicianName || '',
        addressCode: data?.requester?.addressCode || '',
        addressName: data?.requester?.addressName || null,
        address: data?.requester?.address || '',
        city: data?.requester?.city || '',
        state: data?.requester?.state || null,
        zipCode: data?.requester?.zipCode || '',
        country: data?.requester?.country || '',
        phoneNumber: data?.requester?.phoneNumber || null
      },
      responder: {
        warehouse: data?.responder?.warehouse || data?.warehouse || '',
        status: data?.responder?.status || '',
        technicianId: data?.responder?.technicianId || data.technicianId || '',
        technicianName: data?.responder?.technicianName || data.technicianName || '',
        carrier: data?.responder?.carrier || '',
        trackingNumber: data?.responder?.trackingNumber || '',
        dateShipped: data?.responder?.dateShipped || null,
      },
      comments: data?.comments || [],
      attachments: data?.attachments || [],
      history: data?.history || []
    };
    this.commentCount = this.data.comments.length;
    this.attachmentCount = this.data.attachments.length;
  }

  setTechnicianDDL() {
    this.technicianList = [{technicianId: this.data.requester.technicianId, technicianName: this.data.requester.technicianName, warehouse: this.data.requester.warehouse}];
  }

  onResourceChange(args) {
    this.data.requester.technicianId = args.itemData.technicianId;
    this.data.requester.technicianName = args.itemData.technicianName;
    this.data.requester.warehouse = args.itemData.warehouse;//args.itemData.warehouse;

    this.addressDDL.value = '';
    this.commonService.getTechnicianAddresses(this.data?.requester?.warehouse).subscribe((res: ApiResponse) => {
      if ( res.isSuccessful ) {
        this.techAddresses = res.result;
        if ( this.transferOrderId ) {
          this.addressDDL.value = this.data.requester.address;
        } else {
          this.data.requester.address = this.techAddresses[0]?.address || '';
          !this.techAddresses.length && this.commonService.showNotification('warning', 'Address Not Found!');
        }
      } else {
        this.commonService.showNotification('error', res.message);
      }
      this.validateDetails = this.validateDetailsTab();

    }, error => {
      this.commonService.showNotification('error', error.message);
      throw error;
    });
  }

  statusChanged(eventArgs: any){
    if(this.data?.quantity){
      this.data.requester.status = eventArgs.itemData.value;
    } else {
      this.commonService.showNotification('warning', 'Please add quantity first!');
      eventArgs.cancel = true;
    }
  }

  onAddressChange(args) {
    if ( args.value ) {
      this.data.requester.address = args.itemData.address;
      this.data.requester.addressCode = args.itemData.addressCode;
      this.data.requester.addressName = args.itemData.addressName || null;
      this.data.requester.city = args.itemData.city;
      this.data.requester.state = args.itemData.stateProvince || args.itemData.state;
      this.data.requester.country = args.itemData.country;
      this.data.requester.zipCode = args.itemData.zipCode;
      this.data.requester.phoneNumber = args.itemData.phoneNumber || null;
    } else {
      this.data.requester.address = '';
    }
    if ( !this.transferOrderId ) {
      this.loadedData = JSON.stringify(this.data); //for the very first time when address gets changed on technician change on transfer order creation
    }
  }

  openServiceOrderSelectionPopup() {
    if ( this.data.requester.technicianId ) {
      this.showServiceOrderSelectionPopup = true;
    } else {
      this.commonService.showNotification('warning', 'Technician should be selected before this!');
    }
  }

  receiveServiceOrders(soList) {
    this.data.serviceOrderList = JSON.parse(JSON.stringify(soList));
  }

  expandCollapse(value: boolean) {
      this.isExpanded = value;
  }

  onCommentUpdate(data: any) {
      this.commentCount = data.filter(comment => !comment.isDeleted).length;
      this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
      this.data.attachments = data;
      this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  isChanged() {
      return this.loadedData !== JSON.stringify(this.data);
  }

  validateDetailsTab() {
    const validOnHand = [inventoryRequestStatuses.open, inventoryRequestStatuses.submitted].includes(this.data.requester.status) ? this.data.quantity <= this.onHandCount : true;
    return this.data.requester.technicianId && this.data.requester.technicianName && this.data.requester.status && this.data.quantity && this.data.requester.address && validOnHand;
  }

  validate() {
    this.validateDetails = this.validateDetailsTab();
      return this.validateDetails;
  }

  onKeyup(args) {
    this.data.quantity = parseInt(args.target.value);
    this.validateDetails = this.validateDetailsTab();
  }

  onResourcePopupClose(technician) {
    this.showResourceSelectionPopup = false;
    if ( technician ) {
      this.technicianList = [technician];
      this.data.requester.technicianId = technician.employeeId;
      // this.data.requester.technicianName = technician.employeeName;
    }
  }

  save(close?: boolean, placeOrder?: boolean) {
      this.validationApplied = true;
      if (this.validate()) {
        this.inventoryService.popupLoader.next(true);
        this.inventoryService.saveTransferOrder(this.data)
            .subscribe((res: ApiResponse) => {
              if (res.isSuccessful) {
                const message = this.transferOrderId ? `The Transfer Order ${this.transferOrderId} saved successfully.` : `The Transfer Order ${res.result.transferOrderId} created successfully.`;
                this.transferOrderId = res.result.transferOrderId;
                this.commonService.showNotification('success', message, 'center', 10000);
                !close && this.loadAPIs();
                // this.loadedData = JSON.stringify(this.data);
                this.onSave.emit(this.data);
              } else {
                this.commonService.showNotification('error',res.message);
              }
              this.inventoryService.popupLoader.next(false);
              close && this.onClose.emit();
            }, (error: HttpErrorResponse) => {
              this.inventoryService.popupLoader.next(false);
              this.commonService.showNotification('error',error.message);
              throw error;
            });
    } else {
        this.commonService.showNotification('error', 'Please provide all the required information!');
        this.inventoryService.popupLoader.next(false);
    }
  }

  close(forceClose?) {
      if (!this.isChanged() || forceClose) {
          this.onClose.emit();
      } else {
        this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.onClose.emit();
              }
          })
      }
  }

  reset() {
    if (this.isChanged()) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.data = JSON.parse(this.loadedData);
                  this.loadPopup();
              }
          })
    } else {
      this.commonService.showNotification('info', 'Nothing to reset!');
    }
  }

  getStatuses(status) {
    switch (status) {
      case inventoryRequestStatuses.open:
        return this.statusList.filter(s => [inventoryRequestStatuses.open, inventoryRequestStatuses.submitted].includes(s.value));
      case inventoryRequestStatuses.submitted:
        return this.statusList.filter(s => [inventoryRequestStatuses.submitted, inventoryRequestStatuses.cancelled].includes(s.value));
      case inventoryRequestStatuses.cancelled:
        return this.statusList.filter(s => [inventoryRequestStatuses.cancelled].includes(s.value));
      case inventoryRequestStatuses.accepted:
        return this.statusList.filter(s => [inventoryRequestStatuses.accepted].includes(s.value));
      case inventoryRequestStatuses.rejected:
        return this.statusList.filter(s => [inventoryRequestStatuses.rejected].includes(s.value));
      case inventoryRequestStatuses.inTransit:
        return this.statusList.filter(s => [inventoryRequestStatuses.inTransit, inventoryRequestStatuses.received].includes(s.value));
      case inventoryRequestStatuses.received:
        return this.statusList.filter(s => [inventoryRequestStatuses.received].includes(s.value));
      default: break;
    }
  }

  ngOnDestroy() {
      this.data = null;
      this.destroy$.next();
      this.destroy$.unsubscribe();
  }
}
