import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StatusDropdown } from '../../../../../modules/service-order/structure/structure';
import { PageElement } from '../../../../../modules/service-order/models/popup.model';
import { InventoryService } from '../../../services/inventory.service';
import { ItemsTabComponent } from '../../../modules/orders/components/parts-orders/parts-orders-popup/items-tab/items-tab.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { returnOrderStatuses } from '../../../models/orders.model';
import { ApiResponse, UserRoles } from 'src/app/shared/models/app.model';

@Component({
  selector: 'app-core-return-order-popup-details-tab',
  templateUrl: './core-return-order-popup-details-tab.component.html',
  styleUrls: ['./core-return-order-popup-details-tab.component.scss']
})
export class CoreReturnOrderPopupDetailsTabComponent implements OnInit {
  @Input() tab: string = 'details';
  @Input() id: string = null;
  @Input() showComments: boolean = false;
  @Input() details: any;
  @Input() shipmentMethods;
  @Input() categories;
  @Input() status;

  @ViewChild('category') category;
  @ViewChild('shipmentMethod') shipmentMethod;
  @ViewChild('trackingId') trackingId;

  loadedData = null;
  statuses = returnOrderStatuses;
  statusDropdown: PageElement = StatusDropdown;

  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  createdDate: Date = new Date();
  minEstimatedDate: Date = new Date();

  inventory = [];
  commentCount = 0;
  attachmentCount = 0;

  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;

  @Output() categoryChanged = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() tabUpdated = new EventEmitter();

  USER;
  isManager: boolean = false;
  isCSA: boolean = false;
  @Input() itemsTab: ItemsTabComponent;
  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService
  ) {
    this.USER = this.commonService.USER;
    this.isManager = this.USER.role === UserRoles.manager;
    this.isCSA = this.USER.role === UserRoles.csa;
}

  ngOnInit(): void { 
      // console.log(this.details);
  }

  getData() {
    return this.details;
  }

  onCategoryChange(args: any) {
    const itemsGrid = (document.getElementById('itemsGrid') as any)?.ej2_instances[0];
    if ( args.value && args.isInteracted ) {
      if ( !itemsGrid.dataSource.length ) {
        this.showLoader = true;
        this.getInventory(args);
        this.tabUpdated.emit(this.details);
      } else {
        this.commonService.showConfirmation('Items data will be lost! Do you want to proceed?').then(res => {
          if ( res.isConfirmed ) {
            this.showLoader = true;
            this.getInventory(args);
            this.tabUpdated.emit(this.details);
          } else {
            this.category.value = args.previousItemData.text;
          }
        });
      }
    }
  }

  getInventory(args) {
    this.inventoryService.getInventory(args.value.toLowerCase(), this.USER?.warehouse)
    .subscribe((res: ApiResponse) => {
      if ( res.isSuccessful ) {
        this.inventory = res.result.map(item => {
          item.itemCode = item.item;
          item.itemDescription = item.description;
          return item;
        });
        this.details.category = args.value;
        this.categoryChanged.emit({ inventory:this.inventory, value: args.value, isInteracted: args.isInteracted });
      }
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      throw error;
    });
  }

  validate(checkGenerateStatementValidation) {
    this.validationApplied = true;
    if (!this.shipmentMethod.value) {
        this.commonService.showNotification('error', 'Please select a shipment method.');
        return false;
    } else if(checkGenerateStatementValidation){
      if(!this.details.trackingId){
        this.commonService.showNotification('error', 'Tracking Id is required for generating statement.');
        return false;  
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.details = {};
  }
}
