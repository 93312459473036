import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ClaimsService } from '../../services/claims.service';
import { CommonService } from '../../services/common.service';
import { UserRoles } from '../../models/app.model';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, updateFilterIcon } from '../../utils/grid-functions';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from '../../utils/date-functions';

@Component({
  selector: 'app-service-order-list-for-claim-popup',
  templateUrl: './service-order-list-for-claim-popup.component.html',
  styleUrls: ['./service-order-list-for-claim-popup.component.scss']
})
export class ServiceOrderListForClaimPopupComponent implements OnInit {
  showLoader: boolean = false;
  gridColumns: any;
  grid: GridComponent;

  public pageSettings: Object
  gridHeight: number = window.innerHeight - 420;

  data : any = [];
  USER: any;

  @Output() closeSOlistPopup = new EventEmitter();
  @Output() serviceOrderSelectedForClaim = new EventEmitter();
  serviceOrderId: any;
  serviceOrderData: any;
  showServiceOrderPopup: boolean;

  @ViewChild('claimsGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  constructor(
    private claimsService: ClaimsService,
    private commonService: CommonService,
    private serviceOrderService: ServiceOrderService
  ) { 
    this.USER = commonService.USER;
  }

  ngOnInit(): void {
    this.showLoader = true;
    this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
    // setTimeout(() => {
    //   this.showLoader = false;
    // }, 1000);
    // // this.getData();
    this.loadApis();
    this.gridColumns = this.getColumns();
  }

  setGridToolbar() {
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        // { id: 'reset-persistence',align: 'Right', prefixIcon: 'fas fa-sync', cssClass: '', tooltipText: 'Reset Persistence' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];

    if (this.commonService.roleClaims['CycleCountGrid_Toolbar_AddCycleCount']?.visible) {
      const index = this.grid.toolbar.findIndex(tool => tool['id'] === 'refresh');
      this.grid.toolbar.splice(index, 0, { text: '', id: 'add-new-cycle-count', prefixIcon: 'e-add', cssClass: '', tooltipText: 'Add Cycle Count' });
    }
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  serviceOrderIdClicked(serviceOrderId) {
    this.serviceOrderId = serviceOrderId;
    this.showLoader = true;
    this.serviceOrderService.getServiceOrderById(serviceOrderId, true)
      .subscribe((res: any) => {
        if(res.isSuccessful ) {
          this.serviceOrderData = res.result;
          this.showLoader = false;
          this.showServiceOrderPopup = true;
        } else {
          this.commonService.showNotification('error',res.message)
        }
      }, (error: HttpErrorResponse) => {
        this.commonService.showNotification('error', error.message);
        this.showLoader = false;
        throw error;
      });

  }
  
  loadApis(){
    const getServiceOrdersForClaims = this.USER.role === UserRoles.isc || this.USER.role === UserRoles.iscManager || this.USER.role === UserRoles.IscSupervisor ? this.claimsService.getIscServiceOrdersForClaims.bind(this.claimsService) : this.claimsService.getServiceOrdersForClaims.bind(this.claimsService); 
    getServiceOrdersForClaims().subscribe((res: any) => {
      if(res.isSuccessful){
        this.data = res.result;
        this.showLoader = false;
      } else {
        this.commonService.showNotification('error', res.message)
        this.showLoader = false;
      }
    }, (error: HttpErrorResponse) => {
        this.commonService.showNotification('error', error.message)
        this.showLoader = false;
    });
  }

  getColumns() {
    return [
      { field: 'serviceOrderId', headerText: 'Service Order', type: 'string', textAlign: 'Left', width: 200, isPrimaryKey:true},
      // { field: 'technicianName', headerText: 'Technician', type: 'string', textAlign: 'Left', width: 150 },
      { field: 'completedDate', headerText: 'Completion Date', type: 'date', allowEditing: true, textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 140, visible: true },
      // { field: 'nteAmount', headerText: 'NTE', type: 'number', textAlign: 'Left', width: 100 },
      { field: 'totalAmount', headerText: 'Total Amount', type: 'number', format: 'n2', textAlign: 'Left', width: 200 },
      {
        field: 'Actions', headerText: 'Actions', textAlign: 'Center', allowFiltering: false, width: 100,  allowSorting: false,
        commands: [
            {
                title: 'Create a claim',
                buttonOption: {
                    iconCss: `fas fa-arrow-right`,
                    cssClass: `e-flat action-button`
                }
            }
        ]
      }
    ];
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'excel-export') {
        const dataSource = getExcelDataSource(this.grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `SO List (${getCurrentDate()}).xlsx`
        };
        this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    } else if (args.item.id === 'clear-filter') {
        this.grid.clearFiltering();
        this.grid.search('');
    }  else if (args.item.id === 'refresh') {
      this.loadApis();
    }
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Create a claim') {
        this.serviceOrderSelectedForClaim.emit(args.rowData.serviceOrderId);
    }
}

  close(){
    this.closeSOlistPopup.emit();
  }

}
