export const environment = {
  production: true,
  // redirectUrl: 'https://sso1beta.snapon.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DMobileApp.js%26redirect_uri%3Dhttps%253A%252F%252Fmobileappdevtest-designer.azurewebsites.net%252Fauth%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520Roles%26state%3D16414008400430.ruvtjl5vfu%26nonce%3D16414008400430.usnkl95geh',
  // redirectUrl: 'https://sso1beta.snapon.com/connect/authorize?client_id=MobileAppStaging.js&redirect_uri=https://stage-rims.inavista.com/auth&response_type=id_token%20token&scope=openid%20profile%20Roles&state=16742019005960.zhfk0ghp5ae&nonce=16742019005960.ropusfjkqn',
  redirectUrl: 'https://sso1beta.snapon.com/connect/authorize?client_id=MobileAppStaging2.js&redirect_uri=https://stage2-rims.inavista.com/auth&response_type=id_token%20token&scope=openid%20profile%20Roles&state=16814521988200.r1zay15nyk8&nonce=16814521988200.v9t82wc9jjh',
  loginUrl: 'https://app-idp-inv-stg-eastus-002.azurewebsites.net/api/',
  apiUrl: 'https://app-api-inv-stg-eastus-003.azurewebsites.net/api/',
  loginAppUrl: 'https://stage.accounts.inavista.com/',
  webAuthUrl: 'https://stg-webauth.inavista.com/api/',
  generateApplicationTokenUrl: "https://app-api-inv-stg-eastus-003.azurewebsites.net/api/Account/GenerateApplicationToken",
  azureMapKey: '6WmuScYxyZ76xJYXeExDrVMpIFtZSz4LGDMZb6mP1hQ',
  imageUrl: 'https://stinvdata001.blob.core.windows.net/stage/snapon/mobileservice/images/',
  // imageUrl: 'https://mobileappstorageacc.blob.core.windows.net/dev/snapon/mobileservice/images/',
  serveKey: 'AAAAtIMs0KQ:APA91bHxZZDvfjqmhJjCKCRrhbo7QHPC_pl7sEMSO9Xcaa9gtD9lxm0KLByEH4x9RsTMSKIbXKtK_y8W3ffaDIHlsbbYJiQXg-fr5eSrEUpe1GrMKSK_gCwlJ1hWrhOJXFiE8RqTixB6',
  googleMapApiKey: 'AIzaSyArXMoiRYVB07rxDepO0TTxEuhLBfHZaUg',
  HPFInterface: 'https://www.chasepaymentechhostedpay-var.com/hpf/1_1',
  barclaysFormURL: 'https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp',
  syncfusionLicenseKey: 'Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQllhT35Wc0FiWn9fdHU=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpRcEVmXHtfc3RTQGE=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdENjXHpacXdVTmFd;MTg0NTEwMkAzMjMxMmUzMTJlMzMzNWpDenNwS285ZXJNc3V2M25ha3RZREQ5eSsyWDh1dnZJTlJJR1c1OHpiM1U9;MTg0NTEwM0AzMjMxMmUzMTJlMzMzNWhiSk42QXhKTnBSSU5IdnRzUlQxWEk3eS94RWxaeloxZTBHWWRZdXdpZGs9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckdlWXpbdHVWR2dbUA==;MTg0NTEwNUAzMjMxMmUzMTJlMzMzNWNZZG9tUXAwUUNjNWZacVpLTGZPekl0QjN0RFVuMXN1MUg1M3llS0E4Tmc9;MTg0NTEwNkAzMjMxMmUzMTJlMzMzNU15V2VpU0xBeWt1d2o3cWxxZllnblZqZWVpWGtCcy9KWjdiMStIVzJWbU09;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdENjXHpacXdXQGZd;MTg0NTEwOEAzMjMxMmUzMTJlMzMzNUJUUExoOHE0RmhPaDNpOUdQVWloRWc0MTdpTk9OSWlRS256UmxmYVpMQnc9;MTg0NTEwOUAzMjMxMmUzMTJlMzMzNWdhM0N2T1RlVG1Ub3M3VnV1MVEzeXpNNFBWc3NNUDRnVEZHSG1wOE11aFk9;MTg0NTExMEAzMjMxMmUzMTJlMzMzNWNZZG9tUXAwUUNjNWZacVpLTGZPekl0QjN0RFVuMXN1MUg1M3llS0E4Tmc9',
  firebase: {
    apiKey: "AIzaSyArXMoiRYVB07rxDepO0TTxEuhLBfHZaUg",
    authDomain: "mobile-service-1.firebaseapp.com",
    projectId: "mobile-service-1",
    storageBucket: "mobile-service-1.appspot.com",
    messagingSenderId: "775294865572",
    appId: "1:775294865572:web:51f59a37b4500a4a685631",
    measurementId: "G-XQN6KKQHT6"
  },
  paymentLoginId: '5D8AvZW8cG',
  paymentTransactionKey: '652b53GVYeW4qZ3G',
  paymentEndpoint: 'https://apitest.authorize.net/xml/v1/request.api'
};
