import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('access_token');
        const webauth_token: string = localStorage.getItem('webauth_token');
        if(webauth_token && request.url === environment.generateApplicationTokenUrl){
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + webauth_token) });
        } else if (token && request.url !== 'https://apitest.authorize.net/xml/v1/request.api') {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        //Temporary solution, need to be fixed later
        if(!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        } else if(request?.headers.get('Content-Type') == 'FileUpload') {
            request = request.clone({ headers: request.headers.delete('Content-Type') });
        }

        request = request.clone({setHeaders: {
            'Accept': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Content-Security-Policy': "script-src 'self';",
            // 'Referrer Policy': 'no-referrer',
            'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
            'X-Frame-Options': 'DENY',
            'X-XSS-Protection': '1; mode=block'
        }})
        return next.handle(request)
        .pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // ('event--->>>', event);
                }
                return event;
            })
        );
    }
}