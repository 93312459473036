
<div class="row">
  <div class="col mt-4">
      <ejs-grid #exceptionsGrid
          id="exceptionsGrid" 
          [dataSource]="exceptions"
          [allowPaging]="false"
          [allowFiltering]="true"
          [allowSorting]="true"
          [allowResizing]="true"
          [allowReordering]="true"
          [allowExcelExport]="true"
          [editSettings]="{ allowEditing: false, allowAdding: false, allowDeleting: false  }"
          [filterSettings]="{ type: 'Menu' }">
          <e-columns>
              <e-column 
                  *ngFor="let column of columns;" 
                  [field]="column.field" 
                  [headerText]="column.headerText"
                  [isPrimaryKey]="column.isPrimaryKey || false"
                  [clipMode]="'EllipsisWithTooltip'"
                  [type]="column.type"
                  [width]="column.width"
                  [foreignKeyField]="column.foreignKeyField"
                  [foreignKeyValue]="column.foreignKeyValue"
                  [validationRules]="column.validationRules"
                  [dataSource]="column.dataSource"
                  [edit]="column.edit"
                  [filter]="column.filter ? column.filter : { type: 'Menu' }"
                  [format]="column.format ? column.format : null"
                  [textAlign]="column.textAlign" 
                  [visible]="column.visible"
                  [commands]="column?.commands ? column.commands : null"
                  [allowEditing]="column.allowEditing" 
                  [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
              </e-column>
          </e-columns>
      </ejs-grid>
  </div>
</div>

<ng-template #exceptionsGridColumnChooser>
  <app-column-chooser
      [grid]="exceptionsGrid"
      [columns]="columns"
      [showColumnChooser]="showColumnChooser"
      (onClose)="showColumnChooser = false"
  ></app-column-chooser>
</ng-template>