<div class="settings-button toolbar-button details top-settings" *ngIf="grid">
    <button type="button" class="btn btn-default btn-sm settings-icon">
        <i class="fas fa-cogs"></i>
    </button>
    <div class="dropdown-menu settings-menu"
        [ngClass]="{open: showColumnChooser}">
        <ng-container *ngFor="let column of columns">
            {{column.customAttributes}}
            <div class="item" *ngIf="column.field !== 'Actions' && (column.showInColumnChooser === undefined || column.showInColumnChooser)">
                <div class="e-checkbox-wrapper e-wrapper" role="checkbox" aria-checked="true">
                    <label for="checkbox-{{grid?.element.id}}-{{column.field}}">
                        <input
                            name="checkbox-{{grid?.element.id}}-{{column.field}}" 
                            id="checkbox-{{grid?.element.id}}-{{column.field}}" 
                            class="e-input e-field e-control e-checkbox e-lib" 
                            type="checkbox"
                            [checked]="column.visible === undefined || column.visible"
                            (click)="column.visible = !column.visible; toggleShowHide(column.field, column.visible, grid)"
                        >
                        <span class="e-icons e-frame e-btn-icon"
                            [ngClass]="{'e-check': column.visible === undefined || column.visible, 'e-uncheck': !column.visible}">
                        </span> 
                        <span class="column-name">{{ column.headerText || column.field}}</span>
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
</div>