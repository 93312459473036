import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { NotificationTypes } from "../models/app.model";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private commonService: CommonService,
    private router: Router
  ) { }

  handleHttpError(error: HttpErrorResponse) {
    let errorMessage = 'An error occurred';
    
    // Check if the error is an instance of HttpErrorResponse
    if (error instanceof HttpErrorResponse) {
      // Check if the error has a client-side error
      if (error.error instanceof ErrorEvent) {
        // Client-side error
        errorMessage = `Client-side error: ${error.error.message}`;
      } else {
        // Server-side error
        errorMessage = `Server-side error: ${error.status} - ${error.error.message}`;
      }
    } else {
      // Non-HTTP error
      errorMessage = `Unexpected error: ${error}`;
    }
    
    // Log the error to the console
    this.commonService.showNotification(NotificationTypes.error, errorMessage);


    setTimeout(() => {
      this.router.navigate(['/auth'])
    }, 5000);
  }
}
