import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth',
  template: '',
})
export class AuthComponent implements OnInit {

  constructor(
    private Auth: AuthService
  ) { }

  ngOnInit(): void {
    localStorage.clear();
    window.location.href = `${environment.loginAppUrl}`;
  }

}
