import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Invoice } from '../models/payment-invoicing.model';

const BASE_URL = environment.apiUrl;

@Injectable()
export class ServiceOrderService {

    configurationChange: Subject<any> = new Subject();
    nteChange: Subject<any> = new Subject();
    popupLoader: Subject<any> = new Subject();
    modelDescriptionChange: Subject<any> = new Subject();
    serialNumberChange: Subject<any> = new Subject();
    callgroupChange: Subject<any> = new Subject();
    equipmentInfoSubject: Subject<any> = new Subject();
    hasTransaction: Subject<any> = new Subject();
    searchFilter;
    estimatedMiles;

    private _customerFiltering = new Subject<string>();
    public _customerFiltering$ = this._customerFiltering.asObservable();

    private _srNumber = new Subject<string>();
    public _srNumber$ = this._srNumber.asObservable();

    constructor(
        private http: HttpClient
    ) { }

    setSearchCustomerFiltering(value: string): void {
        this._customerFiltering.next(value)
    }

    getSearchCustomerFiltering(): Observable<string> {
        return this._customerFiltering$
    }

    /**
     * This method is being used to fetch latest records for DDL while filtering
     * @param value | value is the text we are getting from the dropdown filtering
     */
    setSrNumberFiltering(value: string): void {
        this._srNumber.next(value)
    }

    /**
     * This function will be used to get the searched text in DDL of serial number
     */
    getSrNumberFiltering(): Observable<string> {
        return this._srNumber$
    }

    getIscConfigurations(serviceDepartment: string, company: string, searchString: string = null) {
        const filterData = this.setPayloadForCustomerSearch(serviceDepartment, company, searchString);
        return this.http.post(`${BASE_URL}SoldToBP/GetISCCustomerSearchView`, filterData);
    }

    getConfigurations(serviceDepartment: string, company: string, searchString: string = null) {
        const filterData = this.setPayloadForCustomerSearch(serviceDepartment, company, searchString);
        return this.http.post(`${BASE_URL}SoldToBP/GetCustomerSearchView`, filterData);
    }

    setPayloadForCustomerSearch(serviceDepartment: string, company: string, searchString: string = null) {
        const columnName = isNaN(+searchString) ? 'bpDescription' : 'soldToBP';
        const filterData = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize: 10
            },
            parameters: [{
                key: "company",
                value: company
            }, /*{
                key: "serviceDepartment",
                value: serviceDepartment
            }*/],
            skip: 0,
            take: 10
        };
        if (searchString) {
            filterData['onWhere'] = {
                ignoreAccent: false,
                isComplex: true,
                condition: "and",
                predicates: [{
                    ignoreAccent: false,
                    isComplex: false,
                    field: columnName,
                    operator: "contains",
                    value: searchString,
                    ignoreCase: true,
                    oprtr: "contains"
                }]
            };
        }
        return filterData;
    }

    getTaxRateByConfiguration(configurationId: string) {
        return this.http.get(`${BASE_URL}TaxRate/GetTaxRateByConfiguration?configuration=${configurationId}`);
    }

    getServiceOrders(employeeId: string, isManager?: boolean) {
        return this.http.get(`${BASE_URL}ServiceOrder/GetServiceOrders?technicianId=${employeeId}`);
        // if ( isManager ) {
        //     return this.http.get(`${BASE_URL}ServiceOrder/GetManagerServiceOrders?employeeId=${employeeId}`);
        // } else {
        //     return this.http.get(`${BASE_URL}ServiceOrder/GetServiceOrders?employeeId=${employeeId}`);
        // }
    }

    generateEstimateInvoice(serviceOrderId) {
        return this.http.post(`${BASE_URL}ServiceOrder/GenerateEstimateInvoice?serviceOrderId=${serviceOrderId}`,null)
    }

    generateIscEstimateInvoice(serviceOrderId) {
        return this.http.post(`${BASE_URL}ISCServiceOrder/GenerateISCEstimateInvoice?serviceOrderId=${serviceOrderId}`,null)
    }

    generateChecklistInvoice(invoiceChecklist: any){
        return this.http.post(`${BASE_URL}Checklist/GenerateChecklist`, invoiceChecklist);
    }

    getServiceOrderById(id: string, isIsc: boolean = false) {
        const apiUrl = isIsc ? 'ISCServiceOrder/GetISCServiceOrderById' : 'ServiceOrder/GetServiceOrderById';
        return this.http.get(`${BASE_URL}${apiUrl}?serviceOrderId=${id}`);
    }

    saveServiceOrder(data: any, isIsc: boolean = false) {
        if (data.serviceOrderId) {
            return this.http.post(`${BASE_URL}ServiceOrder/UpdateServiceOrder`, data);
        } else {
            return this.http.post(`${BASE_URL}ServiceOrder/CreateServiceOrder`, data);
        }
    }

    updateServiceOrderContractAndWarranty(data: any){
        return this.http.post(`${BASE_URL}ServiceOrder/UpdateServiceOrderContractAndWarranty`, data);
    }

    saveIscServiceOrder(data: any) {
        if (data.serviceOrderId) {
            return this.http.post(`${BASE_URL}ISCServiceOrder/UpdateISCServiceOrder`, data);
        } else {
            return this.http.post(`${BASE_URL}ISCServiceOrder/CreateISCServiceOrder`, data)
        }
    }

    updateISCServiceOrderContractAndWarranty(data: any){
        return this.http.post(`${BASE_URL}ISCServiceOrder/UpdateISCServiceOrderContractAndWarranty`, data);
    }

    deleteServiceOrderComment(serviceOrderId: string, commentId: any) {
        return this.http.delete(`${BASE_URL}ServiceOrder/DeleteServiceOrderComment?serviceOrderId=${serviceOrderId}&commentId=${commentId}`);
    }

    deleteServiceOrderAttachment(serviceOrderId: string, attachmentId: any) {
        return this.http.delete(`${BASE_URL}ServiceOrder/DeleteServiceOrderAttachment?serviceOrderId=${serviceOrderId}&attachmentId=${attachmentId}`);
    }

    updateServiceOrderStatus(
        serviceOrderId: string,
        checkIn: Date,
        checkOut: Date,
        checkOutStatus: string,
        reasonCode?: string,
        startDate?: Date | String
    ) {
        const apiUrl:string = serviceOrderId.includes('SRI') ? `${BASE_URL}ISCServiceOrder/UpdateISCServiceOrderStatus` :`${BASE_URL}ServiceOrder/UpdateServiceOrderStatus`;
        return this.http.post(`${apiUrl}?serviceOrderId=${serviceOrderId}${checkIn ? '&checkIn=' + checkIn : ''}${checkOut ? '&checkOut=' + checkOut : ''}${checkOutStatus ? '&checkOutStatus=' + checkOutStatus : ''}&reasonCode=${reasonCode}${startDate ? '&startDate=' + startDate : ''}`, null)
    }

    updateIscServiceOrderStatus(
        serviceOrderId: string,
        checkIn: Date,
        checkOut: Date,
        checkOutStatus: string,
        reasonCode?: string,
        startDate?: Date
    ) {
        return this.http.post(`${BASE_URL}ISCServiceOrder/UpdateISCServiceOrderStatus?serviceOrderId=${serviceOrderId}${checkIn ? '&checkIn=' + checkIn : ''}${checkOut ? '&checkOut=' + checkOut : ''}${checkOutStatus ? '&checkOutStatus=' + checkOutStatus : ''}&reasonCode=${reasonCode}${startDate ? '&startDate=' + startDate : ''}`, null)
    }

    markServiceOrderCompleted(soIds: string[]) {
        return this.http.post(`${BASE_URL}ServiceOrder/MarkServiceOrderCompleted`, soIds);
    }

    // updateServiceOrderStartDate(serviceOrderId: string, startDate: any) {
    //     return this.http.post(`${BASE_URL}?serviceOrderId=${serviceOrderId}&startDate=${startDate}`, null);
    // }

    updateServiceOrderStartDate(serviceOrderId: string, startDate: any, isIsc: boolean = false) {
        const apiUrl = isIsc ? 'ISCServiceOrder/UpdateISCServiceOrderStartDate' : 'ServiceOrder/UpdateServiceOrderStartDate';
        return this.http.post(`${BASE_URL}${apiUrl}?serviceOrderId=${serviceOrderId}&startDate=${startDate}`, null);
    }

    updateServiceOrderNTE(serviceOrderId: string, nteAmount: number | string, isIsc: boolean = false) {
        const url = isIsc ? 'ISCServiceOrder/UpdateISCServiceOrderStatusToEstimate' : 'ServiceOrder/UpdateServiceOrderStatusToEstimate';
        return this.http.post(`${BASE_URL}${url}?serviceOrderId=${serviceOrderId}&nteAmount=${nteAmount}`, null);
    }

    getPriorities(company) {
        return this.http.get(`${BASE_URL}Priority/GetPrioritiesForDDL?company=${company}`);
    }

    getPaymentTypes(company) {
        return this.http.get(`${BASE_URL}PaymentMethod/GetPaymentMethodsForDDL?company=${company}`);
    }

    getTemplateTypes(serviceOrderId, company) {
        return this.http.get(`${BASE_URL}Template/GetTemplatesForDDL?company=${company}&serviceOrderId=${serviceOrderId}`);
    }

    getEquipmentHistory(serviceOrderId: string, item: string, serialNumber: string, isIsc: boolean = false) {
        const apiUrl = isIsc ? 'IScServiceOrder/GetISCServiceOrderEquipmentHistory' : 'ServiceOrder/GetServiceOrderEquipmentHistory';
        return this.http.get(`${BASE_URL}${apiUrl}?serviceOrderId=${serviceOrderId}&item=${item}&serialNumber=${serialNumber}`);
    }

    getServiceOrdersByCustomer(customerId: string, serviceOrderId: string, invoiceId?: string) {
        return this.http.get(`${BASE_URL}ServiceOrder/GetServiceOrdersForInvoicing?customerCode=${customerId}&serviceOrderId=${serviceOrderId}${invoiceId ? '&invoiceId=' + invoiceId : ''}`);
    }

    createInvoice(invoice: Invoice) {
        return this.http.post(`${BASE_URL}ServiceOrder/CreateInvoice`, invoice);
    }

    createIscInvoice(invoice: Invoice) {
        return this.http.post(`${BASE_URL}ISCServiceOrder/CreateISCInvoice`, invoice);
    }

    updateInvoice(invoice: Invoice) {
        return this.http.post(`${BASE_URL}ServiceOrder/UpdateInvoice`, invoice);
    }

    generateInvoicePdf(invoice: Invoice, isDraft: boolean = true) {
        return this.http.post(`${BASE_URL}ServiceOrder/GenerateInvoice?draft=${isDraft}`, invoice);
    }

    generateIscInvoicePdf(invoice: Invoice, isDraft: boolean = true) {
        return this.http.post(`${BASE_URL}ISCServiceOrder/GenerateISCInvoice?draft=${isDraft}`, invoice);
    }

    getInvoice(invoiceId: string) {
        return this.http.get(`${BASE_URL}ServiceOrder/GetInvoiceById?invoiceId=${invoiceId}`);
    }

    getIscInvoice(invoiceId: string) {
        return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCInvoiceById?invoiceId=${invoiceId}`);
    }

    markInvoiceSigned(invoiceId: string) {
        return this.http.post(`${BASE_URL}ServiceOrder/UpdateInvoiceSigned?invoiceId=${invoiceId}`, null);
    }

    markIscInvoiceSigned(invoiceId: string) {
        return this.http.post(`${BASE_URL}ISCServiceOrder/UpdateISCInvoiceSigned?invoiceId=${invoiceId}`, null);
    }

    captureCreditCardPayment(cardData: any) {
        return this.http.post(environment.paymentEndpoint, cardData);
    }

    authorizeCreditCardPayment(cardData: any) {
        return this.http.post(environment.paymentEndpoint, cardData);
    }

    captureAutorizedPayment(transactionInfo: any) {
        return this.http.post(environment.paymentEndpoint, transactionInfo);
    }

    getCountries(company) {
        return this.http.get(`${BASE_URL}Country/GetCountriesForDDL?company=${company}`);
    }

    saveImage(base64Image: any) {
        return this.http.post(`${BASE_URL}PdfViewer/UploadCameraImage`, base64Image);
    }

    getReasons(company) {
        return this.http.get(`${BASE_URL}Reason/GetReasonsForDDL?company=${company}`);
    }
    getReasonsForPausedStatus(company) {
        return this.http.get(`${BASE_URL}Reason/GetReasonsForDDL?company=${company}&reasonType=Interruption`);
    }
    getReasonsForCancelStatus(company) {
        return this.http.get(`${BASE_URL}Reason/GetReasonsForDDL?company=${company}&reasonType=Cancel`);
    }

    getPendingApprovals(module?) {
        return this.http.get(`${BASE_URL}ShipmentOrder/GetPendingApprovalExceptions?status=Pending Approval`);
    }

    getPrice(data) {
        return this.http.get(`${BASE_URL}PriceBook/GetPrice?company=${data.company}&soldTo=${data.configuration}&item=${data.item}&orderQty=${data.quantity}&unit=${data.unit}`)
    }

    GetInProgressAndEnrouteSOCount(employeeId, isIsc: boolean = false){
        const apiUrl = isIsc ? 'ISCServiceOrder/GetISCInProgressAndEnrouteSOCount' : 'ServiceOrder/GetInProgressAndEnrouteSOCount';
        return this.http.get(`${BASE_URL}${apiUrl}?employeeId=${employeeId}`);
    }

    savePaymentResponse(uId, serviceOrderId) {
        return this.http.post(`${BASE_URL}CCPayment/ProcessChasePaymentResponse?responseUId=${uId}&serviceOrderId=${serviceOrderId}`, null)
    }

    authorizeCCPaymentForChase(transactionInfo: any) {
        return this.http.post(`${BASE_URL}CCPayment/AuthorizeCCPaymentForChase`, transactionInfo);
    }

    AuthorizeCCPaymentForBarclays(transactionInfo: any) {
        return this.http.post(`${BASE_URL}CCPayment/AuthorizeCCPaymentForBarclays`, transactionInfo);
    }

    processBarclaysPaymentResponse(transactionInfo: any) {
        return this.http.post(`${BASE_URL}CCPayment/ProcessBarclaysPaymentResponse`, transactionInfo);
    }

    checkInProgressEnRouteServiceOrders(){
      return this.http.get(`${BASE_URL}ServiceOrder/CheckInProgressEnRouteServiceOrders`);
    }

    checkISCInProgressEnRouteServiceOrders() {
      return this.http.get(`${BASE_URL}ISCServiceOrder/CheckISCInProgressEnRouteServiceOrders`);
    }

    getIscRuleForServiceOrder(soId) {
        return this.http.get(`${BASE_URL}ISCRule/GetISCRuleForServiceOrder?serviceOrderId=${soId}`);
    }

    /**
     *
     * @param employeeId : ISC employee ID
     * @returns User details including (t1/t2 labor rates, t1/t2 callout rates, IscCode and iscSupCode)
     */
    getIscServiceEmployeeDetailsById(employeeId: string) {
        return this.http.get(`${BASE_URL}ServiceEmployee/GetServiceEmployeeById?employeeId=${employeeId}`);
    }
}
