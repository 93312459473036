import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { BuilderComponent } from './modules/builder/builder.component';
import { HomeComponent } from './modules/home/home.component';
import { ServiceOrderComponent } from './modules/service-order/service-order.component';
import { ViewerComponent } from './modules/viewer/viewer.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { BarclayResponseComponent } from './modules/service-order/components/barclay-response/barclay-response.component';
import { MasterDataComponent } from './modules/home/modules/master-data/master-data.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () => 
                    import('./modules/auth/auth.module').then(
                        (m) => m.AuthModule
                    ),
            },
        ],
    },
    {
        path: 'builder',
        component: BuilderComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/builder/builder.module').then(
                        (m) => m.BuilderModule
                    ),
            },
        ],
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
        ],
    },
    {
        path: 'app',
        component: ViewerComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/viewer/viewer.module').then((m) => m.ViewerModule),
            },
        ],
    },
    {
        path: 'master-data',
        component: MasterDataComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/home/modules/master-data/master-data.module').then((m) => m.MasterDataModule),
            },
        ],
    },
    {
        path: 'service-order',
        component: ServiceOrderComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/service-order/service-order.module').then((m) => m.ServiceOrderModule),
            },
        ],
    },
    {
        path: 'barclay-response',
        component: BarclayResponseComponent,
        canActivate: [AuthGuardService]
      }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
