import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SoDetailsTabService {

    constructor(
        private http: HttpClient
    ) { }
    
    getServiceCenters(company) {
        return this.http.get(`${BASE_URL}ServiceCentre/GetServiceCentresForDDL?company=${company}`);
    }

    getConfigurations(serviceDepartment: string, text: string, company: string) {
        return this.http.get(`${BASE_URL}Configuration/GetConfigurationsForDDL?company=${company}&filterText=${text}&serviceDepartment=${serviceDepartment}`);
    }

    getCustomerInfoByConfiguration(configurationId: string, serial?: any) {
        const serialForWorkSiteAddress = serial ? `&serial=${serial}` : '';
        return this.http.get(`${BASE_URL}Configuration/GetCustomerInfoByConfiguration?configuration=${configurationId}${serialForWorkSiteAddress}`);
    }

    getSignals(company) {
        return this.http.get(`${BASE_URL}Signal/GetSignalsForDDL?company=${company}`);
    }

    getCallgroups(company) {
        return this.http.get(`${BASE_URL}CallGroup/GetCallGroupsForDDL?company=${company}`);
    }

    getObjectsForDDL(configurationId: string) {
        return this.http.get(`${BASE_URL}EquipmentObject/GetObjectsForDDL?configuration=${configurationId}`);
    }

    getObjects(configurationId: string, serviceDepartment: string) {
        return this.http.get(`${BASE_URL}EquipmentObject/GetObjectsForDDL?configuration=${configurationId}&serviceDepartment=${serviceDepartment}`);
    }

    getIscObjects(configurationId: string, serviceDepartment: string) {
        return this.http.get(`${BASE_URL}EquipmentObject/GetISCObjectsForDDL?configuration=${configurationId}&serviceDepartment=${serviceDepartment}`);
    }

    getSerialNumbers(configurationId?: string, objectId?: string) {
        let payload = '';
        if (objectId) {
            payload = `eObject=${objectId}&configuration=${configurationId}`
        } else {
            payload = `configuration=${configurationId}`
        }
        return this.http.get(`${BASE_URL}EquipmentObject/GetSerialNumbersForDDL?${payload}`);
    }

    getIscSerialNumbersForSearch(serviceDepartment: string, company, configurationId: string, objectId: string, serialNumberSearchString: string = null, offlineOrder: boolean) {
        const filterData = this.setPayloadForSerialNumberSearch(serviceDepartment, company, configurationId, objectId, serialNumberSearchString, offlineOrder);
        return this.http.post(`${BASE_URL}EquipmentObject/GetISCSerialNumberSearchView`, filterData);
    }

    getSerialNumbersForSearch(serviceDepartment: string, company, configurationId: string, objectId: string, serialNumberSearchString: string = null, offlineOrder: boolean) {
        const filterData = this.setPayloadForSerialNumberSearch(serviceDepartment, company, configurationId, objectId, serialNumberSearchString, offlineOrder);
        return this.http.post(`${BASE_URL}EquipmentObject/GetSerialNumberSearchView`, filterData);
    }

    setPayloadForSerialNumberSearch(serviceDepartment: string, company, configurationId: string, objectId: string, serialNumberSearchString: string = null, offlineOrder: boolean) {
        let predicatesList = [];
        if (configurationId) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'configuration',
                operator: "contains",
                value: configurationId,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        if (objectId) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'model',
                operator: "contains",
                value: objectId,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        if (serialNumberSearchString) {
            predicatesList.push({
                ignoreAccent: false,
                isComplex: false,
                field: 'serialNumber',
                operator: "contains",
                value: serialNumberSearchString,
                ignoreCase: true,
                oprtr: "contains"
            });
        }
        const filterData = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize: 100
            },
            parameters: [{
                key: "company",
                value: company
            }, {
                key: "serviceDepartment",
                value: serviceDepartment
            }, {
                key: 'offlineOrder',
                value: offlineOrder+''
            }],
            skip: 0,
            take: 100
        };
        // if (serialNumberSearchString) {
        filterData['onWhere'] = {
            ignoreAccent: false,
            isComplex: true,
            condition: "and",
            predicates: predicatesList
        };
        // }
        return filterData;
    }

    getContractAndWarranty(objectId: string, serialNumber: string, serviceContractCode: string, warrantyCode: string, company: string, serviceTypeCode: string = '', soCreatedDate) {
        const coverageTypeCode = serviceTypeCode ? `&coverageTypeCode=${serviceTypeCode}` : '';
        const soCreatedDateParam = soCreatedDate ? `&soCreatedDate=${soCreatedDate}`: '';
        let payload = `item=${objectId}&serie=${serialNumber}&serviceContractCode=${serviceContractCode}&company=${company}&warrantyCode=${warrantyCode}${coverageTypeCode}${soCreatedDateParam}`;
        return this.http.get(`${BASE_URL}EquipmentObject/GetContractAndWarranty?${payload}`);
    }    

    getIscContractAndWarranty(objectId: string, serialNumber: string, serviceContractCode: string, warrantyCode: string, company: string, serviceTypeCode: string = '', soCreatedDate) {
        const coverageTypeCode = serviceTypeCode ? `&coverageTypeCode=${serviceTypeCode}` : '';
        const soCreatedDateParam = soCreatedDate ? `&soCreatedDate=${soCreatedDate}`: '';
        let payload = `item=${objectId}&serie=${serialNumber}&serviceContractCode=${serviceContractCode}&company=${company}&warrantyCode=${warrantyCode}${coverageTypeCode}${soCreatedDateParam}`;
        return this.http.get(`${BASE_URL}EquipmentObject/GetISCContractAndWarranty?${payload}`);
    }
}
