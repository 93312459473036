import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
    selector: 'app-column-chooser',
    templateUrl: './column-chooser.component.html',
    styleUrls: ['./column-chooser.component.scss']
})
export class ColumnChooserComponent implements OnInit {

    @Input() showColumnChooser: boolean = false;
    @Input() grid: GridComponent;
    columns: any = [];

    @Output() onClose = new EventEmitter();
    
    @HostListener('window:mouseup', ['$event'])
    clickout(event) {
        if (this.eRef.nativeElement.contains(event.target)) {
            this.showColumnChooser = !this.showColumnChooser;
        } else {
            this.showColumnChooser = false;
        }
    }
    
    constructor(
        private eRef: ElementRef,
    ) { }

    ngOnInit(): void {
        this.columns = this.grid?.columns;
    }


    toggleShowHide(field: string, checked, grid: GridComponent) {
        this.showColumnChooser = true;
        if ( checked ) {
            grid.showColumns(field, 'field')
        } else {
            grid.hideColumns(field, 'field');
        }
    }

}
