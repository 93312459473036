
<div class="row">
    <app-loader *ngIf="showLoader"></app-loader>
    <div class="col mt-4"  *ngIf="showItemsGrid">
        <ejs-grid #partsOrderItemsGrid
            id="parts-order-items-grid"
            [height]="gridHeight"
            [dataSource]="items"
            [allowPaging]="false"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [allowExcelExport]="true"
            [enablePersistence]="true"
            [query]="itemsQuery"
            [editSettings]="{ allowEditing: status === statuses.open && !isManager && !isCSA0 && !isReadOnly, allowAdding: true, allowDeleting: status === statuses.open, newRowPosition: 'Top' }"
            [filterSettings]="{ type: 'Menu' }"
            [enablePersistence]="true"
            (dataBound)="dataBound($event)"
            (rowDataBound)="rowDataBound($event)"
            (toolbarClick)="toolbarClick($event, partsOrderItemsGrid)"
            (created)="onGridCreated()"
            (actionBegin)="actionBegin($event)"
            (actionComplete)="actionComplete($event, partsOrderItemsGrid)"
            (commandClick)="commandColumnClick($event, partsOrderItemsGrid)"
            (load)="load($event)"><!--(rowSelected)='rowSelected($event)'-->
            <e-columns>
                <!-- <e-column field='id' headerText='id' [width]='140' [isPrimaryKey]='true' [visible]='false' [showInColumnChooser]='false'></e-column> -->
                <e-column
                    *ngFor="let column of columns;"
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [clipMode]="'EllipsisWithTooltip'"
                    [type]="column.type"
                    [edit]="column.edit"
                    [width]="column.width"
                    [showInColumnChooser]="column.showInColumnChooser"
                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                    [format]="column.format ? column.format : null"
                    [textAlign]="column.textAlign"
                    [visible]="column.visible"
                    [isPrimaryKey]="column.isPrimaryKey || false"
                    [validationRules]="column?.validationRules || null"
                    [commands]="column?.commands ? column.commands : null"
                    [allowEditing]="column.allowEditing"
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.field === 'shipmentOrderLN'" #template let-data>
                        <a href="javascript:" (click)="onShipmentIdClick.emit({orderId: data.shipmentOrderId, orderType: orderType})">{{data.shipmentOrderLN}}</a>
                    </ng-template>
                    <ng-template *ngIf="column.headerText === 'Item'" #headerTemplate let-data>
                        <div>
                            Item <span class="required-asterik">*</span>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #itemsGridColumnChooser>
    <app-column-chooser
        [grid]="partsOrderItemsGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [technicianId]="technicianId"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>
