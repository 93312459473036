<ejs-grid *ngIf="claimsForm.value" 
    #claimsGrid
    id="claimsGrid"
    [dataSource]="claimsForm.value.iscClaimDetails"
    [height]="270"
    [rowHeight]="30"
    [allowPaging]="false"
    [allowFiltering]="true"
    [allowExcelExport]="true"
    [allowSorting]="true"
    [allowReordering]="true"
    [enablePersistence]="false"
    [allowResizing]="true"
    [editSettings]="{ allowEditing: claimsForm.value.status === 'Open' || claimsForm.value.status === 'Awaiting Information', allowAdding: true, allowDeleting: true, mode: 'Normal' }"
    [toolbar]="toolbar"
    [filterSettings]="{ type: 'Menu' }"
    [query]="claimsQuery"
    (toolbarClick)="toolbarClick($event, claimsGrid)"
    (created)="onGridCreated()"
    (actionBegin)="actionBegin($event)"
    (actionComplete)="actionComplete($event, claimsGrid)"
    (commandClick)="onCommandClick($event)"
    (dataBound)="dataBound($event)"
    (queryCellInfo)="queryCellInfo($event)"
    (rowDataBound)="rowDataBound($event)"
    ><e-columns>
        <e-column 
            *ngFor="let column of columns;" 
            [field]="column.field" 
            [headerText]="column.headerText"
            [clipMode]="'EllipsisWithTooltip'"
            [type]="column.type"
            [width]="column.width"
            [edit]="column.edit"
            [editType]="column.editType"
            [foreignKeyValue]="column.foreignKeyValue"
            [foreignKeyField]="column.foreignKeyField"
            [dataSource]="column.dataSource"
            [isPrimaryKey]="column.isPrimaryKey || false"
            [format]="column.format ? column.format : null"
            [textAlign]="column.textAlign" 
            [visible]="column.visible"
            [validationRules]="column?.validationRules || null"
            [showInColumnChooser]="column.showInColumnChooser"
            [allowEditing]="column.allowEditing" 
            [filter]="column.filter ? column.filter : { type: 'Menu' }"
            [commands]="column?.commands ? column.commands : null"
            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
        </e-column>
    </e-columns>
</ejs-grid>

<ng-template #claimsGridColumnChooser>
    <app-column-chooser
        [grid]="claimsGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<ng-template #includeTaxOption>
    <div class="mr-2">
        <ejs-checkbox
            #includeTaxCheckbox
            id='claim-include-tax'
            label="Include Tax"
            [checked]="claimsForm.value?.includeTax"
            [disabled]="claimsForm.value?.isNationalAccount ? !iscRulesForSO?.iscTax : false"
            (created)="includeTaxCheckboxCreated(includeTaxCheckbox)"
            (change)="includeTaxChange($event)"
        ></ejs-checkbox>
    </div>
</ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [technicianId]="claimsForm.value?.technicianId"
    [serviceItemGroup]="serviceItemGroup"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>