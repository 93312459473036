import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';

import {
    CommandColumnService,
    EditService,
    FilterService,
    GridComponent,
    IEditCell,
    ResizeService,
    SortService
} from '@syncfusion/ej2-angular-grids';
import { ChangeEventArgs, DashboardLayout, ResizeArgs } from '@syncfusion/ej2-layouts';
import { PageElement } from 'src/app/shared/models/page.model';

@Component({
    selector: 'app-page-structure',
    templateUrl: './page-structure.component.html',
    styleUrls: ['./page-structure.component.scss'],
    providers: [
        EditService,
        FilterService,
        ResizeService,
        SortService,
        CommandColumnService
    ]
})
export class PageStructureComponent implements OnInit, OnChanges {

    @Input()
    pageStructure;
    structure;
    
    @Input()
    layoutId = 1;

    @Input()
    update;

    @Input() gridName;
    @Input() preference;

    selectedItem;
    selectedItemProperties;

    showGrid: boolean = true;
    showPopup: boolean = false;

    @ViewChild('default_dashboard') layout: DashboardLayout;
    @ViewChildren('grid') grids: GridComponent;

    @Output()
    itemSelected = new EventEmitter();
    
    @Output()
    panelResized = new EventEmitter();
    
    @Output()
    panelLocationChanged = new EventEmitter();

    constructor() { }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        // console.log(this.pageStructure);
        if (this.pageStructure && this.pageStructure.length) {
            this.pageStructure.forEach( (element: PageElement) => {
                if ( element.type === 'grid' ) {
                    this.showGrid = !this.showGrid;
                }
            });
        }
    }
    
    getItemType(text: string, type: string) {
        if (text === 'label' || text === 'placeholder') {
            return 'text';
        } else if (((text === 'min' || text === 'max') && type === 'number') || text === 'decimals' || text === 'recordsperpage') {
            return 'number';
        } else if ((text === 'min' || text === 'max') && type === 'datepicker') {
            return 'date';
        } else if (text.includes('allow') || text.includes('show')) {
            return 'checkbox';
        }
    }

    selectItem(item: any) {
        const keysNotAllowed = ['type', 'id', 'dataSource', 'api', 'click', 'filterSettings', 'columns', 'sizeX', 'sizeY', 'col', 'row', 'minSizeX', 'minSizeY', 'toolbarItems', 'toolbar'];
        this.selectedItemProperties = [];
        this.selectedItem = item;
        for (let key in item) {
            if (!keysNotAllowed.includes(key)) {
                this.selectedItemProperties.push({
                    type: this.getItemType(key.toLowerCase(), item['type']),
                    text: key,
                    value: item[key]
                });
            }
        }
        this.itemSelected.emit({
            selectedItemProperties: this.selectedItemProperties,
            selectedItem: this.selectedItem
        });
    }

    onResize(event: ResizeArgs) {
        this.panelResized.emit({
            panelId: event.element.id,
            panelInfo: event.element.dataset,
            panels: this.layout.panels
        });
    }

    onLocationChange(event: ChangeEventArgs) {
        this.panelLocationChanged.emit(event);
    }

}
