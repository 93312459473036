<ejs-grid *ngIf="gridUpdated"
    #grid 
    [id]="gridName"
    [query]="query"
    [height]="gridProps.height"
    [dataSource]="gridData" 
    [editSettings]="{'allowEditing': gridProps.allowEditing}" 
    [allowPaging]="gridProps.allowPaging" 
    [pageSettings]="pageSettings"
    [allowFiltering]="gridProps.allowFiltering"
    [allowSorting]="gridProps.allowSorting" 
    [allowResizing]="true" 
    [allowReordering]="true" 
    [enablePersistence]="true" 
    [allowSelection]="gridProps.allowSelection"
    [selectionSettings]="gridProps.selectionSettings"
    [allowExcelExport]="true"
    [allowPdfExport]="allowPdfexport"
    [toolbar]="toolbar"
    [filterSettings]="gridProps.filterSettings"
    [childGrid]="gridProps.childGrid"
    (actionComplete)="onActionComplete($event, grid)"
    (beforeExcelExport)="grid.showSpinner()"
    (excelExportComplete)="grid.hideSpinner()"
    (excelQueryCellInfo)="exportQueryCellInfo($event)"
    (pdfQueryCellInfo)='exportQueryCellInfo($event)' 
    (toolbarClick)="toolbarClick($event, grid)"
    (created)="created($event, grid)">
    <e-columns>
        <e-column 
            *ngFor="let column of gridProps.columns;" 
            [clipMode]="'EllipsisWithTooltip'"
            [field]="column.field" 
            [type]="column.type"
            [headerText]="column.headerText" 
            [valueAccessor]="column.valueAccessor"
            [disableHtmlEncode]="column.disableHtmlEncode"
            [width]="column.width"
            [textAlign]="column.textAlign ? column.textAlign : 'Left'" 
            [visible]="column.visible"
            [showInColumnChooser]="column.showInColumnChooser"
            [format]="column.format ? column.format : null"
            [filter]="column.filterType ? { type: column.filterType} : null" 
            [allowEditing]="column.allowEditing" 
            [commands]="column?.commands ? column.commands : null"
            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
        </e-column>
    </e-columns>
</ejs-grid>

<app-mobile-service-popup 
    *ngIf="showPopup"
    [popupId]="popupId"
    (onClose)="showPopup = false"
></app-mobile-service-popup>

<ng-template #columnChooser>
    <app-column-chooser
        [grid]="grid"
        [columns]="gridProps.columns"
        [showColumnChooser]="openColumnChooser"
        (onClose)="openColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<ng-template #ejs_splitButton>
    <app-dropdown-button
        [items]="dropdownProps.items"
        (onSelection)="onToolbarDropdownSelection.emit($event)"
        [iconCss]="dropdownProps.iconCss"
        [cssClass]="dropdownProps.cssClass"
    ></app-dropdown-button>
</ng-template>