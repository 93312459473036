<div class="tab-content-container mt-3 mb-2">
    <h4 class="border-bottom">Links</h4>
    <div class="links-grid">
        <ejs-grid 
            #linksGrid
            [dataSource]="links" 
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [allowExcelExport]="true"
            [filterSettings]="{ type: 'Menu' }"
            (toolbarClick)="onToolbarClick($event, linksGrid)"
            (created)="onGridCreated()"
            (actionComplete)="onGridActionComplete($event, linksGrid)"
            >
            <e-columns>
                <e-column 
                    *ngFor="let column of gridColumns;" 
                    [field]="column.field" 
                    [headerText]="column.headerText" 
                    [type]="column.type"
                    [width]="column.width"
                    [format]="column.format ? column.format : ''"
                    [textAlign]="column.textAlign" 
                    [visible]="column.visible"
                    [filter]="column.filter ? column.filter : { type: 'Menu'}" 
                    [allowEditing]="column.allowEditing" 
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.field === 'status'" #template let-data>
                        <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                    </ng-template>
                    <ng-template *ngIf="column.field === 'consignment'" #template let-data>
                        <a href="javascript:" (click)="onConsignmentClick(data.consignment)">{{data.consignment}}</a>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #statusTemplate let-data>
    <span class="status {{data.status}}">{{data.status}}</span>
</ng-template>

<ng-template #serviceOrderLinksColumnChooser>
    <app-column-chooser
        [grid]="linksGrid"
        [columns]="gridColumns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>