<div
    class="modal fade show in" 
    id="claims-modal" 
    tabindex="-1" 
    aria-labelledby="claims-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex w-100">
        
                <div class="flex-fill" >
                  <h3 class="modal-title mb-0">Service Orders list for Claims</h3>
                </div>

                <div class="btns-header">
                  <!-- <button 
                      class="header-icon close p-0 m-0" 
                      (click)="close()" 
                      aria-label="Close"
                  ><i class="fas fa-times" 
                        title="Close Popup"
                    ></i>
                  </button> -->
                  <!-- <button 
                      class="close header-icon expand-modal p-0 m-0"
                      (click)="isExpanded=!isExpanded"
                  ><i class="fas ml-2 mr-2" 
                      [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                      [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                    ></i>
                </button> -->
              </div>
            </div>
          </div>
            <div class="modal-body">
                <ejs-grid #claimsGrid
                    [dataSource]="data"
                    [height]="gridHeight"
                    [allowPaging]="true"
                    [allowFiltering]="true"
                    [allowSorting]="true"
                    [allowResizing]="true"
                    [allowReordering]="true"
                    [allowExcelExport]="true"
                    [pageSettings]="pageSettings"
                    [allowSelection]="true"
                    [filterSettings]="{ type: 'Menu' }"
                    [selectionSettings]="{ mode: 'Both', checkboxOnly: true }"
                    [toolbar]="grid?.toolbar"
                    (toolbarClick)="onToolbarClick($event, claimsGrid)"
                    (commandClick)="onCommandClick($event)"
                    (created)="onGridCreated()"
                    >
                    <e-columns>
                        <e-column 
                            *ngFor="let column of gridColumns;" 
                            [field]="column.field" 
                            [headerText]="column.headerText"
                            [clipMode]="'EllipsisWithTooltip'"
                            [showInColumnChooser]="column.showInColumnChooser"
                            [type]="column.type"
                            [width]="column.width"
                            [filter]="column.filter ? column.filter : { type: 'Menu' }"
                            [format]="column.format ? column.format : null"
                            [textAlign]="column.textAlign" 
                            [visible]="column.visible"
                            [commands]="column?.commands ? column.commands : null"
                            [allowEditing]="column.allowEditing" 
                            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true"
                            [allowSorting]="column.allowSorting"
                        >
                            <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                <a href="javascript:" (click)="serviceOrderIdClicked(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div class="modal-footer p-0">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>

<app-isc-popup
    *ngIf="showServiceOrderPopup"
    [id]="serviceOrderId"
    [data]="serviceOrderData"
    (onClose)="showServiceOrderPopup = false"
></app-isc-popup>

<ng-template #columnChooser>
    <app-column-chooser
        [columns]="mainGrid?.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>