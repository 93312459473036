import Swal from "sweetalert2"
import { ActionButtonTitles } from "../models/app.model"

export const getColumnProperties = (columnName: string) => {
    switch (columnName) {
        case 'srNo': {
            return {
                field: columnName,
                title: 'Sr No.',
                type: 'text',
                editType: '',
                editParams: null,
                allowEditing: false
            }
        }
        case 'field': {
            return {
                field: columnName,
                title: 'Field',
                type: 'text',
                editType: '',
                editParams: null,
                allowEditing: false
            }
        }
        case 'headerText': {
            return  {
                field: columnName,
                title: 'Header Text',
                type: 'text',
                editType: '',
                editParams: null,
                allowEditing: true
            }
        }
        case 'allowFiltering': {
            return  {
                field: columnName,
                title: 'Allow Filtering',
                type: 'boolean',
                editType: 'checkbox',
                editParams: null,
                allowEditing: true
            }
        }
        case 'allowEditing': {
            return  {
                field: columnName,
                title: 'Allow Editing',
                type: 'boolean',
                editType: 'checkbox',
                editParams: null,
                allowEditing: true
            }
        }
        case 'filterType': {
            return  {
                field: columnName,
                title: 'Filter Type',
                type: '',
                editType: 'dropdownedit',
                editParams: null,
                allowEditing: true
            }
        }
        case 'textAlign': {
            return  {
                field: columnName,
                title: 'Text Alignment',
                type: '',
                editType: 'dropdownedit',
                editParams: null,
                allowEditing: true
            }
        }
        case 'visible': {
            return  {
                field: columnName,
                title: 'Visible',
                type: 'boolean',
                editType: 'checkbox',
                editParams: null,
                allowEditing: true
            }
        }
        case 'type': {
            return  {
                field: columnName,
                title: 'Type',
                type: 'string',
                editType: '',
                editParams: null,
                allowEditing: true
            }
        }
        default: {
            return {
                field: columnName,
                title: columnName,
                editParams: null,
                allowEditing: true
            }
        }
    }
}

function forceDownload(uri: string, filename: string) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = uri;
    //: string For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}
  
  // Current blob size limit is around 500MB for browsers
export function downloadResource(url: string, filename: string) {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
    })
    .then(response => response.blob())
    .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
}

const arithmeticOperators = ['+', '-', '/', '*', '(', ')'];

export const solveEquation = (equation: string) => {
    const equationArray = breakEquation(equation);
    let equationWithConstants = '';
    let error = false;
    equationArray.forEach( equationItem => {
        if( !arithmeticOperators.includes(equationItem) ) {
            if ( isNaN(equationItem as any) ) {
                const element = document.getElementById(equationItem) ? document.getElementById(equationItem)['ej2_instances'][0] : null;
                if ( element ) {
                    equationWithConstants += element.value;
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: `There is some problem applying the rules because of the equation. Please fix the equation to continue. The variable <strong>${equationItem}</strong> doesn't exist`,
                        toast: true,
                        timer: 5000,
                        position: 'top-right',
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                    error = true;
                    return;
                }
            } else {
                equationWithConstants += equationItem;
            }
        } else {
            equationWithConstants += equationItem;
        }
    });
    if ( !error ) {
        return eval(equationWithConstants);
    }
}

const breakEquation = (equation: string) => {
    return equation.split(/([-+*\()^/])/g);
}

export const solveDateEquation = (equation) => {
    let error = false;
    if(equation && isNaN(equation)){
        let retDate = new Date(new Date().setHours(0, 0, 0, 0));
        if(equation.startsWith("TODAY")){
            return retDate;
        }
        else if(equation.startsWith("DATEADD") || equation.startsWith("DATESUB")){
            let multiplier = 1;
            if(equation.startsWith("DATESUB"))
                multiplier = -1;
            const param = equation.substring(equation.indexOf("(")+1, equation.lastIndexOf(")")).split(",");
            if(!param[0].includes("TODAY")){
                const element = document.getElementById(param[0]) ? document.getElementById(param[0])['ej2_instances'][0] : null;
                if ( element ) {
                    if(element.value){
                        retDate = new Date(element.value);
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: `There is some problem applying the rules because of the equation. Please fix the equation to continue. The variable <strong>${equation}</strong> doesn't exist`,
                        toast: true,
                        timer: 5000,
                        position: 'top-right',
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                    error = true;
                    return;
                }
            }
            if(retDate){
                if(isNaN(param[1]) && (param[1].includes("MONTH") || param[1].includes("DAY") || param[1].includes("YEAR"))){
                    let subParam = param[1].substring(param[1].indexOf("(")+1, param[1].lastIndexOf(")"));
                    if(isNaN(subParam)){
                        const subElement = document.getElementById(subParam) ? document.getElementById(subParam)['ej2_instances'][0] : null;
                        if ( subElement ) {
                            if(subElement.value){
                                subParam = subElement.value ? parseInt(subElement.value) : 0;
                            }
                            else{
                                return null;
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                html: `There is some problem applying the rules because of the equation. Please fix the equation to continue. The variable <strong>${equation}</strong> doesn't exist`,
                                toast: true,
                                timer: 5000,
                                position: 'top-right',
                                showConfirmButton: false,
                                showCloseButton: true
                            });
                            error = true;
                            return;
                        }
                    }
                    else{
                        subParam = parseInt(subParam);
                    }
                    parseInt(param[1].substring(param[1].indexOf("(")+1, param[1].lastIndexOf(")")))
                    if(isNaN(param[1]) && param[1].includes("MONTH")){
                        return new Date(retDate.setMonth(retDate.getMonth() + (multiplier*subParam)));
                    }
                    else if(isNaN(param[1]) && param[1].includes("DAY")){
                        return new Date(retDate.setDate(retDate.getDate() + (multiplier*subParam)));
                    }
                    else if(isNaN(param[1]) && param[1].includes("YEAR")){
                        return new Date(retDate.setFullYear(retDate.getFullYear() + (multiplier*subParam)));
                    }
                }
                else if(!isNaN(param[1])){
                    return new Date(retDate.setDate(retDate.getDate() + (multiplier*parseInt(param[1]))));
                }
            }
        }
    }
    return null;
}

export const validationMessage = (message: string) => {
    return `
    <div class="e-tooltip-wrap e-lib e-control e-popup e-griderror" style="margin-top:33px;z-index:9999;left:0px;">
        <div class="e-tip-content" style="white-space: nowrap;">
            <label class="e-error" for="Predecessor" id="Predecessor-info" style="display: block;">${message}</label>
        </div>
        <div class="e-arrow-tip e-tip-top">
            <div class="e-arrow-tip-outer e-tip-top"></div>
            <div class="e-arrow-tip-inner e-tip-top"></div>
        </div>
    </div>`;
}

export const isApprove = (value) => {
    return value === ActionButtonTitles.approve;
}

export const isComplete = (value) => {
    return value === ActionButtonTitles.complete;
}

export const isReject = (value) => {
    return value === ActionButtonTitles.reject;
}
