<div
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="cycle-count-add-modal" 
    tabindex="-1" 
    aria-labelledby="cycle-count-add-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id flex-fill">
                        <h4 class="modal-title mb-0 item-id">Warehouses</h4>
                    </div>
            
                    <div class="btns-header">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="expand()"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-0">
                <div class="w-100" style="margin-top: 20px;">
                    <ejs-grid
                        #cycleCountAddPopupGrid
                        id="{{gridName}}"
                        [selectionSettings]='selectionOptions'
                        [dataSource]="warehouseData"
                        [query]="query"
                        [height]="gridHeight"
                        [pageSettings]="pageSettings"
                        [gridLines]="false"
                        [allowPaging]="true"
                        [enablePersistence]="false"
                        [allowFiltering]="true"
                        [allowSorting]="true"
                        [allowResizing]="true"
                        [allowReordering]="true"
                        [allowExcelExport]="true"
                        [filterSettings]="{ type: 'Menu' }"
                        [editSettings]="{ allowEditing: false, allowAdding: false, allowDeleting: false , newRowPosition: 'Top' }"
                        [toolbar]="grid?.toolbar"
                        (created)="onGridCreated()"
                        (toolbarClick)="onToolbarClick($event, cycleCountAddPopupGrid)"
                        (actionBegin)="onGridActionBegin($event)"
                        (actionComplete)="onGridActionComplete($event, cycleCountAddPopupGrid)">
                        <e-columns>
                            <e-column
                                *ngFor="let column of mainGrid?.columns;"
                                [rowHeight]="30"
                                [field]="column.field"
                                [headerText]="column.headerText"
                                [clipMode]="'EllipsisWithTooltip'"
                                [type]="column.type"
                                [width]="column.width"
                                [isPrimaryKey]="column.isPrimaryKey || false"
                                [validationRules]="column?.validationRules || null"
                                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                [format]="column.format ? column.format : null"
                                [textAlign]="column.textAlign"
                                [editType]="column.editType"
                                [showInColumnChooser]="column.showInColumnChooser"
                                [visible]="column.visible"
                                [commands]="column?.commands ? column.commands : null"
                                [allowEditing]="column.allowEditing"
                                [allowReordering]="column.allowReordering ?? true"
                                [edit]="column.edit"
                                [freeze]="column.freeze ? column.freeze: null"
                                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                
                                <ng-template *ngIf="column.field === 'status'" #template let-data>
                                    <span class="grid-status-column {{getStatusClass(data.status)}}">
                                        {{data.status}}
                                    </span>
                                </ng-template>
                
                                <ng-template *ngIf="column.field === 'cycleCountId'" #template let-data>
                                    <a href="javascript:" (click)="onServiceOrderClick(data.cycleCountId)">{{data.cycleCountId}}</a>
                                </ng-template>
                
                            </e-column>
                        </e-columns>
                      </ejs-grid>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="onCreate()">Create</button>
            </div>
        </div>
    </div>
</div>

<ng-template #columnChooser>
    <app-column-chooser
        [columns]="mainGrid?.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>