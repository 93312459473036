import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { Section, Tab, TabTypes, Element, MSPopup, GridTypes, GridElement, FieldTypes } from 'src/app/modules/builder/models/main.model';
import { CalibrationData, CalibrationTypes, DataField } from 'src/app/modules/home/modules/calibrations/models/calibration.model';
import { CalibrationService } from 'src/app/modules/home/modules/calibrations/services/calibration.service';
import { ApiResponse, OrderTypes, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { PageService } from 'src/app/shared/services/page.service';
import { calculateMinMaxDates } from 'src/app/shared/utils/date-functions';
import { formatDate } from '@angular/common';


@Component({
    selector: 'app-ms-dynamic-popup',
    templateUrl: './ms-dynamic-popup.component.html',
    styleUrls: [
        './ms-dynamic-popup.component.scss',
        '../../../../assets/scss/service-order.scss',
        '../../../../assets/scss/popup.scss'
    ]
})
export class MsDynamicPopupComponent implements OnInit {


    private destroy$: Subject<null> = new Subject();
    tabTypes = TabTypes;
    isExpanded: boolean = true;
    showLoader: boolean = false;
    isLastTabActive: boolean = false
    page;
    loadedData: string = null;
    payloadData: any;

    USER;

    fields = {};
    elements: Element[] = [];

    @Input() tabName: string = 'details';
    @Input() id: string = null;
    @Input() orderId: string = null;
    @Input() pageId: string;
    @Input() pageType: string;
    @Input() showComments: boolean = false;
    @Input() canEdit: boolean = true;
    @Input() claim: any;
    @Input() data: any;
    @Input() serviceOrderData: any;
    @Input() module: string = 'AdjustmentOrder';
    @Input() calibrationType: string;

    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();
    @Output() onNeedRefresh = new EventEmitter();

    @ViewChild('attachmentsTab') attachmentsTab;
    @ViewChildren('pageLayout') layouts;
    moduleType: string;
    lastTabId: number;

    constructor(
        private pageService: PageService,
        private commonService: CommonService,
        private calibrationService: CalibrationService
    ) {
        this.USER = commonService.USER;
    }

    ngOnInit(): void {
        this.loadPopup();
        this.moduleType = this.module.toLowerCase().replace(/\s+/g, '');
        this.isExpanded  = this.moduleType !== OrderTypes.adjustmentorder;
        if (this.data && this.data.status === 'Order Sent') {
            this.canEdit = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.data && changes.data.currentValue) {
            this.data = changes.data.currentValue;
        }
    }

    onTabClick(index: number, event: Event): void {
        const tabs = this.page.elements.tabs;
        let moveToNext = true;
        
        for (const layout of this.layouts) {
          if (layout.tabId === tabs[index].tabId) {
            break;
          }

          const tab = tabs.find(tab => tab.tabId === layout.tabId);

          if (!layout.validate()) {
            tab.isValid = false;
            moveToNext = false;
            event.stopPropagation();
            event.preventDefault();
          }
        }
        
        if (moveToNext) {
          const currentTab = tabs[index];
          this.isLastTabActive = currentTab.tabId === this.lastTabId;
        }
    }
      

    onFormStatusChange(section, tab, $event) {
        section.isValid = $event;
        tab.isValid = tab.sections.every(s => s.isValid === undefined || s.isValid);
    }
      
    expandCollapse(value: boolean) {
        this.isExpanded = value;
    }

    loadPopup() {
        this.getPageLayout();
        this.setPageData();
    }

    setPageData() {
        if (!this.data) {
            this.data = {
                warehouse: this.USER.warehouse,
                status: 'Open',
                company: this.USER.company,
                adjustmentOrderNumber: '',
                manualAdjustment: 'yes',
                orderDate: new Date(),
                createdDate: new Date(),
                electronicOrderNumber: '',
                attachments: [],
                comments: [],
                history: []
            }
        }
        this.setFields(this.data.fields);
    }

    setPageElements(tabs: Tab[]) {
        tabs.forEach(tab => {
            tab.sections.forEach(section => {
                section.elements.forEach(element => {
                    this.elements.push(element);
                });
            });
        });
    }

    setFields(fields: DataField[]) {
        if ( fields && fields.length ) {
            fields.forEach(field => {
                this.fields[field.key] = field.value;
            });
        } else {
            Object.keys(this.data).forEach( (key: string) => {
                this.fields[key] = this.data[key];
            });
        }
    }

    getPageLayout() {
        this.showLoader = true;
        this.pageService.getPageById(this.pageId, this.pageType)
            .subscribe((res: ApiResponse) => {
                if (res.isSuccessful) {
                    let pageData: MSPopup = typeof res.result === 'string' ? JSON.parse(res.result) : res.result;
                    pageData = {
                        ...pageData,
                        elements: typeof pageData.elements === 'string' ? JSON.parse(pageData.elements) : pageData.elements,
                        rules: typeof pageData.rules === 'string' ? JSON.parse(pageData.rules) : pageData.rules
                    };
                    this.setPageElements(pageData.elements.tabs);
                    if ( this.tabName === 'comments' ) {
                        this.showComments = true;
                    } else {
                        pageData.elements.tabs.forEach((tab: Tab) => {
                            if ( tab.tabKey === this.tabName ) {
                                tab.isActive = true;
                            } else {
                                tab.isActive = false;
                            }
                        });
                    }
                    this.page = pageData;
                    this.page.elements.parentElements.resource.dataSource = [
                        { text: this.USER.firstName, value: this.USER.userId }
                    ];
                    this.page.elements.parentElements.resource.value = this.USER.userId;
                    setTimeout(() => {
                        this.loadedData = JSON.stringify(this.getData(this.data));
                    }, 100);
                    if (this.page && this.page.elements && this.page.elements.tabs) {
                        for(var tab of this.page.elements.tabs){
                            if(tab.tabType == 'attachments' || tab.tabType == 'history'){
                                break;   
                            }
                            this.lastTabId = tab.tabId
                        };
                    }
                }
                this.showLoader = false;
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
            });
    }

    onCommentUpdate(data: any) {
        this.data.comments = data.map(comment => {
            if (comment.isNew) {
                comment.commentId = 0;
            }
            return comment;
        });
        this.onNeedRefresh.emit();
    }

    onAttachmentsUpdate(data: any) {
        this.data.attachments = data;
    }

    getData(existingData: any) {
        let data: any = {
            company: this.USER.company,
            adjustmentOrderNumber: existingData.adjustmentOrderNumber || '',
            status: existingData.status || 'Open',
            manualAdjustment: existingData.manualAdjustment || 'yes',
            orderDate: existingData.id ? existingData.orderDate : new Date(),
            electronicOrderNumber: existingData.electronicOrderNumber || '',
            attachments: existingData.attachments || [],
            comments: existingData.comments || [],
            history: existingData.history || []
        };
        if ( existingData.id ) {
            data = {
                id: existingData.id, // this was done to keep id at top, due to some backend reservation raised by prithi for the moment
                ...data
            }
        }
        const elements = [];
        this.page.elements.tabs.forEach((tab: Tab) => {
            if ( tab.tabType === this.tabTypes.custom ) {
                tab.sections.forEach((section: Section) => {
                    section.elements.forEach((element: Element | GridElement) => {
                        data[element.elementKey] = element.elementType === GridTypes.grid ? element.dataSource : element.elementType === FieldTypes.radiobutton ? element.value || data[element.elementKey] : document.getElementById(element.elementKey) && document.getElementById(element.elementKey)['ej2_instances'] ? document.getElementById(element.elementKey)['ej2_instances'][0].value : element.value;
                        elements.push({
                            key: element.elementKey,
                            name: element.elementLabel,
                            value: data[element.elementKey]
                        });
                    });
                });
            }
        });
        // data.fields = elements;

        return data;
    }

    validate() {
        let isValid = true;
        this.layouts.forEach((layout: any) => {
            const isLayoutValid = layout.validate();
            isValid = isValid && isLayoutValid;
        });
        return isValid;
    }

    getCalibrationData(calibrationData) {
        let data = {
            reportTemplate: this.calibrationType,
            draft: false,
            approvedSignatory: [{ // Common
                codeNumber: this.serviceOrderData.technicianId || '',
                name: this.serviceOrderData.technicianName || '',
                dateOfIssue: formatDate(new Date(),"yyyy-MM-ddTHH:mm:ss",'en') ,
                certificateNumber: this.serviceOrderData.serviceOrderIdLN ? 'C'+this.serviceOrderData.serviceOrderIdLN : 'C'+this.serviceOrderData.serviceOrderId || '',
                recalibDueDate: calibrationData.calibrationDueDate ? formatDate(calibrationData.calibrationDueDate,"yyyy-MM-ddTHH:mm:ss",'en') : calibrationData.calibrationDueDate
            }],
            analyserDetails: [{ // Common
                manufacturer: calibrationData.manufacturer || 'Snap-on',
                model: calibrationData.model != null ? calibrationData.model : this.serviceOrderData.itemDescription.split(' - ')[1] || '',
                unitSerialNo: this.payloadData.serialNo || '',
                ProcedureNo: calibrationData.procedureValue || '',
                PEF: calibrationData.pefValue != null ? (calibrationData.pefValue).toFixed(3)+'' : 'N/A'
            }],
            locationDetail: [{ // Common
                customer: this.serviceOrderData.customerDescription.split(' - ')[1] || '',
                houseNumber: this.serviceOrderData.address || '',
                street: this.serviceOrderData.street || '',
                city: this.serviceOrderData.city || '',
                Country: this.serviceOrderData.country || '',
                state: this.serviceOrderData.state || '',
                zipCode: this.serviceOrderData.zipCode || '',
                VTSNumber: calibrationData.vtsNumber || '',
                ACNumber: this.serviceOrderData.customerCode || ''
            }]
        };

        if ( this.pageType === CalibrationTypes.gas ) {
            data['initialReadingGA'] = [{
                GasCarbonMonoxide: calibrationData.coValue.toFixed(3),
                GasHydrocarbons: (calibrationData.propaneValue).toFixed(0),
                GasCarbonDioxide: calibrationData.co2Value.toFixed(2),
                GasOxygen: 0+'',
                PEFHydrocarbons: (calibrationData.pefValue * calibrationData.propaneValue).toFixed(0),
                AnalyserCarbonMonoxide: calibrationData.noInitialReadingsCheckbox ? 'N/A' : (calibrationData.coValueInitialReading).toFixed(3),
                AnalyserHydrocarbons: calibrationData.noInitialReadingsCheckbox ? 'N/A' : (calibrationData.hcValueInitialReading).toFixed(0),
                AnalyserCarbonDioxide: calibrationData.noInitialReadingsCheckbox ? 'N/A' : (calibrationData.co2ValueInitialReading).toFixed(2),
                AnalyserOxygen: calibrationData.noInitialReadingsCheckbox ? 'N/A' : calibrationData.o2ValueInitialReading.toFixed(2)
            }],
            data['finalReadingGA'] = [{
                GasCarbonMonoxide: calibrationData.coValue.toFixed(3),
                GasHydrocarbons: (calibrationData.propaneValue).toFixed(0),
                GasCarbonDioxide: calibrationData.co2Value.toFixed(2),
                GasOxygen: 0+'',
                PEFHydrocarbons: (calibrationData.pefValue * calibrationData.propaneValue).toFixed(0),
                AnalyserCarbonMonoxide: (calibrationData.coValueFinalReading).toFixed(3) ,
                AnalyserHydrocarbons: (calibrationData.hcValueFinalReading).toFixed(0),
                AnalyserCarbonDioxide: (calibrationData.co2ValueFinalReading).toFixed(2),
                AnalyserOxygen: calibrationData.o2ValueFinalReading.toFixed(2)
            }],

            data['calibrationResultsGA'] = [{
                AmbTemperature: calibrationData.ambientTemprature || 0,
                AmbPressure: calibrationData.airPressure || 0,
                SpecificationNo:'' ,
                TestPerformed: calibrationData.facilityBasedCalibrationCheckbox || false,
                SpecificationDate: new Date(),
                CalibGasCertNo: calibrationData.referenceEquipmentCertificateNumber+'' || '',
                Remarks: calibrationData.initialReadingComments != null ? calibrationData.initialReadingComments+'' : '',
                InitCalibOxygenReading: calibrationData.oxygenReadingOfAmbient+'' || '',
                FinalCalibOxygenReading: (calibrationData.o2FinalReadingForAmbient).toFixed(2) || ''
            }];
        } else if ( this.pageType === CalibrationTypes.smoke ) {
            let smokeData = ['100','25','50','75'];
            data['initialReading'] = [];
            data['finalReading'] = [];
            smokeData.forEach((i, index, arr) => {
                data['initialReading'].push({
                    SNo: (index+1)+'',                    
                    Displayed: calibrationData[`field-101`] ? 'N/A' : calibrationData[`initial${i}d`].toFixed(2),
                    MeasuredVoltage: calibrationData[`field-101`] ? 'N/A' : calibrationData[`initial${i}v`].toFixed(3),
                    Calculated: calibrationData[`field-101`] ? 'N/A' : calibrationData[`initial${i}c`].toFixed(2)
                });
                data['finalReading'].push({
                    SNo: (index+1)+'',                    
                    Displayed: calibrationData[`final${i}d`].toFixed(2),
                    MeasuredVoltage: calibrationData[`final${i}v`].toFixed(3),
                    Calculated: calibrationData[`final${i}c`].toFixed(2)
                });
            });

            data['calibrationResults'] = [
                {
                    AmbTemperature: calibrationData.ambientTemprature+'',
                    // SimulatorRefValue: calibrationData.roti === 'Bluetooth' ? parseInt(calibrationData.wirelessTemperature || 0) : parseInt(calibrationData.standardTemperature || 0),
                    SimulatorRefValue: calibrationData.roti === 'Bluetooth' ? parseInt(calibrationData.wirelessTemperature || 0) :
                        calibrationData.roti === 'WOTI' ? parseInt(calibrationData.tempWirelessWOTI || 0) : parseInt(calibrationData.standardTemperature || 0),
                    SimulatorDisplayValue: parseInt(calibrationData.displayedOilTemperature || 0),
                    SpecificationDate: new Date(),
                    SpecificationNumber: '',
                    TestPerformed: calibrationData.facilityBasedCalibrationCheckbox || false,
                }
            ];
        } else if ( this.pageType === CalibrationTypes.liftLoad ) {
            data['TestLoadDetails'] = [{
                TestLoadApplied: calibrationData['field-131'] === '1' ? 'Vehicle used' : 'Weight used',
                CertifiedWeight: calibrationData['referenceWeight'] || '',
                CertificateNumber: calibrationData['certificateNumber'], // To be confirmed with Anas about this field
                CertificateDate: new Date()
            }];
        } else if ( this.pageType === CalibrationTypes.brakeTester ) {
            data['brakeTester'] = this.getBrakeTesterReadings(calibrationData);
            data['brakeTesterDetail'] = [{
                LoadApplied: calibrationData.axleWeighingBrakeTesterCheckbox  ? calibrationData.brakeCalAxleWeight + '' : 'N/A',
                MeasuredWeight: calibrationData.axelWeight != null ? calibrationData.axelWeight + '' : 'N/A',
                Error: calibrationData.axleWeighingBrakeTesterCheckbox ? this.calculateErrorPercent(calibrationData.brakeCalAxleWeight, calibrationData.axelWeight)+'' : 'N/A',
                AxleWieghing0: calibrationData['weightReferencePointReadings0kgf '] != null ? calibrationData['weightReferencePointReadings0kgf '] + '' : 'N/A',
                AxleWieghing50: calibrationData['weightReferencePointReadings50kgf'] != null ? calibrationData['weightReferencePointReadings50kgf'] + '' : 'N/A',
                AxleWieghing100: calibrationData['weightReferencePointReadings100kgf'] != null ? calibrationData['weightReferencePointReadings100kgf'] + '' : 'N/A',
                AxleWieghing200: calibrationData['weightReferencePointReadings200kgf'] != null ? calibrationData['weightReferencePointReadings200kgf'] + '' : 'N/A',
            }];
        } else if ( this.pageType === CalibrationTypes.levelStandingArea ) {
            data["testTypeDetails"] = [{
                classOfTest: calibrationData.class,
                standingAreaType: calibrationData.typesOfCalibration
              }
            ];
            data['hrRow1'] = [{}];
            data['hrRow2'] = [{}];
            for ( let col = 1; col <= 8; col++ ) {
                data['hrRow1'][0]['hr'+col] = calibrationData[`field-11${col+1}`]  != null ? calibrationData[`field-11${col+1}`].toFixed(2) : '';
                data['hrRow2'][0]['hr'+col] = calibrationData[`field-111${col+1}`]  != null ? calibrationData[`field-111${col+1}`].toFixed(2) : '';
                data['saCol'+col] = [{}];
                for ( let sa = 1; sa <= 15; sa ++ ) {
                    data['saCol'+col][0]['sa'+sa] = calibrationData[`field-11${sa+1}${col+1}`] != null ? calibrationData[`field-11${sa+1}${col+1}`].toFixed(2) : '';
                }
            }
        }
        return data;
    }

    getBrakeTesterReadings(calibrationData) {
        let data;
        switch(calibrationData.classOfBrakeTester) {
            case 'Class 1/2':
                data = this.getClass1By2Readings(calibrationData);
                break;
            case 'Class 4':
            case 'Class 4/7':
                data = this.getClass4or4By7Readings(calibrationData);
                break;
            case 'HGV/PSV':
                data = this.getHGVPSVReadings(calibrationData);
                break;
            default: break;
        }
        return data;
    }

    getClass1By2Readings(data) {
        // if ( data.xleWieghingBrakeTesterCheckbox ) { // 4.1.2
        const classInfo = data.otherApprovalCheckbox ? '(Class 1 & 2)' : data['otherApprovalClass4/7'] ? '(Class 4 or 7)' : '';
        return [
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '0', InitCalibNSGuage: data['0kgf-NearsideInitialReading']+'', InitCalibOSGuage: 'N/A', FinalCalibNSGuage: data['0kgf-NearsideFinalReading']+'', FinalCalibNSGuageError: this.calculateErrorPercent(0, data['0kgf-NearsideFinalReading'])+'', FinalCalibOSGuage: 'N/A', FinalCalibOSGuageError: 'N/A'},
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '50', InitCalibNSGuage: data['50kgf-NearsideInitialReading']+'', InitCalibOSGuage: 'N/A', FinalCalibNSGuage: data['50kgf-NearsideFinalReading']+'', FinalCalibNSGuageError: this.calculateErrorPercent(50, data['50kgf-NearsideFinalReading'])+'', FinalCalibOSGuage: 'N/A', FinalCalibOSGuageError: 'N/A'},
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '100', InitCalibNSGuage: data['100kgf-NearsideInitialReading']+'', InitCalibOSGuage: 'N/A', FinalCalibNSGuage: data['100kgf-NearsideFinalReading']+'', FinalCalibNSGuageError: this.calculateErrorPercent(100, data['100kgf-NearsideFinalReading'])+'', FinalCalibOSGuage: 'N/A', FinalCalibOSGuageError: 'N/A'},
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '200', InitCalibNSGuage: data['200kgf-NearsideInitialReading']+'', InitCalibOSGuage: 'N/A', FinalCalibNSGuage: data['200kgf-NearsideFinalReading']+'', FinalCalibNSGuageError: this.calculateErrorPercent(200, data['200kgf-NearsideFinalReading'])+'', FinalCalibOSGuage: 'N/A', FinalCalibOSGuageError: 'N/A'},
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '300', InitCalibNSGuage: data['300kgf-NearsideInitialReading']+'', InitCalibOSGuage: 'N/A', FinalCalibNSGuage: data['300kgf-NearsideFinalReading']+'', FinalCalibNSGuageError: this.calculateErrorPercent(300, data['300kgf-NearsideFinalReading'])+'', FinalCalibOSGuage: 'N/A', FinalCalibOSGuageError: 'N/A'},
        ];
        // } else { // 4.1.1

        // }
    }

    getClass4or4By7Readings(data) {
        const classInfo = data.otherApprovalCheckbox ? '(Class 1 & 2)' : data['otherApprovalClass4/7'] ? '(Class 4 or 7)' : '';
        const testMarkKGF = ['0','100','200','400','600'];
        if(data.otherApprovalCheckbox){
            testMarkKGF.splice(1, 0, '50');
            testMarkKGF.splice(4, 0, '300');
        }
        if ( data.maxBrakeForceReadings === '800 KGF' || data.classOfBrakeTester === 'Class 4/7') {
            testMarkKGF.push('800');
            data.classOfBrakeTester === 'Class 4/7' && testMarkKGF.push('1200');
        }
        const class4Readings = [];
        testMarkKGF.forEach(i => {
            class4Readings.push(
                {
                    ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`,
                    TestMarkKGF: i,
                    InitCalibNSGuage: data[`${i}kgf-NearsideInitialReading`] != null ? data[`${i}kgf-NearsideInitialReading`]+`` : `N/A`,
                    InitCalibOSGuage: data[`${i}kgf-OffsideInitialReading`] != null ? data[`${i}kgf-OffsideInitialReading`]+`` : `N/A`,
                    FinalCalibNSGuage: data[`${i}kgf-NearsideFinalReading`] != null ? data[`${i}kgf-NearsideFinalReading`]+`` : ``,
                    FinalCalibNSGuageError: (this.calculateErrorPercent(+i, data[`${i}kgf-NearsideFinalReading`])).toFixed(2),
                    FinalCalibOSGuage: data[`${i}kgf-OffsideFinalReading`] != null ? data[`${i}kgf-OffsideFinalReading`]+`` : ``,
                    FinalCalibOSGuageError: (this.calculateErrorPercent(+i, data[`${i}kgf-OffsideFinalReading`])).toFixed(2)
                }
            );
        });
        return class4Readings;
    }

    getHGVPSVReadings(data) {
        const classInfo = data.otherApprovalCheckbox ? '(Class 1 & 2)' : data['otherApprovalClass4/7'] ? '(Class 4 or 7)' : '';
        const calClass4 = 'class4';
        const calClassHGV = 'classHGV/PSV';
        const reading = [
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '0', InitCalibNSGuage: data[`0kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`0kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`0kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(0, data[`0kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`0kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(0, data[`0kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '100', InitCalibNSGuage: data[`100kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`100kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`100kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(100, data[`100kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`100kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(100, data[`100kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '200', InitCalibNSGuage: data[`200kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`200kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`200kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(200, data[`200kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`200kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(200, data[`200kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '400', InitCalibNSGuage: data[`400kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`400kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`400kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(400, data[`400kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`400kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(400, data[`400kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '600', InitCalibNSGuage: data[`600kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`600kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`600kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(600, data[`600kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`600kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(600, data[`600kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '1200', InitCalibNSGuage: data[`1200kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`1200kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`1200kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(1200, data[`1200kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`1200kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(1200, data[`1200kgf-OffsideFinalReading`]) + '' },
            { ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '2000', InitCalibNSGuage: data[`2000kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`2000kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`2000kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(2000, data[`2000kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`2000kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(2000, data[`2000kgf-OffsideFinalReading`]) + '' },

        ];
        if (data.maxBrakeForceReadings !== '4000 KGF') {
            reading.push({ ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '3000', InitCalibNSGuage: data[`3000kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`3000kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`3000kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(3000, data[`3000kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`3000kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(3000, data[`3000kgf-OffsideFinalReading`]) + '' });
        }
        else {
            reading.push({ ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '2500', InitCalibNSGuage: data[`2500kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`2500kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`2500kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(2500, data[`2500kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`2500kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(2500, data[`2500kgf-OffsideFinalReading`]) + '' });
            reading.push({ ClassOfCalibration: `${data.classOfBrakeTester} ${classInfo}`, TestMarkKGF: '4000', InitCalibNSGuage: data[`4000kgf-NearsideInitialReading`] + '', InitCalibOSGuage: data[`4000kgf-OffsideInitialReading`] + '', FinalCalibNSGuage: data[`4000kgf-NearsideFinalReading`] + '', FinalCalibNSGuageError: this.calculateErrorPercent(4000, data[`4000kgf-NearsideFinalReading`]) + '', FinalCalibOSGuage: data[`4000kgf-OffsideFinalReading`] + '', FinalCalibOSGuageError: this.calculateErrorPercent(4000, data[`4000kgf-OffsideFinalReading`]) + '' });
        }
        return reading;
    }

    calculateErrorPercent(kgf, reading) {
        let value = kgf ? Math.abs(kgf - reading) / kgf * 100 : reading
        if (value != null && !Number.isInteger(value)) {
            let numDecimalPlaces = value.toString().split('.')[1]?.length || 0; // Get the number of decimal places in the number
            if (numDecimalPlaces > 2) {
                value = value.toFixed(2);
            } 
        }
        return Number(value);
    }

    getApiUrl(data) {
        if ( this.calibrationType ) {
            return 'Calibration/GenerateCalibrationCertificate';
        } else {
            return data.id ? 'ShipmentOrder/UpdateAdjustmentOrder' : 'ShipmentOrder/CreateAdjustmentOrder'
        }
    }

    generateCertificate() {
        this.commonService.showConfirmation('Are you sure? This will generate the Calibration Certificate and clear the form.')
        .then( result => {
            if ( result.isConfirmed ) {
                this.save(true);
            }
        });
    }

    getRefEquipmentProperties(data, asset) {
        let assetNumber = data.assetNumber;
        let certificateNumber = data.certificateNumber;
        let certiExpiryDate = data.certificateExpiryDate;
        let tempWireless = data.wirelessTemperature;
        let isSelected = null;
        switch(asset) {
            case 'Thermometer':
                assetNumber = data.assetNumberThermometer;
                certificateNumber = data.certificateNumberThermometer;
                certiExpiryDate = data.certificateExpiryThermometer;
                break;
            case 'Multimeter':
                assetNumber = data.assetNumberMultimeter;
                certificateNumber = data.certificateNumberMultimeter;
                certiExpiryDate = data.certificateExpiryMultimeter;
                break;
            case 'WOTI Temp. Simulator':
                assetNumber = data.assetNumberWOTITempSim;
                certificateNumber = data.certificateNumberWOTITempSim;
                certiExpiryDate = data.certificateExpiryWOTITempSim;
                tempWireless = data.tempWirelessWOTI;
                break;
            case 'Scale':
                assetNumber = data.scalesSetAssetNumber;
                certificateNumber = data.scalesSetCertificateNumber;
                certiExpiryDate = data.scalesSetExpiryDate;
                isSelected = data.equipmentType === "Electronic" ? true : false;
                break;
            case 'Weights':
                assetNumber = data.weightsAssetNumber;
                certificateNumber = data.weightsCertificateNumber;
                certiExpiryDate = data.weightsExpiryDate;
                isSelected = data.equipmentType === "Mechanical" ? true : false;
                break;
            case 'Axle Weight':
                certificateNumber = data.axelCertificateNumber;
                certiExpiryDate = data.axelExpiryDate;
                break;
            case 'Flowmeter':
                assetNumber = data.assetNumberFlowmeter;
                certificateNumber = data.certificateNumberFlowmeter;
                certiExpiryDate = data.certificateExpiryFlowmeter;
                break;
            case 'Regulator':
                assetNumber = data.assetNumberRegulator;
                certificateNumber = data.certificateNumberRegulator;
                certiExpiryDate = data.certificateExpiryRegulator;
                break;
            case 'Barometer':
                assetNumber = data.assetNumberBarometer;
                certificateNumber = data.certificateNumberBarometer;
                certiExpiryDate = data.certificateExpiryBarometer;
                break;
            case 'Gas Bottle':
                certificateNumber = data.referenceEquipmentCertificateNumber;
                certiExpiryDate = data.referenceEquipmentCertificateExpiryDate;
                break;
            default:
                break;
        }
        certiExpiryDate = certiExpiryDate ? formatDate(certiExpiryDate,"yyyy-MM-ddTHH:mm:ss",'en') : certiExpiryDate;
        return [assetNumber, certificateNumber, certiExpiryDate, tempWireless, isSelected];
    }

    getRefEquipmentData(data) {
        let assets = [];
        const refEquipment = [];

        if ( this.pageType === CalibrationTypes.gas ) {
            assets = ['Gas Bottle', 'Flowmeter', 'Regulator', 'Barometer', 'Thermometer', 'Multimeter'];
        } else if ( this.pageType === CalibrationTypes.brakeTester ) {
            assets = ['Axle Weight', 'Scale', 'Weights', 'Brake Calibration Bar'];
        } else if (this.pageType === CalibrationTypes.smoke) {
            assets = ['WOTI Temp. Simulator', 'Smoke Temp. Simulator', 'Thermometer', 'Multimeter'];
        } else if ( this.pageType === CalibrationTypes.levelStandingArea ) {
            assets = ['Level Standing Area'];
        } else if ( this.pageType === CalibrationTypes.generic ) {
            assets = ['Generic'];
        } else if ( this.pageType === CalibrationTypes.liftLoad ) {
            assets = ['Lift Load'];
        } else if ( this.pageType === CalibrationTypes.beamSetter ) {
            assets = ['Beam Laser Level'];
        }

        assets.forEach(asset => {
            const [assetNumber, certificateNumber, certiExpiryDate, tempWireless, isSelected] = this.getRefEquipmentProperties(data, asset);
            refEquipment.push({
                id: this.data?.refEquipment?.id || 0,
                company: data.company,
                calibrationType: this.calibrationType,
                asset,
                assetNumber,
                certificateNumber,
                certiExpiryDate,
                isSelected,
                technicianId: this.serviceOrderData.technicianId,
                technicianName: this.serviceOrderData.technicianName,
                tempStd: data.standardTemperature ? data.standardTemperature+'' : '',
                tempWireless: tempWireless ? tempWireless+'' : '',
                refWeight: data.brakeCalAxleWeight ? data.brakeCalAxleWeight+'' : '',
                assetSerialNumber: data.referenceEquipmentSerialNumber ? data.referenceEquipmentSerialNumber+'' : '',
                coVal: data.coValue ? data.coValue+'' : '',
                co2Val: data.co2Value ? data.co2Value+'' : '',
                propaneVal: data.propaneValue ? data.propaneValue+'' : ''
            });
        });

        return refEquipment;
    }

    getEquipmentAndDatesData(data) {
        return {
            calibrationType: this.calibrationType,
            procedureNumber: data.procedureValue,
            vtsNumber: data.vtsNumber,
            calibrationExpiryDate: this.pageType === CalibrationTypes.motPlayDetector ? data['field-151'] : data.calibrationDueDate,
            lastCalibrationDate: this.pageType === CalibrationTypes.motPlayDetector ? data['field-141'] : data.previousCalibrationDate
        }
    }

    save(close: boolean = false, placeOrder: boolean = false) {
        if (this.validate()) {
            const data = this.getData(this.data);
            this.payloadData = data
            let calibration, refEquipment, equipmentAndDates;
            if ( this.calibrationType ) {
                calibration = this.getCalibrationData(data);
                refEquipment = this.getRefEquipmentData(data);
                equipmentAndDates = this.getEquipmentAndDatesData(data);
                if(calibration && calibration.brakeTester)
                console.table(calibration.brakeTester);
                if(calibration && calibration.brakeTesterDetail)
                console.table(calibration.brakeTesterDetail);
            }
            // console.log('data',data);
            // console.log('calibration data', calibration);
            // console.log('service order data', this.serviceOrderData);
            data.status = placeOrder ? 'Order Sent' : data.status;
            data.lastUpdatedDate = new Date();
            this.showLoader = true;
            const apiUrl = this.getApiUrl(data);
            this.commonService.callApi(apiUrl, 'post', this.calibrationType ? calibration : data)
            .subscribe((res: ApiResponse) => {
                if ( this.calibrationType ) {
                    res['calibrationType'] = this.calibrationType;
                    res['refEquipment'] = refEquipment;
                    res['calibSerialNumber'] = data.serialNo;
                    res['expiryDate'] = data.calibrationDueDate ? formatDate(new Date(data.calibrationDueDate),"yyyy-MM-ddTHH:mm:ss",'en') : null;
                    this.onSave.emit(res);
                    this.showLoader = false;
                    if (close) {
                        this.onClose.emit();
                    }
                } else {
                    if (res.isSuccessful) {
                        this.data.id = res && res.result ? res.result.id : null;
                        this.loadedData = JSON.stringify(this.getData(this.data));
                        this.onSave.emit();
                        if (close) {
                            this.onClose.emit();
                        }
                    } else {
                        this.commonService.showNotification('error', `${res.message || 'There was a problem saving the data, please try again.'} <br><br><strong>Error:</strong><span class="ml-2 text-danger">${res.apiException}</span>`, 'center', 6000);
                    }
                    this.showLoader = false;
                }
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
            });
        } else {
            if (this.page && this.page.elements && this.page.elements.tabs) {
                this.page.elements.tabs.map(tab => {
                  const invalidSections = tab.sections.filter(section => section.isValid === false);
                  if (invalidSections.length > 0) {
                    tab.isValid = false;
                  }
                  return tab;
                });
            }
            this.commonService.showNotification('error', 'Please provide all required fields to continue!');
        }
    }

    isChanged() {
        const newData = this.getData(this.data);
        // console.log('Loaded Data:')
        // console.log(this.loadedData);
        // console.log('----------------------------------------------------------------------------------');
        // console.log('New Data:');
        // console.log(JSON.stringify(newData));
        return this.loadedData !== JSON.stringify(newData);
    }

    reset() {
        if (this.isChanged()) {
            this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                .then(result => {
                    if (result.isConfirmed) {
                        this.resetPopup();
                    }
                });
        } else {
            this.commonService.showNotification('info', 'Nothing to reset');
        }
    }

    resetPopup() {
        this.fields = {};
        this.data = JSON.parse(this.loadedData);
        this.setFields(this.data.fields);

        setTimeout(() => {
            this.layouts.forEach((layout: any) => {
                layout.resetRules();
            });
        }, 100);
    }

    close() {
        if (!this.isChanged()) {
            this.onClose.emit();
        } else {
            this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                .then(result => {
                    if (result.isConfirmed) {
                        this.onClose.emit();
                    }
                });
        }
    }

    showSection(section: Section) {
        if (section && section.elements && section.elements.length) {
            let showSection = false;
            section.elements.forEach((element: Element) => {
                if (element.visible !== false) {
                    showSection = true;
                    return;
                }
            });
            return showSection;
        } else {
            return false;
        }
    }

}
