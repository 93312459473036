import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
    selector: 'app-confirmation-box',
    templateUrl: './confirmation-box.component.html',
    styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {
    
    @Input() target: string = 'body';
    public showCloseIcon: boolean = false;
    public animationSettings: Object = { effect: 'None' };
    public confirmWidth: string = '400px';
    public confirmHeader: string = 'Delete Multiple Items';
    public confirmDlgButtons: ButtonPropsModel[] = [{ 
        click: this.confirmDlgBtnClick.bind(this), 
        buttonModel: { content: 'Yes', isPrimary: true } 
    }, { 
        click: this.confirmDlgBtnClick.bind(this), 
        buttonModel: { content: 'No' } 
    }];

    @ViewChild('confirmDialog') public confirmDialog: DialogComponent;

    @Output() onDialogClose = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    onClose(args) {
        // console.log(args);
    }

    confirmDlgBtnClick(args) {
        // console.log(args);
        this.confirmDialog.hide();
    }

}
