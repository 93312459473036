import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { CommonService } from '../../services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { IscService } from 'src/app/modules/home/services/isc.service';
@Component({
  selector: 'app-iframe-popup',
  templateUrl: './iframe-popup.component.html',
  styleUrls: ['./iframe-popup.component.scss']
})
export class IframePopupComponent implements OnInit, OnDestroy {

    isExpanded: boolean = true;
    showLoader: boolean = true;
    showIframe: boolean = false;
    chasePaymentSuccessful: boolean = false;
    chasePaymentFailed: boolean = false;

    data: {};
    isLoading: boolean;
    transactionInfo: any;
    iframeUrl: any;
    USER: any;
    transactionAmount: any;
    transactionDate: Date;
    transactionID: string;

    @Input() serviceOrderId = null;
    @Input() serviceOrderData = null;
    @Input() isPurchaseOrder:boolean = false;
    @Output() ccPaymentCompleted = new EventEmitter();
    @Output() ccPaymentAuthcode = new EventEmitter();
    @Output() ccPaymentCancel = new EventEmitter();
    @Output() ccPaymentCancelForPurchaseOrder = new EventEmitter();
    transactionErrorMessage: any;
    transactionErrorCode: any;
    transactionErrorSuggestion: any = 'Please try again or use different payment method.';
    chasePaymentFailedForPurchaseOrder: boolean;


    constructor(
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private iscService: IscService
    ) {
        this.USER = commonService.USER;
        // Cancel Payment - Called when the customer clicks the cancel button.
        // The function closes the iFrame and indicates that the customer’s card was not charged.
        window['cancelCREPayment'] = () => {
            this.commonService.showNotification('warning', "You canceled payment process.");
            this.cancel();
        }

        // Called when the customer clicks the link for more information about CVV2 numbers.
        // The function displays a popup window, which describes what the CVV2 number is and where to find it on the card.
        window['whatCVV2'] = () => {
            this.commonService.showNotification('info', 'A card security code is a series of numbers that, in addition to the bank card number, is printed on a card.');
        }


        // Called when a transaction error occurs.
        window['creHandleErrors'] = (errorCode) => {
            let message = window['handleError'](errorCode, "|");
            this.transactionErrorMessage = message;
            this.transactionErrorCode = errorCode;
            this.showTransactionFailedScreen();
            return false;
        }


        // OPTIONAL Function
        // Optionally called for transaction errors when more detailed information is required for debugging.

        window['creHandleDetailErrors'] = (errorCode, gatewayCode, gatewayMessage) => {

            let message = window['handleError'](errorCode, "|");
            this.transactionErrorMessage = message;
            this.transactionErrorCode = errorCode;
            this.showTransactionFailedScreen();
            // this.transactionErrorMessage = message;
            // this.commonService.showNotification('error', "creHandleDetailErrors called!, errorCode :" + errorCode + ", gatewayCode:" + gatewayCode +", gatewayMessage:"+ gatewayMessage);
            // this.commonService.showNotification('error', message);
            // this.cancel();
            return false;
        }

        //Payment Completed - Called when a transaction is processed successfully.
        window['completeCREPayment'] = (transaction) => {
            this.showLoader = true;
            this.save(transaction);
            // try {
            // } catch( e ) {
            //     console.log("Error " + e.message);
            //     return false;
            // }
        }


    // Handle error for Iframe
    window['handleError'] = (stringToSplit, separator) => {

        //   console.log("handleError called " + stringToSplit);
        var arrErrors = stringToSplit.split(separator);
        //console.log('The original string is: "' + stringToSplit + '"');
        //console.log('The separator is: "' + separator + '"');
        //console.log('The array has ' + (arrayOfStrings.length) + ' elements: ' + arrayOfStrings);
        arrErrors = arrErrors.sort();
        //   console.log('After Sort elements: ' + arrErrors);
        var errorMessage = "";
        for(let ecCtr=0; ecCtr<arrErrors.length; ecCtr++){
            var errorcode = arrErrors[ecCtr];

            if( errorcode == '000' ){
                try {
                    errorMessage += "EC:000, Successful request.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '100' ){
                try {
                    errorMessage += "EC:100, Merchant Identifier left blank or inavlid. The transaction was not processed.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '110' ){
                try {
                    errorMessage += "EC:110, Session Identifier left blank or inavlid. The transaction was not processed.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '200' ){
                try {
                    errorMessage += "EC:200, Name required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '300' ){
                try {
                    errorMessage += "EC:300, Amount entered is invalid. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '310' ) {
                try {
                    errorMessage += "EC:310, Credit card number is required. \n";
                } catch( e ) {
                    alert( "Error \n" + e.message );
                }
            } else if( errorcode == '315' ){
                try {
                    errorMessage += "EC:315, Invalid credit card number. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '320' ){
                try {
                    errorMessage += "EC:320, Invalid credit card type. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '330' ){
                try {
                    errorMessage += "EC:330, Invalid credit card month. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '340' ){
                try {
                    errorMessage += "EC:340, Invalid credit card expiration year. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '350' ){
                try {
                    errorMessage += "EC:350, Invalid CVC. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '355' ){
                try {
                    errorMessage += "EC:355, CVC required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '357' ){
                try {
                    errorMessage += "EC:357, Invalid CVC. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '360' ){
                try {
                    errorMessage += "EC:360, Payment declined by bank, or some other error has occured.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '370' ){
                try {
                    errorMessage += "EC:370, Invalid credit card expiration date. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '400' ){
                try {
                    errorMessage += "EC:400, Transaction tracer value does not match. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '500' ){
                try {
                    errorMessage += "EC:500, Address required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '510' ){
                try {
                    errorMessage += "EC:510, City required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '520' ){
                try {
                    errorMessage += "EC:520, State required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '530' ){
                try {
                    errorMessage += "EC:530, Zip code required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '531' ){
                try {
                    errorMessage += "EC:531, Invalid Zip code. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '550' ){
                try {
                    errorMessage += "EC:550, Country required. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '516' ){
                try {
                    errorMessage += "EC:516, Merchant account is inactive or invalid. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '521' ){
                try {
                    errorMessage += "EC:521, Transaction data is not formated. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '839' ){
                try {
                    errorMessage += "EC:839, Account number is invalid. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '841' ){
                try {
                    errorMessage += "EC:841, Account number is invalid for merchant account. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '20412' ){
                try {
                    errorMessage += "EC:20412, Security information is not configured. \n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else if( errorcode == '9582' ){
                try {
                    errorMessage += "EC:9582, Cannot create profile - Profile ID already exist.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            } else {
                try {
                    errorMessage += "Something went wrong please try again or contact back office.\n";
                } catch( e ) {
                    alert( "Error " + e.message );
                }
            }

        }

        return errorMessage;
    }
}

    ngOnInit(): void {
        this.getEditValue();
    }

    getEditValue(){
        this.data = {
        "company": this.USER.company,
        "amount": this.serviceOrderData.invoiceAmount+"",
        "orderDesc":this.serviceOrderId,
        "currencyCode": this.serviceOrderData.customerCurrency,
        "customerEmail": this.serviceOrderData.customerEmail,
        "address": this.serviceOrderData.address,
        "address2":"",
        "city": this.serviceOrderData.city,
        "state": this.serviceOrderData.state,
        "zip":this.serviceOrderData.zipCode,
        "deliveryFirstName": "",
        "deliveryLastName": "",
        "deliveryAddress": this.serviceOrderData.address,
        "deliveryAddress2":"",
        "deliveryEmail": this.serviceOrderData.customerEmail,
        "deliveryPhone": this.serviceOrderData.customerPhoneNumber,
        "deliveryCity": this.serviceOrderData.city,
        "deliveryState": this.serviceOrderData.state,
        "deliveryPostalCode":this.serviceOrderData.zipCode,
        "billingName": ""
        }
        if(this.isPurchaseOrder){
            this.data['orderId'] = this.serviceOrderId;
          this.iscService.authorizeCCPayment(this.data).subscribe(
            (response: any) => {
                if(response.isSuccessful){
                this.transactionInfo = response.result;
                const selectedValue = environment.HPFInterface + "/?uID=" + this.transactionInfo.hdnHPFuID;
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedValue);
                this.isLoading = false;
                this.showIframe = true;
                this.showLoader = false;
                //   this.commonService.showNotification('success', response.message);
                } else {
                this.isLoading = false;
                this.commonService.showNotification('error', response.message);
                }
            },
            (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.commonService.showNotification('error', error.message);
            }
            )
        }
        else{
            this.data['serviceOrderId'] = this.serviceOrderId;
          this.serviceOrderService.authorizeCCPaymentForChase(this.data).subscribe(
            (response: any) => {
                if(response.isSuccessful){
                this.transactionInfo = response.result;
                const selectedValue = environment.HPFInterface + "/?uID=" + this.transactionInfo.hdnHPFuID;
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedValue);
                this.isLoading = false;
                this.showIframe = true;
                this.showLoader = false;
                //   this.commonService.showNotification('success', response.message);
                } else {
                this.isLoading = false;
                this.commonService.showNotification('error', response.message);
                }
            },
            (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.commonService.showNotification('error', error.message);
            }
            )
        }

    }

    save(transaction){
        // this.commonService.showNotification('success', 'Payment Completed');
        this.showLoader = true;
        this.transactionID = transaction.uID;
        this.transactionDate = new Date();
        this.transactionAmount = this.data['amount'];
        this.chasePaymentSuccessful = true;
        this.showIframe = false;
        this.cdr.detectChanges();
        let api = this.serviceOrderService.savePaymentResponse(transaction.uID, this.serviceOrderId);

        if(this.isPurchaseOrder){
         api = this.iscService.processPurchasePayment(transaction.uID, this.serviceOrderId);
        }
        api.subscribe( (response: any) => {
                if(response.result && response.result.creditauthorizationcode){
                    this.showLoader = false;
                    this.ccPaymentAuthcode.emit(response.result.creditauthorizationcode);
                } else {
                    this.showLoader = false;
                    // this.commonService.showNotification('error', response.message);
                    this.showLoader = false;
                }
            },
            (error: HttpErrorResponse) => {
                this.isLoading = false;
                this.showLoader = false;
                // this.commonService.showNotification('error', error.message);
            }
        )
    }

    showTransactionFailedScreen(){
        this.showIframe = false;
        this.chasePaymentSuccessful = false;
        if(this.isPurchaseOrder){
            this.chasePaymentFailedForPurchaseOrder = true;
            this.chasePaymentFailed = false;
        }
        else{
            this.chasePaymentFailedForPurchaseOrder = false;
            this.chasePaymentFailed = true;
        }
        this.cdr.detectChanges();
    }

    ccPaymentComplete(args){
        this.showIframe = false;
        this.chasePaymentSuccessful = false;
        this.chasePaymentFailed = false;
        this.chasePaymentFailedForPurchaseOrder = false;
        this.cdr.detectChanges();
        this.ccPaymentCompleted.emit(args);
    }

    retryPayment(){
        this.showLoader = true;
        this.getEditValue();
        this.chasePaymentSuccessful = false;
        this.chasePaymentFailed = false;
        this.chasePaymentFailedForPurchaseOrder = false;
        this.cdr.detectChanges();
    }

    failedAndClose(){
        this.chasePaymentSuccessful = false;
        this.chasePaymentFailed = false;
        this.chasePaymentFailedForPurchaseOrder = false;
        this.showIframe = false;
        this.ccPaymentCancel.emit(false);
    }

    failedAndCloseForPurchaseOrder(){
        this.chasePaymentSuccessful = false;
        this.chasePaymentFailed = false;
        this.chasePaymentFailedForPurchaseOrder = false;
        this.showIframe = false;
        this.ccPaymentCancelForPurchaseOrder.emit(false);
    }


    cancel(){
        this.ccPaymentCancel.emit(true);
    }

    ngOnDestroy(): void {
    }

}
