import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentAdjustmentOrder } from '../../models/shipment-adjustment-order.model';
import { ModuleNames } from '../../models/app.model';

@Component({
  selector: 'app-shipment-adjustment-order-popup',
  templateUrl: './shipment-adjustment-order-popup.component.html',
  styleUrls: ['./shipment-adjustment-order-popup.component.scss']
})
export class ShipmentAdjustmentOrderPopupComponent implements OnInit {

  @Input() tab: string = 'details';
  @Input() data: ShipmentAdjustmentOrder;
  @Input() orderId: string;
  @Input() showComments: boolean = false;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();

  isExpanded: boolean = false;
  moduleName = ModuleNames.shipmentAdjustmentOrder;
  loadedData: string = '';

  constructor() { }

  ngOnInit(): void {
    this.loadedData = JSON.stringify(this.data);
  }

  onAttachmentsUpdate(eventArgs){
      const loadedData = JSON.parse(this.loadedData);
      loadedData.attachments = eventArgs;
      this.loadedData = JSON.stringify(loadedData);
      this.data.attachments = eventArgs;
      this.data.attachmentCount = eventArgs.filter(attachment => !attachment.isDeleted).length;
  }

  onCommentUpdate(eventArgs){
    console.log(eventArgs);
    const loadedData = JSON.parse(this.loadedData);
    loadedData.comments = eventArgs;
    this.loadedData = JSON.stringify(loadedData);
    this.data.comments = eventArgs;
    this.data.commentCount = eventArgs.filter(comment => !comment.isDeleted).length;
  }

  close(){
    this.onClose.emit();
  }

  save(hasToClose){
    this.onSave.emit(hasToClose);
  }
}
