import { Component, OnInit, Input, Output, ViewChild, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ComboBox, DropDownList } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiResponse, gridNames, GridPersistRequests, PersistenceData, UserRoles } from 'src/app/shared/models/app.model';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';

@Component({
  selector: 'app-wrong-parts-list',
  templateUrl: './wrong-parts-list.component.html',
  styleUrls: ['./wrong-parts-list.component.scss']
})
export class WrongPartsListComponent implements OnInit {

    showLoader: boolean = false;
    isExpanded: boolean = false;
    showColumnChooser: boolean = false;
    skipBeginEditEvent: boolean = false;

    isQuantityValid: boolean = true;

    itemsField: any;
    itemsObject: any;

    itemDescription: any;
    itemDescriptionObject: TextBox;

    itemsList = [];

    onItemUpdate = new Subject<string>();

    columns: any = [];
    wrongItemsGridList: any = [];
    gridToolbar: any;

    @Input() shipmentOrderId : string;
    @Input() quantity : number;
    @Input() wrongItemsList : {};

    @Output() onClose = new EventEmitter();

    @ViewChild('wrongPartsGrid') wrongPartsGrid: GridComponent;
    @ViewChild('itemsGridColumnChooser') columnChooser: any;
    selectedRowId: string;
    showItemAdvancedSearchPopup: boolean = false;
    serviceItemGroup: any;
    itemGroup: any;
    itemGroupObject: any;
    gridButtonClass: any = '';
    quantityLimit: number;

    itemQuantity: any;
    itemQuantityField: any;
    itemQuantityObject: any;
    totalQuantity: number = 0;
    USER: any;

    constructor(
      private commonService: CommonService,
      private serviceOrderService: ServiceOrderService,
      private eRef: ElementRef
    ) { 
        this.USER = this.commonService.USER;
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (this.wrongPartsGrid.isEdit) {
            if (!document.getElementById('shipmentOrderWrongShippedItemsGrid')?.contains(event.target)
            && !document.getElementById('shipmentOrderWrongShippedItemsGriditemCode_popup')?.contains(event.target) ) {
                this.wrongPartsGrid.endEdit();
            }
        }
    }

  ngOnInit(): void {
    this.quantityLimit = this.quantity;
    this.wrongItemsGridList = this.wrongItemsList['wrongPartsList'];
    this.columns = this.getGridColumns();
    this.gridToolbar = this.getGridToolbar();
    this.getItemList();
    this.onItemUpdate
            .pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).subscribe(value => {
                if (this.itemsObject) {
                    this.itemsObject.showPopup();
                    this.itemsObject.showSpinner();
                }
                this.getItemList(value);
            });
  }

  getItemList(value: string = null) {
    this.showLoader = true;
    this.commonService.getItems(value, 30, this.USER.company)
        .subscribe((res: ApiResponse) => {
            if (res && res.result && res.result.length) {
                res.result.map(item => {
                    item.value = item.item;
                    item.text = `${item.item} | ${item.itemDescription}`;
                });

                const existingItems = [];
                (this.wrongPartsGrid?.dataSource as any)?.forEach(transaction => {
                    if (transaction.itemCode !== this.itemsObject?.value) {
                        existingItems.push(transaction.itemCode);
                    }
                });
                this.itemsList = res.result.filter(item => !existingItems.includes(item.value));
                if (this.itemsObject) {
                    this.itemsObject.hideSpinner();
                    this.itemsObject.dataSource = this.itemsList;
                }
                this.showLoader = false;
            } else {
                this.itemsList = [];
                if (this.itemsObject) {
                    this.itemsObject.hideSpinner();
                    this.itemsObject.dataSource = [];
                }
                this.showLoader = false;
            }
        });
}

  getGridColumns() {
    return [
        { field: 'rowId', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, visible: false, isPrimaryKey: true },
        { field: 'srNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100 },
        { field: 'itemCode', headerText: 'Item',  type: 'string', textAlign: 'Left', width: 142, edit: this.initItemsDDL(), validationRules: { required: true }, allowEditing: true },
        { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left', width: 142, edit: this.editItemDescription() },
        { field: 'itemGroup', headerText: 'Item Group', type: 'string', textAlign: 'Left', width: 142, edit: this.editItemGroup() },
        { field: 'quantity', headerText: 'Received Quantity', type: 'number', format: 'n', min: 0,  textAlign: 'right', width: 142, edit: this.editItemQuantity(), validationRules: { required: true }, allowEditing: true },
        {
            field: 'Actions', headerText: 'Actions', textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
            commands: [{ title: 'Delete', type: 'delete', buttonOption: { iconCss: `fas fa-trash delete-button`, cssClass: `e-flat delete-button` } }]
        }
    ];
  }

  getGridToolbar(){
    const items =  [
      { text: '', id: 'add-item', align: 'Left', prefixIcon: 'e-add', cssClass: `grid-add-button ${this.gridButtonClass}`, tooltipText: 'Add Item' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = items.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      items.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    return items
  }

    onGridCreated(args: any) {
        if(this.quantity){
            this.wrongItemsGridList.forEach(element => {
                this.quantity = this.quantity - element.quantity;
                element.rowId = element.srNumber - 1;
            });
            this.gridButtonClass = this.quantity > 0 ? '' : 'disabled-grid-button';
        }
    }

    toolbarClick(args: ClickEventArgs) {
        if (args.item.id === 'excel-export') {
            const dataSource = getExcelDataSource(this.wrongPartsGrid);
            let excelExportProperties: ExcelExportProperties = {
                dataSource,
                hierarchyExportMode: 'Expanded',
                theme: {
                    header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
                },
                fileName: `Exceptions (${getCurrentDate()}).xlsx`
            };
            this.wrongPartsGrid.excelExport(excelExportProperties);
        } else if (args.item.id === 'pdf-export') {
            this.wrongPartsGrid.pdfExport();
        } else if (args.item.id === 'clear-filter') {
            this.wrongPartsGrid.clearFiltering();
            this.wrongPartsGrid.search('');
        } else if (args.item.id === 'column-chooser') {
            // this.showColumnChooser = !this.showColumnChooser;
        } else if (args.item.id === 'add-item') {
            this.wrongPartsGrid.endEdit();
            if (!this.wrongPartsGrid.isEdit) {
                if(this.quantity > 0){
                    this.addItem();
                } else {
                    this.commonService.showNotification('warning', 'Already added '+ this.quantityLimit + ' Items.');
                }
            } else {
                this.commonService.showNotification('warning', 'There is a row in wrong parts grid, which is already in edit mode!');
            }
            
        }
    }

    onCommandClick(args: any) {
        if (args.commandColumn.type === 'delete') {
            this.showLoader = true;
            const index = (this.wrongPartsGrid.dataSource as any).findIndex(row => row.rowId === args.rowData.rowId);
            (this.wrongPartsGrid.dataSource as any).splice(index, 1);
            let count = 0;
            (this.wrongPartsGrid.dataSource as any).map(item => {
                item.rowId = count++;
                item.srNumber = count;
            });
            this.wrongPartsGrid.refresh();
            this.showLoader = false;
        }
    }

    actionBegin(args: any) {
        if (args.requestType === 'beginEdit' ) {
            if(args.rowData?.itemCode){
                this.getItemList(args.rowData?.itemCode);
            } else {
                this.getItemList();
            }
            if(this.quantity < 0){
                args.cancel = true;
            }
        }
    }

    actionComplete(args: any) {
        if (args.requestType === 'save') {
            this.wrongPartsGrid.endEdit();
            this.quantity = 0;
            if(this.wrongPartsGrid.dataSource){
                (this.wrongPartsGrid.dataSource as any).forEach(gridRow => {
                    this.quantity = this.quantity + +gridRow.quantity; 
                });
            }
            if(this.wrongPartsGrid.dataSource){
                this.isQuantityValid = false;
            }
        } else if (args.requestType === 'cancel') {
            if (!args.rowData.quantity) {
                const index = (this.wrongPartsGrid.dataSource as any).findIndex(row => row.rowId === args.rowData.rowId);
                (this.wrongPartsGrid.dataSource as any).splice(index, 1);
                this.wrongPartsGrid.refresh();
            }
        }
        updateFilterIcon({requestType: 'filtering'}, this.wrongPartsGrid);
    }

  addItem(){
    let newIndex = this.wrongPartsGrid.dataSource ? (this.wrongPartsGrid.dataSource as any).length : 0;
    let newRow = this.setItemData({}, newIndex);
    this.wrongItemsGridList.push(newRow);
    this.wrongPartsGrid.refresh();
    setTimeout(() => {
        this.wrongPartsGrid.selectRow(newIndex);
        this.wrongPartsGrid.startEdit();
    }, 500);
  }

  setItemData(item?, index?) {
    item.rowId = index;
    item.srNumber = ''+(index + 1);
    item.itemCode = '';
    item.itemGroup = '';
    item.itemDescription = '';
    item.quantity = null;

    return item;
  }

    initItemsDDL() {
        return {
            create: (args) => {
                this.selectedRowId = args.data.rowId;
                this.itemsField = document.createElement('input');
                return this.itemsField;
            },
            read: () => {
                if (this.itemsObject) {
                    return this.itemsObject.value;
                }
            },
            destroy: () => {
                this.itemsObject.destroy();
            },
            write: (argsData) => {
                this.itemsObject = new DropDownList({
                    dataSource: this.itemsList,
                    fields: { value: 'value', text: 'text' },
                    value: argsData.rowData.itemCode,
                    cssClass: 'generic-items-dropdown-list',
                    allowFiltering: true,
                    showClearButton: true,
                    popupWidth: '350px',
                    filterType: 'Contains',
                    filtering: args => { this.onItemUpdate.next(args.text); },
                    focus: args => { this.itemsObject.showPopup() },
                    blur: args => {
                        if(this.itemsObject['isSelectCustom']){
                            this.itemsObject.value = null;
                        }
                    },
                    change: (event: any) => {
                        if ( event.value && event.item ) {
                            this.itemDescriptionObject.value = event.itemData?.itemDescription ? event.itemData.itemDescription : null;
                            this.itemGroupObject.value = event.itemData?.serviceItemGroup;
                            this.itemGroup = event.itemData?.itemGroup;
                        }
                    }
                });
                this.itemsObject.appendTo(this.itemsField);
                this.addAdvancedSearchIcon();
            }
        }
    }

  addAdvancedSearchIcon() {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Advanced Search');
    itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.itemsField.parentElement.parentElement.append(itemAnchor);
  }

  showItemPopup() {
    this.showItemAdvancedSearchPopup = true;
  }

  onItemAdvancedSearchPopupClose(data: any) {
    const rowIndex = this.wrongPartsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
    this.serviceOrderService.popupLoader.next(true);
    this.showItemAdvancedSearchPopup = false;
    setTimeout(() => {
        this.wrongPartsGrid.selectRow(rowIndex);
        this.wrongPartsGrid.startEdit();
        this.skipBeginEditEvent = true;
        setTimeout(() => {
            this.serviceOrderService.popupLoader.next(false);
            this.itemDescriptionObject.value = data.itemDescription;
            this.itemGroupObject.value =  data.itemGroup;
            this.itemsObject.dataSource = [{
                text: `${data.item} | ${data.itemDescription}`,
                value: data.item,
                ...data
            }];
            this.itemsObject.value = data.item;
        }, 400);
    }, 2000);
  }

    editItemDescription() {
        return {
            create: () => {
                this.itemDescription = document.createElement('input');
                return this.itemDescription;
            },
            read: () => {
                if (this.itemDescriptionObject) {
                    return this.itemDescriptionObject.value;
                }
            },
            destroy: () => {
                this.itemDescriptionObject.destroy();
            },
            write: (args) => {
                this.itemDescriptionObject = new TextBox({
                    value: args.rowData.itemDescription,
                    enabled: false
                });
                this.itemDescriptionObject.appendTo(this.itemDescription);
            }
        }
    }

    editItemGroup() {
        return {
            create: () => {
                this.itemGroup = document.createElement('input');
                return this.itemGroup;
            },
            read: () => {
                if (this.itemGroupObject) {
                    return this.itemGroupObject.value;
                }
            },
            destroy: () => {
                this.itemGroupObject.destroy();
            },
            write: (args) => {
                this.itemGroupObject = new TextBox({
                    value: args.rowData.itemGroup,
                    enabled: false
                });
                this.itemGroupObject.appendTo(this.itemGroup);
            }
        }
    }

    editItemQuantity() {
        return {
            create: () => {
                this.itemQuantityField = document.createElement('input');
                return this.itemQuantityField;
            },
            read: () => {
                if (this.itemQuantityObject) {
                    return this.itemQuantityObject.value;
                }
            },
            destroy: () => {
                this.itemQuantityObject.destroy();
            },
            write: (args) => {
                this.itemQuantityObject = new NumericTextBox({
                    value: args.rowData.quantity,
                    min: 0,
                    created: args => {
                        this.itemQuantityField.onkeyup = event => {
                            this.itemQuantityObject.value = +event.target.value;
                            
                        }
                    }
                });
                this.itemQuantityObject.appendTo(this.itemQuantityField);
            }
        }
    }

    save(){
        this.wrongPartsGrid.endEdit();
        this.totalQuantity = 0;
        (this.wrongPartsGrid.dataSource as any).forEach(wrongPart => {
            this.totalQuantity += +wrongPart.quantity;
            wrongPart.srNumber = ''+wrongPart.srNumber;
            wrongPart.quantity = ''+wrongPart.quantity;
        });
        if(this.totalQuantity !== this.quantityLimit){
            this.commonService.showConfirmation("There is a mismatch in the quantity entered for wrong parts and the total wrong parts you have entered here. Proceeding will update the quantity you have entered for the wrong parts." )
            .then( result => {
                if ( result.isConfirmed ) {
                    this.onClose.emit({ data: this.wrongPartsGrid.dataSource, totalQuantity: this.totalQuantity, justClose: false });
                }
            });
        } else {
            this.onClose.emit({ data: this.wrongPartsGrid.dataSource, totalQuantity: this.totalQuantity, justClose: false });
        }
    }

    close(){
        this.onClose.emit({ data: this.wrongPartsGrid.dataSource, totalQuantity: this.totalQuantity, justClose: true });
        this.wrongItemsGridList = [];
        this.isQuantityValid = true;
    }
}
