<div class="modal-header">
    <div class="d-flex w-100">
        <div class="mr-2 id flex-fill">
            <h4 class="modal-title mb-0 item-id">{{ title }}</h4>
        </div>

        <div class="created-date pr-4" *ngIf="createdDate && !hideCreationDate">
            <span *ngIf="createdDate">Creation Date & Time: {{createdDate | date: 'MMM d, y hh:mm a'}}</span>
        </div>
        <div class="btns-header">
            <button
                class="header-icon close p-0 m-0"
                (click)="close()"
                aria-label="Close"
            >
                <i
                    class="fas fa-times"
                    title="Close Popup"
                ></i>
            </button>
            <button
                class="close header-icon expand-modal p-0 m-0"
                (click)="expandCollapse()"
            >
                <i
                    class="fas ml-2 mr-2"
                    [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                    [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                ></i>
            </button>
        </div>
    </div>
</div>
