import { ComboBoxComponent, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ComboBox } from '@syncfusion/ej2-angular-dropdowns';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import {
  CallGroups,
  GeneralSectionData,
  serviceOrderStatuses,
} from '../../../models/service-order.model';
import { EquipmentInformationSectionService } from '../../../services/equipment-information-section.service';
import { GeneralSectionService } from '../../../services/general-section.service';
import { ServiceOrderService } from '../../../services/service-order.service';
import { SoDetailsTabService } from '../../../services/so-details-tab.service';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';

@Component({
  selector: 'app-isc-so-details-tab',
  templateUrl: './isc-so-details-tab.component.html',
  styleUrls: ['./isc-so-details-tab.component.scss']
})
export class IscSoDetailsTabComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<null> = new Subject();

  @ViewChild('serialNumberSearch') serialNumberDropdown: DropDownListComponent;
  @ViewChild('objectsDDL') objectsDDL: DropDownListComponent;
  @ViewChild('configurationsDDL') configurationsDDL: DropDownListComponent;
  @ViewChild('callgroupCombo') callgroupCombo: ComboBox;
  @ViewChild('reasonsDDL') reasonsDDL;

  @Input() eqData = null;
  @Input() isEditable: boolean = true;
  @Input() fieldRules: any;
  // @Input() equipmentServiceDepartment = null;
  @Input() serviceDepartment = null;
  @Input() paymentButtonClicked = false;

  isSerialNumberEnabled: boolean = false;
  isModelEnabled: boolean = false;

  showAdvancedSearch: boolean = false;
  fromCustomerAdvancedSearch: boolean = false;
  validationApplied: boolean = false;
  skipValidationOnSerialForKL: boolean = false;
  showAdvancedSearchForSerialNumbers: boolean = false;
  fromSerialNumberAdvanceSearch: boolean = false;
  fromSerailNumberDDLChange: boolean = false;
  today = new Date();
  customerAddress: string = '';
  serviceDepartments = [];
  configurations: any = [];
  priorities = [];
  objects = [];
  signals = [];
  serialNumbers = [];
  callgroups = [];
  serviceOrderStatuses = serviceOrderStatuses;

  @Input() id: string = null;
  @Input() gsData: GeneralSectionData = null;
  @Input() notesData: any = null;
  @Input() hasAssignments: boolean = false;
  @Input() hasCheckedInServiceOrder: boolean = false;
  @Input() status: string = serviceOrderStatuses.open;
  @Input() reasonsForCancellation: any = [];
  @Output() statusChanged: EventEmitter<any> = new EventEmitter();
  @Output() distanceZoneChanged: EventEmitter<any> = new EventEmitter();
  @Output() configurationChanged: EventEmitter<any> = new EventEmitter();
  @Output() checkedIn: EventEmitter<any> = new EventEmitter();
  @Output() checkedOut: EventEmitter<any> = new EventEmitter();
  hasTransaction: boolean = false;
  canCheckin: boolean = false;
  reasonsForPause: any = [];
  model: any;
  serialNumber: any;
  formatedElapsedTime: any;
  USER: any;
  userRoles;
  isTechnician: boolean = false;
  isCsa: boolean = false;
  isIsc: boolean = false;
  isIscManager: boolean = false;
  isIscSupervisor: boolean = false;

  warrantyInfo: any;
  contractInfo: any;

  selectedModelNumber: string = null;
  selectedSerialNumber: string = null;

  isKingslynn: boolean = true;
  serialNumberReadOnly: boolean = false;
  itemCodeReadOnly: boolean = false;
  referenceReadOnly: boolean = false;
  priorityReadOnly: boolean = false;
  callGroupReadOnly: boolean = false;
  startDateReadOnly: boolean = false;
  customerComplaintReadOnly: boolean = false;
  fstCommentsToCustomerReadOnly: boolean = false;
  checkInDateReadOnly: boolean = false;
  checkOutDateReadOnly: boolean = false;
  customerCodeReadOnly: boolean = false;
  reasonCodeReadOnly: boolean = false;
  refrenceInputLengthValidation: boolean = true;

  constructor(
    private serviceOrderService: ServiceOrderService,
    private generalSectionService: GeneralSectionService,
    private commonService: CommonService,
    private soDetailsTabService: SoDetailsTabService
  ) {
    this.USER = commonService.USER;
    this.isIsc = commonService.USER.role === UserRoles.isc;
    this.isIscManager = commonService.USER.role === UserRoles.iscManager;
    this.isIscSupervisor = commonService.USER.role === UserRoles.IscSupervisor;
    this.isTechnician = this.USER.role === UserRoles.fst;
    this.serialNumberReadOnly = this.commonService.roleClaims['SO_Popup_Field_serialNumber']?.readOnly;
    this.itemCodeReadOnly = this.commonService.roleClaims['SO_Popup_Fields_itemCode']?.readOnly;
    this.referenceReadOnly = this.commonService.roleClaims['SO_Popup_Fields_reference']?.readOnly;
    this.priorityReadOnly = this.commonService.roleClaims['SO_Popup_Fields_priority']?.readOnly;
    this.callGroupReadOnly = this.commonService.roleClaims['SO_Popup_Fields_callGroup']?.readOnly;
    this.startDateReadOnly = this.commonService.roleClaims['SO_Popup_Fields_startDate']?.readOnly;
    this.customerComplaintReadOnly = this.commonService.roleClaims['SO_Popup_Field_customerComplaint']?.readOnly;
    this.fstCommentsToCustomerReadOnly = this.commonService.roleClaims['SO_Popup_Field_fstCommentsToCustomer']?.readOnly;
    this.checkInDateReadOnly = this.commonService.roleClaims['SO_Popup_Fields_checkInDate']?.readOnly;
    this.checkOutDateReadOnly = this.commonService.roleClaims['SO_Popup_Fields_checkOutDate']?.readOnly;
    this.reasonCodeReadOnly = this.commonService.roleClaims['SO_Popup_Fields_reasonCode']?.readOnly;
    this.customerCodeReadOnly = this.commonService.roleClaims['SO_Popup_Fields_customerCode']?.readOnly;
  }

  ngOnChanges(change: SimpleChanges) {
      if (change && change.gsData) {
        this.gsData = change.gsData.currentValue;
        const timeAgoPipe = new TimeAgoPipe();
        this.formatedElapsedTime = timeAgoPipe.transform(this.gsData?.elapsedTime);
      }

      if (change && change.id) {
          if(!change.id.previousValue && change.id.currentValue){
              this.canCheckin = this.canUserCheckin();
          }
      }

      if (change && change.status) {
          this.status = change.status.currentValue;
          this.canCheckin = this.canUserCheckin();
          if (change.status.previousValue !== undefined && change.status.previousValue !== change.status.currentValue) {
              if (change.status.currentValue === serviceOrderStatuses.inProgress) {
                  this.gsData.checkInDate = this.gsData.startDate = new Date();
                  this.gsData.checkOutDate = null;
              } else if (change.status.currentValue === serviceOrderStatuses.enroute) {
                  this.gsData.startDate = new Date();
              } else if (change.status.currentValue === serviceOrderStatuses.scheduled) {
                  this.gsData.checkInDate = null;
                  this.gsData.checkOutDate = null;
              } else if (change.status.currentValue === serviceOrderStatuses.paused || change.status.currentValue === serviceOrderStatuses.awaitingPayment) {
                  this.gsData.startDate = null;
                  if(change.status.previousValue !== serviceOrderStatuses.open){
                    this.gsData.checkOutDate = new Date();
                  }
              } else if (change.status.currentValue === serviceOrderStatuses.open) {
                  this.commonService.showConfirmation('Time details will be reset do you want to proceed?')
                      .then(result => {
                          if (result.isConfirmed) {
                              this.gsData.checkInDate = this.gsData.checkOutDate = this.gsData.startDate = null;
                          } else {
                              this.changeStatus(serviceOrderStatuses[change.status.previousValue.toLowerCase()]);
                          }
                      });
              } else if (change.status.currentValue === serviceOrderStatuses.pendingApproval) {
                  this.gsData.startDate = null;
                  this.gsData.checkOutDate = new Date();
              } else if (change.status.currentValue === serviceOrderStatuses.completed) {
                  this.gsData.checkOutDate = new Date();
              } else if (change.status.currentValue === serviceOrderStatuses.cancelled) {
                  this.gsData.startDate = null;
                  this.gsData.checkOutDate = new Date();
              }
          }
      }
  }

  ngAfterViewInit(): void {
    if (this.gsData && this.gsData.configuration) {
      this.updateConfigurationDDL(
          this.gsData.configuration,
          this.gsData.customerDescription
      );
    }

    if (this.eqData && this.eqData.itemCode) {
      this.updateModelDDL(
          this.eqData.itemCode,
          this.eqData.itemDescription
      );
    }
  }

  getDropdownListsForCreate() {
    this.loadCommonFields();
    const apis = [
      this.serviceOrderService.getIscConfigurations(this.serviceDepartment, this.USER.company),
    ];
    forkJoin(apis).subscribe((res: any) => {
      res[0]?.result && this.loadConfigurations(res[0]);
      // Load Serial number API will called onSerialNumberCreated event
    });
  }

  ngOnInit(): void {
    this.userRoles = UserRoles;
    this.USER = this.commonService.USER;
    this.isKingslynn = this.USER.company === Companies.kingslynn;

    this.setModelEnabled();
    this.setSerialNumberEnabled();
    this.skipValidationOnSerialForKL = this.isKingslynn && this.gsData?.callGroup === CallGroups.initialCalibration;
    this.setSubscriptions(); // For hasTransactions, Customer and Serial DDL Filtering

    if (!this.id) {
      this.getDropdownListsForCreate();
    } else {
      this.initailizeGeneralSectionData();
      this.setEquipmentPageData();
      this.loadCommonFields();
      // Load Serial number API will called onSerialNumberCreated event
      // We are populating Configuration & Model DDL in ngAfterViewInit() method
      this.setNotesData();
    }
    
    const timeAgoPipe = new TimeAgoPipe();
    this.formatedElapsedTime = timeAgoPipe.transform(this.gsData?.elapsedTime);
  }

  /**
   * We are setting all the subscriptions for the details tab
   * hasTransaction: its value will be updated if we change anything from the Second grid in the execution tab
   */
  setSubscriptions() {
    this.serviceOrderService.hasTransaction.pipe(
      takeUntil(this.destroy$),
    ).subscribe(hasTransaction => {
        this.hasTransaction = hasTransaction;
    });

    this.serviceOrderService.getSearchCustomerFiltering().pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(value => {
        if ( !value ) {
          this.gsData.customerDescription = null;
          this.getIscConfigurations('', true);
        }
        this.getIscConfigurations(value);
      });

    this.serviceOrderService.getSrNumberFiltering().pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(filteredString => {
        this.getSerialNumbersDDL(filteredString);
      });
  }

  setEquipmentPageData() {
    if (!this.eqData) {
        // this.eqData = {
        //     configuration: null,
        //     itemCode: null,
        //     itemDescription: null,
        //     serializedItemGroupCode: null,
        //     serializedItemGroupDescription: null,
        //     serialNumber: null,
        //     category: null,
        //     installationDate: null,
        //     warrantyCode: null,
        //     warrantyDescription: null,
        //     warrantyDescriptionObj: [],
        //     serviceContractCode: null,
        //     serviceContractDescription: null,
        //     serviceContractDescriptionObj: [],
        //     calibrationNextCertificateDate: null,
        //     warrantyCoverageLines: null,
        //     contractCoverageLines: null
        // }
    } else {
        this.selectedModelNumber = this.eqData.itemCode;
        this.selectedSerialNumber = this.eqData.serialNumber;
        this.getWarrantyAndContract();
        if (!this.selectedSerialNumber) {
            this.warrantyInfo = null;
            this.contractInfo = null;
        }
    }
  }

  validateEquipmentSectionData() {
      this.validationApplied = true;
      if (this.eqData.itemCode && (this.eqData.serialNumber || this.skipValidationOnSerialForKL)) {
          return true;
      } else {
          return false;
      }
  }

  onObjectChange(args: any) {
    // console.log('Model changed');
    if ( args.isInteracted ) {
        const model = args.value;
        const modelDescription = args.itemData.description;
        this.setModelSerialValues(model, modelDescription, '');
        this.eqData.serializedItemGroupCode = args.itemData.serializedItemGroupCode;
        this.eqData.serializedItemGroupDescription = args.itemData.serializedItemGroupDescription;

      this.getSerialNumbersDDL(null);
    } else if ( this.fromCustomerAdvancedSearch ) {
      if ( !args.value ) {
        this.resetModelRelatedFieldsForNull();
      }
    } else if ( this.fromSerialNumberAdvanceSearch ) {
      if ( !args.value ) {
        this.resetModelRelatedFieldsForNull();
      } else {
        this.eqData.itemCode = args.value;
        this.eqData.itemDescription = args.itemData.description;
        this.eqData.serializedItemGroupCode = args.itemData.serializedItemGroupCode;
        this.eqData.serializedItemGroupDescription = args.itemData.serializedItemGroupDescription;
      }
      this.fromSerialNumberAdvanceSearch = false;
    } else if (this.fromSerailNumberDDLChange) {

      this.fromSerailNumberDDLChange = false;
    } else { // model changed from popup open, or customer interaction
      if ( !args.value ) {
        this.resetModelRelatedFieldsForNull();
      }
    }
  }

  resetModelRelatedFieldsForNull() {
    this.eqData.serializedItemGroupDescription = null;
    this.eqData.serializedItemGroupCode = null;
    // console.log('Reset Model related fields');
  }

  getModelsDDL(configuration: string = '') {
    this.objects = [];
    this.soDetailsTabService.getIscObjects(configuration, this.serviceDepartment)
        .subscribe((res: ApiResponse) => {
            res.result.map(row => {
                row.text = row.value + " - " + row.description;
            });
            this.objects = res.result && res.result.length ? res.result : [];
        });
  }

  onSerialNumberCreated() {
    // if ( this.commonService.USER.role !== UserRoles.iscManager && this.commonService.USER.role !== UserRoles.isc ) {
        this.soDetailsTabService.getIscSerialNumbersForSearch(this.serviceDepartment, this.USER.company, this.gsData.configuration, this.eqData.itemCode, '', this.gsData.offlineOrder)
        .subscribe((res: ApiResponse) => {
            if (res && res.result) {
                res.result.map((row) => {
                    row.text = row.value = row.serialNumber;
                });
                this.serialNumbers = res.result;
            }
        });
    // }
  }

  getSerialNumbersDDL(searchString?: string) {
    this.soDetailsTabService.getIscSerialNumbersForSearch(this.serviceDepartment, this.USER.company, this.gsData.configuration, this.eqData.itemCode, searchString, this.gsData.offlineOrder)
        .subscribe((res: any) => {
            if (res && res.result ) {
                res.result.map((row) => {
                    row.text = row.value = row.serialNumber;
                });
                if (searchString && !res.result.length) {
                  // if serial is already selected and we try to filter, it was resetting the data source to [], and value gets updated as null, this is to avoid reset DS to null
                } else {
                  this.serialNumberDropdown.dataSource = this.serialNumbers = res.result;
                }
            }
            this.serviceOrderService.popupLoader.next(false);
        });
  }

  onSerialNumberChange(args: any) {
    if (args.isInteracted) {
      this.fromSerialNumberAdvanceSearch = true;
      const data = args.itemData;
      console.log('serial number interacted');
      // check if customer and model are selected already or not
      if ( this.gsData.configuration ) { // means model and serial is filtered according to customer
          if ( this.eqData.itemCode ) { // means serial number is already filtered according to model and customer
            this.eqData.serialNumber = data.serialNumber; //then, set serial only
          } else { // means model is filtered but not selected, we need to filter serial based on model
            this.eqData.itemCode = data.model; // set model and get filtered serial
            this.soDetailsTabService.getIscSerialNumbersForSearch(this.serviceDepartment, this.USER.company, data.configuration, data.model, null, this.gsData.offlineOrder)
            .subscribe((res: ApiResponse) => {
              if (res && res.result ) {
                res.result.map((row) => {
                    row.text = row.value = row.serialNumber;
                });
                this.serialNumbers = res.result;
              }
              this.eqData.serialNumber = data.serialNumber;
            });
          }
        } else {
          this.updateModelCustomerAfterSerialChange(data);
        }

        this.eqData.itemCode = data.model;
        this.updateSerialNumberRelatedData(args.itemData);
        this.getWarrantyAndContract();
        // this.serviceOrderService.serialNumberChange.next(args);
    } else if ( this.fromSerialNumberAdvanceSearch ) {
      // console.log('serial changed from advance search', args.value);
      if ( !args.value ) {
        // console.log('Reset Serial Number Related Fileds including Warranty and Contract Information');
        this.resetSerialNumberFieldsForNull();
      }
    } else {
      // console.log('serial changed on model, customer change or on popup open', args.value);
      if ( !args.value ) {
        this.resetSerialNumberFieldsForNull();
        // console.log('Reset Serial Number Related Fileds including Warranty and Contract Information');
      }
    }
  }

  resetSerialNumberFieldsForNull() {
    this.eqData.serialNumber = null;
    this.eqData.installationDate = null;
    this.eqData.warrantyCode = null;
    this.eqData.warrantyDescription = null;
    this.eqData.warrantyDescriptionObj = null;
    this.eqData.serviceContractCode = null;
    this.eqData.serviceContractDescription = null;
    this.eqData.serviceContractDescriptionObj = null;
    this.eqData.calibrationNextCertificateDate = null;
    localStorage.setItem('warranty', '');
    localStorage.setItem('contract', '');
    this.commonService.warantyInfo = null;
    this.commonService.contractInfo = null;
    if (this.warrantyInfo || this.contractInfo) {
        this.serviceOrderService.equipmentInfoSubject.next({ data: this.eqData, warrantyInfo: '', contractInfo: '' });
    }
  }

  onConfigurationFiltering(args) {
    this.serviceOrderService.setSearchCustomerFiltering(args.text); //searched text
  }

  onSerialNumberFiltering(args: any) {
    this.serviceOrderService.setSrNumberFiltering(args.text);
  }

  setCoverageClasses(data) {
    let warrantyDescriptionObj = data.warrantyDescriptionObj ?? data?.result?.warrantyDescriptionObj;
    let serviceContractDescriptionObj = data.serviceContractDescriptionObj ?? data?.result?.serviceContractDescriptionObj;
    if (warrantyDescriptionObj && warrantyDescriptionObj.length) {
        warrantyDescriptionObj.map(war => {
            war['class'] = this.getWarrantyClass(war);
        });
        this.warrantyInfo = warrantyDescriptionObj;
    }
    if (serviceContractDescriptionObj && serviceContractDescriptionObj.length) {
        serviceContractDescriptionObj.map(con => {
            con['class'] = this.getWarrantyClass(con);
        });
        this.contractInfo = serviceContractDescriptionObj;
    }
  }

  getWarrantyClass(data) {
    const today = new Date(), expiryDate = new Date(data?.expiryDate);
    let after30days = new Date(); after30days.setDate(after30days.getDate() + 30);
    let warrantyClass = '';

    if (expiryDate < today) {
        warrantyClass = 'bg-danger';
    } else if (expiryDate <= after30days && expiryDate > today) {
        warrantyClass = 'bg-warning';
    } else {
        warrantyClass = 'bg-success';
    }
    return warrantyClass;
  }

  getWarrantyAndContract() {
    if ( [serviceOrderStatuses.completed, serviceOrderStatuses.cancelled].includes(this.eqData.status) ) {
        const warrantyInfo = this.warrantyInfo || JSON.parse(this.eqData.warrantyCoverageLines);
        const contractInfo = this.contractInfo || JSON.parse(this.eqData.contractCoverageLines);
        this.setCoverageClasses({
            warrantyDescriptionObj: warrantyInfo,
            serviceContractDescriptionObj: contractInfo
        });

        this.serviceOrderService.equipmentInfoSubject.next({ data: this.eqData, warrantyInfo, contractInfo });
    } else {
        const modelId = this.eqData.itemCode ? this.eqData.itemCode : this.selectedModelNumber ? this.selectedModelNumber : null;
        if ( this.eqData?.warrantyCode && this.eqData?.serialNumber ) {
            this.soDetailsTabService.getIscContractAndWarranty(modelId, this.eqData.serialNumber, this.eqData.serviceContractCode, this.eqData.warrantyCode, this.USER.company, this.eqData.serviceTypeCode, this.eqData.soCreatedDate)
                .subscribe((res: any) => {
                    if (res.isSuccessful) {
                        this.setCoverageClasses(res.result);
                        if (this.warrantyInfo || this.contractInfo) {
                            this.serviceOrderService.equipmentInfoSubject.next({ data: this.eqData, warrantyInfo: this.warrantyInfo, contractInfo: this.contractInfo });
                        }
                    } else {
                        this.commonService.showNotification('error', 'There was some issue during fetching the data. Kindly, check the data and try again.');
                    }
                    this.serviceOrderService.popupLoader.next(false);
                }, error => {
                    this.commonService.showNotification('error', 'There was some issue during fetching the data. Kindly, check the data and try again.');
                    this.serviceOrderService.popupLoader.next(false);
                });
        }
    }
  }

  updateSerialNumberRelatedData(data) {
    if (data) {
        this.eqData.serialNumber = data.serialNumber;
        this.eqData.installationDate = data.installationDate ? data.installationDate : data.installationTime ? data.installationTime : null;
        this.eqData.warrantyCode = data.warrantyCode;
        this.eqData.warrantyDescription = data.warrantyDescription;
        this.eqData.warrantyDescriptionObj = this.warrantyInfo || data.warrantyDescriptionObj;
        this.eqData.serviceContractCode = data.serviceContractCode;
        this.eqData.serviceContractDescription = data.serviceContractDescription;
        this.eqData.serviceContractDescriptionObj = this.contractInfo || data.serviceContractDescriptionObj;
        this.eqData.calibrationNextCertificateDate = data.calibrationNextCertificateDate;
        localStorage.setItem('warranty', data.warrantyDescription || '');
        localStorage.setItem('contract', data.serviceContractDescription || '');
        this.commonService.warantyInfo = data.warrantyDescription;
        this.commonService.contractInfo = data.serviceContractDescription;
        if (this.warrantyInfo || this.contractInfo) {
            this.serviceOrderService.equipmentInfoSubject.next({ data, warrantyInfo: this.warrantyInfo, contractInfo: this.contractInfo });
        }
    }
  }

  setSerialNumberEnabled() {
    const readonlyUsingClaimRoles = this.serialNumberReadOnly;
    const enabledForKingslynn = this.isKingslynn && this.gsData.callGroup === CallGroups.commissionInstall && !this.eqData.serialNumber;
    this.isSerialNumberEnabled = (this.eqData?.offlineOrder || enabledForKingslynn) && !this.hasTransaction ? this.isEditable || !readonlyUsingClaimRoles : false;
  }

  setModelEnabled() {
    const readonlyUsingClaimRoles = this.itemCodeReadOnly;
    const enabledForKingslynn = this.isKingslynn && this.gsData.callGroup === CallGroups.commissionInstall && !this.eqData.itemCode;
    this.isModelEnabled = (this.eqData?.offlineOrder || enabledForKingslynn) && (this.isEditable || !readonlyUsingClaimRoles);
  }

  refrenceChanged(event){
    this.gsData.reference = event.value;
    this.refrenceInputLengthValidation = this.validateReference();
  }

  toggleCheckin() {
    if (this.isEditable && this.refrenceInputLengthValidation) {
      if (!this.status) {
        this.status =
          this.gsData && this.gsData.status
            ? this.gsData.status
            : serviceOrderStatuses.open;
      }
      const restrictedCheckinStatuses = [serviceOrderStatuses.inProgress, serviceOrderStatuses.completed, serviceOrderStatuses.cancelled];
      if ( !restrictedCheckinStatuses.includes(this.status) ) {
        if (this.isKingslynn && this.gsData.callGroup === CallGroups.commissionInstall && !(this.eqData.serialNumber && this.eqData.itemCode)) {// ticket 3609: Requirements Updated
            this.commonService.showNotification('error', 'Cannot check-in due to blank model/serial number.');
        } else {
          this.gsData.checkInDate = this.gsData.startDate = new Date();
          this.changeStatus(serviceOrderStatuses.inProgress);
        }
      } else if (this.status === serviceOrderStatuses.inProgress) {
        if (this.hasAssignments) {
          this.commonService
            .showConfirmation(
              'Do you want to change the status to awaiting payment?'
            )
            .then((result) => {
              if (result.isConfirmed) {
                this.gsData.checkOutDate = new Date();
                this.changeStatus(serviceOrderStatuses.awaitingPayment);
              } else if (result.isDenied) {
                this.gsData.checkOutDate = new Date();
                this.changeStatus(serviceOrderStatuses.paused);
              }
            });
        } else {
          this.commonService
            .showConfirmation(
              'There are no assignments added yet, status will be change to paused. Do you want to proceed?'
            )
            .then((result) => {
              if (result.isConfirmed) {
                this.gsData.checkOutDate = new Date();
                this.changeStatus(serviceOrderStatuses.paused);
              }
            });
        }
      }
    }
  }

  reasonChange(args: any) {
    this.gsData.reasonCode = args.value;
    this.gsData.reasonDescription = args.itemData.description;
  }

  getIscConfigurations(searchText: string, openDropdown: boolean = false) {
    // this.serviceOrderService.popupLoader.next(true);
    this.serviceOrderService.getIscConfigurations(
        this.serviceDepartment,
        this.USER.company,
        searchText
      ).subscribe((res: ApiResponse) => {
        res.result.map((row) => {
          return {
            text: row.soldToBP + ' - ' + row.bpDescription,
            value: row.cluster,
          };
        });
        if (searchText && !res.result.length) {
          // if customer is already selected and we try to filter, it was resetting the data source to [], and value gets updated as null, this is to avoid reset DS to null
        } else {
          this.configurationsDDL.dataSource = this.configurations = res.result;
        }
        // this.serviceOrderService.popupLoader.next(false);
      });
  }

  updateCallGroup(callgroup) {
    this.gsData.callGroup = null;
    this.callgroupCombo.refresh();
    // setTimeout(() => {
    //   this.gsData;
    // }, 100);
  }

  checkoutTimeChange(event) {
    this.checkedOut.next(event.value);
  }

  setCheckinDate(event) {
    this.gsData.checkInDate = event.value;
    if (event.value) {
      this.gsData.startDate = event.value;
    }
    this.checkedIn.next(event.value);
    if (event.isInteracted) {
      this.changeStatus(serviceOrderStatuses.inProgress);
    }
  }

  nteChangeTrigger(args) {
    this.gsData.nteAmount = args.value;
    this.gsData.approvedAmount = args.value;
    this.serviceOrderService.nteChange.next(args);
  }

  setAssignments(hasAssignments: boolean) {
    this.hasAssignments = hasAssignments;
  }

  onStartDateCreated(event: any) {
    if (this.id) {
      if (this.gsData.startDate) {
        if (this.gsData.status) {
          this.today = new Date();
        } else {
          this.today = new Date(this.gsData.startDate);
        }
      } else {
        this.today = new Date();
      }
    } else {
      this.today = new Date();
    }
  }

  onStartDateChange(event: any) {
    if (event.isInteracted) {
      if (
        !this.gsData.startDate ||
        this.gsData.status === serviceOrderStatuses.open
      ) {
        this.changeStatus(serviceOrderStatuses.scheduled);
      }
    }
    this.gsData.startDate = event.value;
  }

  changeStatus(status: string) {
    this.status = status;
    this.statusChanged.next(status);
    this.canCheckin = this.canUserCheckin();
  }

  onStartDateFocus(event: any) {
    if (!this.id) {
      this.commonService.showNotification(
        'error',
        `Please save the service order!`
      );
    }
  }

  canUserCheckin() {
    if (
      this.id &&
      (!this.hasCheckedInServiceOrder ||
        (this.hasCheckedInServiceOrder &&
          this.status === serviceOrderStatuses.inProgress)) &&
      this.status !== serviceOrderStatuses.completed &&
      this.status !== serviceOrderStatuses.cancelled &&
      this.status !== serviceOrderStatuses.pendingApproval &&
      !(
        (this.checkInDateReadOnly || this.checkOutDateReadOnly)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCustomerByConfiguration(configuration: string) {
    this.serviceOrderService.popupLoader.next(true);
    this.generalSectionService
      .getCustomerInfoByConfiguration(configuration, this.serialNumberDropdown.value ?? '', this.objectsDDL.value ?? '')
      .subscribe(
        (res: ApiResponse) => {
          if (res && res.result && res.result.length) {
            const data = res.result[0];
            this.gsData.address = data.address;
            this.gsData.customerEmail = data.email;
            this.gsData.businessPartnerStatus = data.businessPartnerStatus;
            this.gsData.addressCode = data.addressCode;
            this.gsData.city = data.city;
            this.gsData.signal = data.signalCodeDescription;
            this.gsData.country = data.country;
            this.gsData.customerPhoneNumber = this.gsData.customerPhoneNumber;
            this.gsData.customerCode = data.soldToBP;
            this.gsData.zipCode = data.zipCode;
            this.gsData.customerPhoneNumber = data.phoneNumber;
            this.gsData.customerCurrency = data.currency;
            this.gsData.taxable = data.taxable !== undefined ? data.taxable : false;
            this.gsData.state = data.stateProvince;
            this.gsData.termsOfPayment = data.termsOfPayment;
            this.gsData.termCodeDescription = data.termCodeDescription;
            this.gsData.customerDescription = data.soldToBP + ' - ' + data.bpDescription;
            this.gsData.isNationalAccount = data.isNationalAccount;
            this.gsData.isNationalAccount && this.removeBillableFromCallgroupList();
            this.generalSectionService.getIscCallGroups(data.soldToBP, this.eqData?.offlineOrder)
              .subscribe((res: ApiResponse) => {
                res?.result && this.loadCallgroups(res.result);
              });
            this.customerAddress = `${
              this.gsData.customerDescription
                ? this.gsData.customerDescription + '\n'
                : ''
            }${
              this.gsData.address
                ? this.gsData.address + '\n'
                : ''
            }${this.gsData.city || ''}, ${
              this.gsData.state || ''
            } ${this.gsData.zipCode || ''}\n${
              this.gsData.country
            }\n${this.gsData.customerPhoneNumber || ''}\n${
              this.gsData.customerEmail || ''
            }`;
            localStorage.setItem('currency', data.currency);
            this.distanceZoneChanged.emit({
              distanceZone: data.distanceZone,
              currency: data.currency,
            });
            this.generalSectionService.getEstimatedDistance(this.gsData.addressCode, this.USER.iscAddressCode).subscribe((res: ApiResponse) => {
              if (res.isSuccessful) {
                this.gsData.estimatedMiles = res.result;
                this.serviceOrderService.estimatedMiles = res.result; // make the changes to let execution tab know the latest value.
              } else {
                // this.commonService.showNotification('error', res.message + ' for Estimated Distance.');
              }
            }, error => {
              // this.commonService.showNotification('error', res.message + ' for Estimated Distance.');
              throw error;
            });
          }
          this.serviceOrderService.popupLoader.next(false);
        },
        (error: HttpErrorResponse) => {
          this.serviceOrderService.popupLoader.next(false);
        }
      );
  }

  removeBillableFromCallgroupList() { 
    const billableServiceIndex = this.callgroups.findIndex(cg => cg.value === CallGroups.billableService);
    this.isIsc && this.callgroups.splice(billableServiceIndex, 1);
  }

/**
 * Method will be used to set these values in the data
 * @param model | Optional: mapped to itemCode
 * @param itemDescription | Optional: mapped to itemDescription
 * @param serial | Optional: mapped to serialNumber
 */
  setModelSerialValues(model?: string, itemDescription?: string, serial?: string) {
    if ( model != null ) {
      this.eqData.itemCode = model || null;
    }
    if ( itemDescription != null ) {
      this.eqData.itemDescription = itemDescription || null;
    }
    if ( serial != null ) {
      this.eqData.serialNumber = serial || null;
    }
  }

  onConfigurationChange(args: any) {
    if ( args.isInteracted || this.fromCustomerAdvancedSearch ) { // Changed from Dropdown or Customer Advance search

        this.gsData.configuration = args.itemData.value;
        this.gsData.customerDescription = args.itemData.text;
        this.setModelSerialValues('', '', '');
        this.getModelsDDL(args.itemData.value);
        this.getSerialNumbersDDL(null);
        this.getCustomerByConfiguration(args.itemData.value); // should be called after setting configuration value in all use-cases

        if (this.fromCustomerAdvancedSearch) {
          console.log('from customer advanced search');
          this.fromCustomerAdvancedSearch = false;
        } else {
          console.log('on configuration change from ddl', args);
        }
    } else if ( this.fromSerialNumberAdvanceSearch ) {
      console.log('from serial number advanced search');

      // this.getCustomerByConfiguration(this.gsData.configuration); // should be called after setting configuration value in all use-cases
      // this.fromSerialNumberAdvanceSearch = false;
    } else { // On popup open OR ngOnChanges
      if ( this.id && args.value ) { // For update SO, need to call this API to get DistanceZone information
        this.getCustomerByConfiguration(args.value);
      }
      // console.log('Customer change on popup open or reset')
    }
    this.configurationChanged.emit(this.gsData.configuration);
    this.serviceOrderService.configurationChange.next({
      configurationId: this.gsData.configuration,
      model: this.eqData.itemCode,
      serialNumber: this.eqData.serialNumber
    })
  }

  updateModelDDL(itemCode, itemDescription) {
    if (this.objectsDDL) {
      this.objectsDDL.dataSource = [{ value: itemCode, text: itemCode + ' - ' + itemDescription }];
      this.objectsDDL.value = this.eqData.itemCode = itemCode;
      this.eqData.itemDescription = itemDescription;
    }
  }

  updateSerialNumberDDL(data) {
    if ( this.serialNumberDropdown) {
      this.serialNumberDropdown.dataSource = [{ value: data.serialNumber, text: data.serialNumber, ...data}];
      this.serialNumberDropdown.value = data.serialNumber;
    }
  }

  updateConfigurationDDL(configurationId, customerDescription) {
    if (this.configurationsDDL) {
        this.configurationsDDL.dataSource = [{ value: configurationId, text: customerDescription }];
        this.configurationsDDL.value = configurationId;
    }
  }

  loadCommonFields() {
    const apis = [
      this.generalSectionService.getServiceCenters(this.USER.company),
      this.serviceOrderService.getPriorities(this.USER.company),
      this.generalSectionService.getSignals(this.USER.company),
      this.serviceOrderService.getReasonsForPausedStatus(this.USER.company),
    ];
    forkJoin(apis).subscribe((res: any) => {
      res[0]?.result && this.loadServiceDepartments(res[0].result);
      res[1]?.result && this.loadPriorities(res[1].result);
      res[2]?.result && this.loadSignals(res[2].result);
      res[3]?.result && this.loadReasons(res[3].result);
    });
  }

  loadServiceDepartments(data) {
    this.serviceDepartments = data;
  }

  loadPriorities(data) {
    this.priorities = data.map((row) => {
      return { text: row.description, value: row.value };
    });
    this.gsData.priority =
      this.gsData && this.gsData.priority
        ? this.gsData.priority + ''
        : this.priorities.find((priority) => priority.text === 'Normal').value;
    this.gsData.priorityDescription =
      this.gsData && this.gsData.priorityDescription
        ? this.gsData.priorityDescription + ''
        : 'Normal';
  }

  loadSignals(data) {
    this.signals = data.map((row) => {
      return { text: row.description, value: row.value };
    });
  }

  loadCallgroups(data) {
    this.callgroups = data;
  }

  loadReasons(data) {
    this.reasonsForPause = data;
  }

  loadConfigurations(res) {
    if ( res?.isSuccessful ) {
      this.configurations = res.result.map((row) => {
        return {
          text: row.soldToBP + ' - ' + row.bpDescription,
          value: row.cluster,
        };
      });
      this.configurationsDDL.dataSource = this.configurations;
    } else {
      this.commonService.showNotification('error', res.message);
    }

  }

  // loadSerialNumbers(res) {
  //   if ( res?.isSuccessful ) {
  //     this.serialNumbers = res.result.map((row) => {
  //       row.text = row.value = row.serialNumber;
  //   });
  //   } else {
  //     this.commonService.showNotification('error', res.message);
  //   }
  // }

  initailizeGeneralSectionData() {
    if (!this.gsData) {
      // this.gsData = {
      //   serviceOrderIdLN: null,
      //   serviceDepartment: null,
      //   serviceDepartmentDescription: null,
      //   configuration: null,
      //   customerCode: null,
      //   customerDescription: null,
      //   customerCurrency: null,
      //   customerPhoneNumber: null,
      //   address: null,
      //   addressCode: '',
      //   elapsedTime: null,
      //   city: '',
      //   country: '',
      //   state: '',
      //   status: '',
      //   priority: '2',
      //   priorityDescription: null,
      //   object: null,
      //   offlineOrder: true,
      //   serialNumber: null,
      //   customerEmail: null,
      //   businessPartnerStatus: null,
      //   termsOfPayment: null,
      //   termCodeDescription: null,
      //   signal: null,
      //   checkInDate: null,
      //   checkOutDate: null,
      //   reference: null,
      //   startDate: new Date(),
      //   activityText: null,
      //   callGroup: null,
      //   reasonCode: null,
      //   reasonDescription: null,
      //   nteAmount: null,
      //   approvedAmount: null,
      // };
    } else {
      this.today = this.gsData.startDate
        ? new Date(this.gsData.startDate)
        : null;
      this.customerAddress = this.gsData.customerDescription
        ? `${
            this.gsData.customerDescription
              ? this.gsData.customerDescription + '\n'
              : ''
          }${
            this.gsData.address
              ? this.gsData.address + '\n'
              : ''
          }${this.gsData.city || ''}, ${
            this.gsData.state || ''
          } ${this.gsData.zipCode || ''}\n${
            this.gsData.country
          }\n${
            this.gsData.customerPhoneNumber
              ? this.gsData.customerPhoneNumber + '\n'
              : ''
          }${this.gsData.customerEmail || ''}`
        : '';
    }
  }

  validateReference(){
    if(this.gsData.reference.length > 30){
      this.commonService.showNotification('error', 'Max. limit for refrence input is 30 characters.');
      return false;
    } else {
      return true;
    }
  }

  validateGeneralSectionData() {
    this.validationApplied = true;
    if ( this.gsData.configuration && this.gsData.callGroup && (this.status !== serviceOrderStatuses.paused || (this.status === serviceOrderStatuses.paused && this.reasonsDDL?.value)) ) {
      if(this.gsData.reference){
        return this.validateReference();
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  
  onCloseSearchForCustomer(data: any) {
    this.showAdvancedSearch = false;
    if (data) {
        this.fromCustomerAdvancedSearch = true;
        this.updateConfigurationDDL(data.cluster, data.soldToBP + ' - ' + data.bpDescription);
        this.setModelSerialValues('', '', '');
        // this.serviceOrderService.modelDescriptionChange.next(null);
        // this.serviceOrderService.serialNumberChange.next({ itemData: null });
        // this.serviceOrderService.equipmentInfoSubject.next(null);

        // this.configurationChangeSubscription({ configurationId: data.cluster });
        // this.serviceOrderService.configurationChange.next({ configurationId: data.cluster,  });
    }
  }

  setNotesData() {
    if (!this.notesData) {
        // this.notesData = {
        //     activityText: null,
        //     customerComplaint: null,
        //     fstCommentsToCustomer: null,
        //     notesToCustomerCare: null,
        //     cancellationNotes: null,
        //     reasonCode: null,
        //     reasonDescription: null,
        //     offlineOrder: true
        // };
    }
  }

  validateCommentsNotesData(onInvoicePaymentValidate) {
    this.validationApplied = true;
    if (this.status === serviceOrderStatuses.cancelled  && !this.notesData.reasonCode || onInvoicePaymentValidate && !this.notesData.fstCommentsToCustomer ) { // fstCommentsToCustomer = Diagnosis/Solution
      return false;
    } else {
      return true;
    }
  }

  getCommentsNotesData() {
    return this.notesData;
  }
  getGeneralSectionData() {
    this.gsData.priority = this.gsData.priority + '';
    this.gsData.businessPartnerStatus = this.gsData.businessPartnerStatus ? this.gsData.businessPartnerStatus : null;
    return this.gsData;
  }

  getEquipmentSectionData() {
    if ([serviceOrderStatuses.completed, serviceOrderStatuses.cancelled].includes(this.eqData.status)) {
      this.eqData.warrantyCoverageLines = JSON.stringify(this.warrantyInfo);
      this.eqData.contractCoverageLines = JSON.stringify(this.contractInfo);
    }
    return this.eqData;
  }

  onTextInput(args, textareaObj) {
    textareaObj.element.style.height = "auto";
    textareaObj.element.style.height = (textareaObj.element.scrollHeight) + "px";
  }

  getSerialNumbers(isInteracted: boolean) {
    this.soDetailsTabService.getSerialNumbers(this.gsData.configuration, this.eqData.itemCode)
      .subscribe((res: any) => {
          this.serialNumbers = res.result && res.result.length ? res.result : [];
          this.serviceOrderService.popupLoader.next(false);
      }, (error: HttpErrorResponse) => {
          this.serviceOrderService.popupLoader.next(false);
      });
  }

  onCloseSearchForSerialNumbers(data: any) {
    this.showAdvancedSearchForSerialNumbers = false;
    if (data) {
      this.fromSerialNumberAdvanceSearch = true;
      if (this.gsData.configuration) {
          this.commonService.showConfirmation('Customer will change on changing Serial Number. Continue?')
              .then(result => {
                  this.serviceOrderService.popupLoader.next(true);
                  if (result.isConfirmed) {
                      this.updateModelCustomerAfterSerialChange(data);
                      this.eqData.itemCode = data.model;
                      this.updateSerialNumberRelatedData(data);
                      this.getWarrantyAndContract();
                  }
                  this.serviceOrderService.popupLoader.next(false);
              });
      } else {
        this.updateModelCustomerAfterSerialChange(data);
        this.eqData.itemCode = data.model;
        this.updateSerialNumberRelatedData(data);
        this.getWarrantyAndContract();
      }
    }
  }

  updateModelCustomerAfterSerialChange(data) {
    this.objectsDDL.clear();
    this.serialNumberDropdown.clear();
    this.configurationsDDL.clear();
    const configurationDescription = data.customerId + ' - ' + data.customerName;
    this.configurations = [{ value: data.configuration, text: configurationDescription }];
    forkJoin([
      this.soDetailsTabService.getIscObjects(data.configuration, this.serviceDepartment),
      this.soDetailsTabService.getIscSerialNumbersForSearch(this.serviceDepartment, this.USER.company, data.configuration, data.model, '', this.gsData.offlineOrder)
    ]).subscribe((res: any) => {
      if ( res[0]?.result ) {
        res[0].result.map(row => {
          row.text = row.value + " - " + row.description;
        });
        this.objectsDDL.dataSource = this.objects = res[0].result;
      }
      if ( res[1]?.result ) {
        res[1].result.map((row) => {
          row.text = row.value = row.serialNumber;
        });
        this.serialNumberDropdown.dataSource = this.serialNumbers = res[1].result;
      }
      this.configurationsDDL.value = this.gsData.configuration = data.configuration;
      this.gsData.customerDescription = configurationDescription;
      this.objectsDDL.value = this.eqData.itemCode = data.model;
      this.eqData.itemDescription = data.modelDescription;
      this.serialNumberDropdown.value = this.eqData.serialNumber = data.serialNumber;

      this.getCustomerByConfiguration(data.configuration);
    });
  }

  // reset() {
  //   this.selectedModelNumber = null;
  //   this.selectedSerialNumber = null;
  //   this.eqData.itemCode = null;
  //   this.eqData.itemDescription = null;
  //   this.eqData.serialNumber = null;
  //   this.eqData.serializedItemGroupCode = null;
  //   this.eqData.serializedItemGroupDescription = null;
  //   this.eqData.category = null;
  //   this.eqData.installationDate = null;
  //   this.eqData.warrantyDescription = null;
  //   this.eqData.warrantyCode = null;
  //   this.eqData.serviceContractCode = null;
  //   this.eqData.serviceContractDescription = null;
  //   this.eqData.calibrationNextCertificateDate = null;
  // }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
