import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Grid, GridComponent } from '@syncfusion/ej2-angular-grids';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { shipmentOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { gridNames } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-shipped-items',
  templateUrl: './shipped-items.component.html',
  styleUrls: ['./shipped-items.component.scss']
})
export class ShippedItemsComponent implements OnInit {

  gridName: string = gridNames.receiptsPopupShippedItemsGrid;
  gridHeight: 240;
  allowEditing: boolean = true;
  shippedItemsGrid: GridComponent;
  gridColumns: any;

  @Input() receipts : any = [];
  @Input() status : string;
  @Output() onShippedItemsUpdated = new EventEmitter();

  @ViewChild('shipmentOrderReceiptsGridColumnChooser') receiptsGridColumnChooser;
  roleCanEdit: boolean = false;
  canEdit: boolean = false;
  itemQuantityField: HTMLInputElement;
  itemQuantityObject: any;
  @ViewChild('shipmentOrderReceiptsGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.shippedItemsGrid = gridComponent;
      this.getToolbar();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.shippedItemsGrid?.isEdit) {
      if (!this.eRef.nativeElement.contains(event.target) ) {
        this.shippedItemsGrid?.endEdit();
      }
    }
  }

  constructor(
    private commonService: CommonService,
    private eRef: ElementRef
  ) { }

  ngOnChanges(change) {
    if(change?.status){
      this.status === change.status.currentValue;
      this.getCanEditValue();
      // this.enableDisableButton();
    }
  }

  ngOnInit(): void {
    this.gridColumns = this.getColumns();
    this.receipts.map((row, index) => {
      row.tempRowId = index;
    })
  }

  getCanEditValue(){
    this.roleCanEdit = this.commonService.roleClaims['Inv_Order_Receipt_Popup_Receipt_Tab']?.edit
    this.canEdit = this.status === shipmentOrderStatuses.deliveryAcknowledged && this.roleCanEdit;
    this.allowEditing = this.canEdit;
  }

  queryCellInfo(args: any, grid: Grid){
    if (args.column.field !== 'quantityServiceable') {
      args.cell.classList.add('grid-column-grey');
    }
  }
  
  onToolbarClick(eventArgs: any, grid: Grid){

  }
  
  onGridCreated(eventArgs: any, grid: Grid){

  }
  
  onActionComplete(args: any, grid: Grid){
    if (args.requestType === 'save'){
      const rowData = this.receipts.find(row => row.tempRowId === args.data.tempRowId);
      rowData.quantityServiceable = args.data.quantityServiceable;
      let qtyDiff = args.data.quantityShippedInInventoryUnit - args.data.quantityServiceable;
      if(qtyDiff<0){
        rowData.shortShipment = 0;
        rowData.overShipment = Math.abs(qtyDiff);
      } else if(qtyDiff>0){
        rowData.shortShipment = qtyDiff;
        rowData.overShipment = 0;
      } else if(qtyDiff===0) {
        rowData.shortShipment = 0;
        rowData.overShipment = 0;
      }
      grid.refresh();
      this.onShippedItemsUpdated.emit(this.receipts)
    }
  }
  
  onGridLoad(eventArgs: any, grid: Grid){

  }

  getToolbar(){
    this.shippedItemsGrid.toolbar = [
      { text: '', id: 'receipts-column-chooser', align: 'Left', prefixIcon: '', template: this.receiptsGridColumnChooser, tooltipText: 'Show/Hide Columns' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.shippedItemsGrid.toolbar.findIndex(x => x === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.shippedItemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
  }

  getColumns(){
    return [
      { field: 'tempRowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
      { field: 'item', headerText: 'Item', type: 'string', textAlign: 'Left' },
      { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left' },
      { field: 'orderQty', headerText: 'Ord Qty', type: 'number', format: 'n', textAlign: 'right', width: 157},
      { field: 'quantityShippedInInventoryUnit', headerText: 'Shipped Qty', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      { field: 'quantityServiceable', headerText: 'Serviceable Received Qty', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'right', width: 142, showInColumnChooser: false, edit: this.initItemQuantity(), editType: 'numericedit', validationRules: { required: false } },
      { field: 'shortShipment', headerText: 'Short Shipped', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      { field: 'overShipment', headerText: 'Over Shipped', type: 'number', format: 'n', textAlign: 'right', width: 157, showInColumnChooser: false, },
    ];
  }

  initItemQuantity() {
    return {
      create: () => {
        this.itemQuantityField = document.createElement('input');
        return this.itemQuantityField;
      },
      read: () => {
        if (this.itemQuantityObject) {
          return this.itemQuantityObject.value;
        }
      },
      destroy: () => {
        this.itemQuantityObject.destroy();
      },
      write: (args) => {
        this.itemQuantityObject = new NumericTextBox({
          value: args.rowData.quantity || 1,
          showSpinButton: false,
          min: 0,
          step: 1,
          decimals: 0,
          validateDecimalOnType: true,
          created: args => {
            this.itemQuantityField.onkeyup = (event: any) => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.itemQuantityObject.value = +event.target.value;
              }
            }
          },
          change: args => {
          }
        });
        this.itemQuantityObject.appendTo(this.itemQuantityField);
      }
    }
  }

  getData(){
    return this.receipts;
  }

  getItemsData(){
    return {
      itemList: (this.shippedItemsGrid.dataSource as any).map((item, index) => {
        item.warehouseOrderLN = item.warehouseOrderLN || item.partsOrderRef;
        return item;
      })
    }
  }

}
