<div class="modal fade show z" [ngClass]="{'expanded': isExpanded}" id="service-order-modal" tabindex="-1"
    aria-labelledby="service-order-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-lg">
     <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header  
                [isExpanded]="isExpanded"
                [hideCreationDate]="hideCreationDate"
                [title]="title"
                (onClose)="close()" 
                (onExpandCollapse)="expandCollapse($event)"
            ></app-popup-header>

            <ejs-grid #linkServiceOrdersGrid
                [id]="gridName"
                [height]="gridHeight"
                [dataSource]="ServiceOrdersForTransferOrder"
                [enablePersistence]="false"
                [allowFiltering]="true"
                [allowSorting]="true"
                [allowResizing]="true"
                [allowReordering]="true"
                [allowExcelExport]="true"
                [allowSelection]="true"
                [filterSettings]="{ type: 'Menu' }"
                [selectionSettings]="{ type: 'Multiple' }"
                (actionBegin)='actionBegin($event)'
                (dataBound)='dataBound($event)'
                (rowSelected)='rowSelected($event)'
                (rowDeselected)='rowDeselected($event)'
                (checkBoxChange)="checkBoxChange($event)"
                (toolbarClick)="toolbarClick($event, linkServiceOrdersGrid)"
                (created)="onGridCreated()"
                (commandClick)="onCommandClick($event)"
                (actionBegin)="onGridActionBegin($event)"
                (actionComplete)="onGridActionComplete($event, linkServiceOrdersGrid)"
                >
                <e-columns>
                    <e-column 
                        *ngFor="let column of gridColumns;" 
                        [field]="column.field" 
                        [headerText]="column.headerText"
                        [showInColumnChooser]="column.showInColumnChooser"
                        [type]="column.type"
                        [width]="column.width"
                        [filter]="column.filter ? column.filter : { type: 'Menu' }"
                        [format]="column.format ? column.format : null"
                        [textAlign]="column.textAlign" 
                        [visible]="column.visible"
                        [commands]="column?.commands ? column.commands : null"
                        [allowEditing]="column.allowEditing" 
                        [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true"
                        [allowSorting]="column.allowSorting">
                    </e-column>
                </e-columns>
            </ejs-grid>

            <div class="modal-footer"> 
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success close-modal" (click)="add()">Add</button>
           </div>
       </div>

   </div>
</div>

<ng-template #columnChooser>
    <app-column-chooser
        [grid]="linkServiceOrdersGrid"
        [columns]="gridColumns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>