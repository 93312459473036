import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ComboBox, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import {
    CommandColumnService,
    ExcelExportProperties,
    ExcelExportService,
    FilterService,
    GridComponent,
    SortService,
    ToolbarService,
    EditSettingsModel,
    ForeignKeyService,
    EditService
} from '@syncfusion/ej2-angular-grids';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-angular-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Query } from '@syncfusion/ej2-data';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';

@Component({
    selector: 'app-shipment-exceptions-tab',
    templateUrl: './shipment-exceptions-tab.component.html',
    styleUrls: ['./shipment-exceptions-tab.component.scss'],
    providers: [
        ToolbarService,
        FilterService,
        SortService,
        EditService,
        ForeignKeyService,
        ExcelExportService,
        CommandColumnService
    ]
})
export class ShipmentExceptionsTabComponent implements OnInit, OnChanges {
    public editSettings: EditSettingsModel;

    showColumnChooser: boolean;
    showAdjustmentOrderPopup: boolean = false;
    skipBeginEditEvent: boolean = false;
    columns: any;

    itemsField;
    itemsObject: ComboBox;

    itemGroupField;
    itemGroupObject: any;

    itemDescriptionField;
    itemDescriptionObject: any;

    itemQuantityField;
    itemQuantityObject: any;

    exceptionReasonField;
    exceptionReasonObject: any;
    selectedRowId: string;
    showItemAdvancedSearchPopup: boolean = false;

    exceptionsQuery: Query;

    onItemUpdate = new Subject<string>();

    @Input() itemsData;
    @Input() itemsDDL;
    itemsList = [];
    @Input() reasonDDL;
    @Input() exceptions;
    @Input() exceptionItem;
    @Input() status;
    gridButtonClass: string = null;

    @Output() exceptionUpdated = new EventEmitter();

    @ViewChild('exceptionsGrid') exceptionsGrid: GridComponent;
    @ViewChild('exceptionsGridColumnChooser') columnChooser: any;

    constructor(
        private commonService: CommonService,
        private eRef: ElementRef
    ) { }

    // @HostListener('document:click', ['$event'])
    // onClick(event) {
    //     if ( this.exceptionsGrid.isEdit ) {
    //         if ( !this.eRef.nativeElement.contains(event.target)
    //             && !document.getElementById('exceptionsGriditemCode_popup')?.contains(event.target) && !document.getElementById('exceptionsGridreasonDescription_popup')?.contains(event.target)) {
    //             this.exceptionsGrid.endEdit();
    //         }
    //     }
    // }

    ngOnInit(): void {
        this.gridButtonClass = this.status === 'Parts Received' ? 'disabled-grid-button' : null;
        // this.getItemList();
        // this.onItemUpdate
        // .pipe(
        //     debounceTime(500),
        //     distinctUntilChanged()
        // ).subscribe( value => {
        //     if ( this.itemsObject ) {
        //         this.itemsObject.showPopup();
        //         this.itemsObject.showSpinner();
        //     }
        //     this.getItemList(value);
        // });
        // if ( this.exceptions && this.exceptions.length ) {
        //     this.exceptions.forEach( (exception, index) => {
        //         exception.lineNumber = index + 1;
        //     });
        // }
        this.columns = [
            // { field: 'lineNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, isPrimaryKey: true },
            { field: 'itemCode', headerText: 'Item', type: 'string', filter: { type: 'Excel' }, validationRules: { required: true }, textAlign: 'Left', width: 155 },
            { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left' },
            { field: 'quantity', headerText: 'Quantity', format: 'n', textAlign: 'Left', width: 130 },
            { field: 'reasonCode', headerText: 'Reason code', textAlign: 'Left', width: 190, visible: false, },
            { field: 'reasonDescription', headerText: 'Reason', textAlign: 'Left', width: 190, filter: { type: 'Excel' } },

            // {
            //     field: 'Actions', headerText: 'Actions', textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
            //     commands: [{ title: 'Delete', buttonOption: { iconCss: `fas fa-trash delete-button`, cssClass: `e-flat delete-button` } }]
            // }
        ]
    }

    ngOnChanges() {
        // if ( this.exceptionItem ) {
        //     this.updateExceptions(this.exceptionItem);
        // }
    }

    // getItemList(value: string = null) {
    //     this.commonService.getItems(value)
    //     .subscribe( (res: any) => {
    //         if ( res && res.result && res.result && res.result.length ) {
    //             res.result.map( item => {
    //                 item.value = item.item;
    //                 item.text = `${item.item} | ${item.itemDescription}`;
    //             });

    //             const existingItems = [];
    //             (this.exceptionsGrid.dataSource as any).forEach(ex => {
    //                 if (ex.itemCode !== this.itemsObject?.value && ex.isEditable) {
    //                     existingItems.push(ex.itemCode);
    //                 }
    //             });
    //             this.itemsList = res.result.filter(item => !existingItems.includes(item.value));
    //             if ( this.itemsObject ) {
    //                 this.itemsObject.dataSource = this.itemsList;
    //                 this.itemsObject.hideSpinner();
    //             }
    //         } else {
    //             this.itemsList = [];
    //             if ( this.itemsObject ) {
    //                 this.itemsObject.hideSpinner();
    //                 this.itemsObject.dataSource = [];
    //             }
    //         }
    //     });
    // }

    // updateExceptions(exception) {
    //     const itemExceptions = this.getItemExceptions(exception);
    //     if ( this.exceptions.length ) {
    //         const otherItemExceptions = this.exceptions.filter(ex => ex.itemCode !== exception.itemCode);
    //         otherItemExceptions.push(...itemExceptions);
    //         const deletedExceptions = this.exceptions.filter(ex => {
    //             ex.isDeleted = !!ex.shoExceptionId && ex.itemCode === exception.itemCode;
    //             return ex.itemCode === exception.itemCode && ex.shoExceptionId;
    //         });
    //         this.exceptions = otherItemExceptions.concat(deletedExceptions);
    //     } else {
    //         this.exceptions.push(...itemExceptions);
    //     }
    //     this.exceptions.sort((a,b) => a.isDeleted - b.isDeleted).map( (ex, index) => {
    //         ex.lineNumber = index+1;
    //     });
    //     this.exceptionsGrid.dataSource = this.exceptions;
    //     this.exceptionsGrid.refresh();
    // }

    // getItemExceptions(exception) {
    //     const exceptions = [];
    //     let id = (this.exceptionsGrid.dataSource as any).length;
    //     const exceptionObject = {
    //         shoExceptionId: 0,
    //         lineNumber: null,
    //         itemCode: exception?.itemCode || '',
    //         itemDescription: exception?.itemDescription || '',
    //         itemGroup: exception?.itemGroup || '',
    //         quantity: 0,
    //         reasonCode: null,
    //         reasonDescription: null,
    //         shipmentOrderDetailId: exception?.shipmentOrderDetailId || '',
    //         isDeleted: false,
    //         createdBy: exception?.createdBy || '',
    //         createdDate: exception?.createdDate || new Date(),
    //         createdByName: exception?.createdByName || '',
    //         lastUpdatedDate: exception?.lastUpdatedDate || new Date(),
    //         lastUpdatedBy: exception?.lastUpdatedBy || '',
    //         lastUpdatedByName: exception?.lastUpdatedByName || '',
    //         isEditable: false,
    //         isManual: false
    //     };
    //     if ( exception.quantityShippedInInventoryUnit !== exception.quantityReceived || exception.quantityReceived !== exception.quantityServiceable ) {

    //         if ( exception.quantityReceived > exception.quantityServiceable ) {
    //             const newException = Object.assign({}, exceptionObject);
    //             id += 1;
    //             newException.lineNumber = id;
    //             newException.quantity = exception.quantityReceived - exception.quantityServiceable;
    //             newException.reasonCode = this.getReasonCode('Damaged');
    //             newException.reasonDescription = 'Damaged';
    //             exceptions.push(newException);
    //         }

    //         if ( exception.quantityShippedInInventoryUnit > exception.quantityReceived ) {
    //             const newException = Object.assign({}, exceptionObject);
    //             id += 1;
    //             newException.lineNumber = id;
    //             newException.quantity = exception.quantityShippedInInventoryUnit - exception.quantityReceived;
    //             newException.reasonCode = this.getReasonCode('Short Shipment');
    //             newException.reasonDescription = 'Short Shipment';
    //             exceptions.push(newException);
    //         } else if ( exception.quantityShippedInInventoryUnit < exception.quantityReceived ) {
    //             if ( !(exception.quantityReceived > exception.quantityServiceable) ) {
    //                 const newException = Object.assign({}, exceptionObject);
    //                 id += 1;
    //                 newException.lineNumber = id;
    //                 newException.quantity = exception.quantityReceived - exception.quantityShippedInInventoryUnit;
    //                 newException.reasonCode = this.getReasonCode('Excess Shipment');
    //                 newException.reasonDescription = 'Excess Shipment';
    //                 exceptions.push(newException);
    //             }
    //         }


    //     }
    //     return exceptions;
    // }

    // actionBegin(args: any) {
    //     if (args.requestType === 'beginEdit' ) {
    //         if ( args.rowData?.itemCode ) {
    //             if ( this.skipBeginEditEvent ) {
    //                 this.skipBeginEditEvent = false;
    //             } else {
    //                 this.getItemList(args.rowData?.itemCode);
    //             }
    //         }
    //         if ( args.rowData.isEditable !== undefined ) {
    //             args.cancel = args.rowData.isEditable ? false : true;
    //         }
    //     }
    // }

    // actionComplete(args: any) {
    //     updateFilterIcon({requestType: 'filtering'}, this.exceptionsGrid);
    // }

    // dataBound(args) {
    //     setTimeout(() => {
    //         this.exceptionsGrid?.toolbarModule?.enableItems(['add-exception'], this.status === 'Delivery Acknowledged');
    //     }, 50);
    // }

    // rowDataBound(args: any) {
    //     const data = args.data;
    //     const $row = args.row;

    //     // if ( data.isManual ) {
    //     //     $row.getElementsByClassName('delete-button')[0].classList.remove('disabled');
    //     // } else {
    //     //     $row.getElementsByClassName('delete-button')[0].classList.add('disabled');
    //     // }

    //     // const actionButtons = $row.querySelectorAll('.delete-button');
    //     // if ( this.status === 'Delivery Acknowledged' && data.isManual) {
    //     //     actionButtons[0].classList.remove('disabled-grid-button');
    //     // } else {
    //     //     actionButtons[0].classList.add('disabled-grid-button');
    //     // }

    //     if ( data.isRemovedFrmExceptionList ) {
    //         $row.classList.add('bg-danger');
    //     } else {
    //         $row.classList.remove('bg-danger');
    //     }
    // }

    // setExceptionsData(item?, fromItemsTab?) {
    //     item.shoExceptionId = 0;
    //     item.lineNumber = (this.exceptionsGrid.dataSource as any).length + 1;
    //     item.itemCode = this.exceptionItem?.itemCode || '';
    //     item.itemDescription = this.exceptionItem?.itemDescription || '';
    //     item.description = this.exceptionItem?.description || '';
    //     item.itemGroup = this.exceptionItem?.itemGroup || '';
    //     item.quantity = this.getReasonAndQuantity('', fromItemsTab);
    //     item.reasonCode = this.getReasonAndQuantity('reasonCode', fromItemsTab);
    //     item.reasonDescription = this.getReasonAndQuantity('reason', fromItemsTab);
    //     item.shipmentOrderDetailId = this.exceptionItem?.shipmentOrderDetailId || '';
    //     item.isDeleted = false;
    //     item.createdBy = this.exceptionItem?.createdBy || '';
    //     item.createdDate = this.exceptionItem?.createdDate || new Date();
    //     item.createdByName = this.exceptionItem?.createdByName || '';
    //     item.lastUpdatedDate = this.exceptionItem?.lastUpdatedDate || new Date();
    //     item.lastUpdatedBy = this.exceptionItem?.lastUpdatedBy || '';
    //     item.lastUpdatedByName = this.exceptionItem?.lastUpdatedByName || '';
    //     item.isManual = true;

    //     return item;
    // }

    // toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    //     if (args.item.id === 'excel-export') {
    //         const dataSource = getExcelDataSource(grid);
    //         let excelExportProperties: ExcelExportProperties = {
    //             dataSource,
    //             hierarchyExportMode: 'Expanded',
    //             theme: {
    //                 header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
    //             },
    //             fileName: `Exceptions (${getCurrentDate()}).xlsx`
    //         };
    //         grid.excelExport(excelExportProperties);
    //     } else if (args.item.id === 'pdf-export') {
    //         grid.pdfExport();
    //     } else if (args.item.id === 'clear-filter') {
    //         grid.clearFiltering();
    //         grid.search('');
    //     } else if (args.item.id === 'column-chooser') {
    //         this.showColumnChooser = !this.showColumnChooser;
    //     } else if (args.item.id === 'add-exception') {
    //         this.exceptionsGrid.endEdit();
    //         if ( !this.exceptionsGrid.isEdit ) {
    //             this.exceptionItem = null;
    //             this.addItem();
    //         } else {
    //             this.commonService.showNotification('warning','Exception is in edit mode!');
    //         }
    //     }
    // }

    // getReasonAndQuantity(param?: any, fromItemsTab?: boolean) {
    //     if ( fromItemsTab ) {
    //         if ( this.exceptionItem.quantityShippedInInventoryUnit > this.exceptionItem.quantityServiceable ) {
    //             return param === 'reason' ? 'Short Shipment' : param === 'reasonCode' ? this.getReasonCode('Short Shipment') : this.exceptionItem.quantityShippedInInventoryUnit - this.exceptionItem.quantityReceived;
    //         } else {
    //             return param === 'reason' ? 'Excess Shipment' : param === 'reasonCode' ? this.getReasonCode('Excess Shipment') : this.exceptionItem.quantityReceived - this.exceptionItem.quantityShippedInInventoryUnit;
    //         }
    //     } else {
    //         return '';
    //     }
    // }

    // getReasonCode(reasonText: string) {
    //     return this.reasonDDL.find(reason => reason.text.trim().toLowerCase() === reasonText.trim().toLowerCase()).value;
    // }

    // getReason(reasonCode: string) {
    //     return this.reasonDDL.find(reason => reason.value === reasonCode).text;
    // }

    // onGridCreated() {
    //     this.exceptionsQuery = new Query().where('isDeleted', 'equal', false);
    //     this.exceptionsGrid.toolbar = [
    //         { id: 'column-chooser', tooltipText: 'Show/Hide Columns', template: this.columnChooser },
    //         'Search',
    //         { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
    //         { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    //     ];
    //     updateFilterIcon({requestType: 'filtering'}, this.exceptionsGrid);
    // }

    getData() {
        const dataSource = (this.exceptionsGrid.dataSource as any).slice();
        return { exceptionList: dataSource.filter(row => {
                if(row.reasonCode && row.itemCode && row.quantity){
                    row.warehouseOrderLN = row.warehouseOrderLN || this.itemsData[0].partsOrderRef;
                    return row;
                }
            }) 
        };
    }

    // validate() {
    //     return true;
    // }

    // // commandColumnClick(args) {
    // //     if (args.commandColumn.title === 'Delete') {
    // //         if (args.rowData.isEditable === undefined || args.rowData.isEditable) {
    // //             this.commonService.showConfirmation('Are you sure? You want to delete this exception?').then(result => {
    // //                 if (result.isConfirmed) {
    // //                     if ( args.rowData.shoExceptionId ) {
    // //                         const deletedRow = (this.exceptionsGrid.dataSource as any).find(ex => ex.itemCode === args.rowData.itemCode);
    // //                         deletedRow.isDeleted = true;
    // //                     } else {
    // //                         const index = (this.exceptionsGrid.dataSource as any).findIndex(ex => ex.itemCode === args.rowData.itemCode);
    // //                         (this.exceptionsGrid.dataSource as any).splice(index, 1);
    // //                     }
    // //                     args.rowData.isDeleted = true;
    // //                     this.exceptionUpdated.emit(args.rowData);
    // //                     (this.exceptionsGrid.dataSource as any).sort((a,b) => a.isDeleted - b.isDeleted).map((ex, index) => { ex.lineNumber = index + 1; return ex; })
    // //                     this.exceptionsGrid.refresh();
    // //                 }
    // //             });
    // //         }
    // //     }
    // // }

    // addItem() {
    //     let newIndex = (this.exceptionsGrid.dataSource as any).length;
    //     let newRow = this.setExceptionsData({}, false);
    //     (this.exceptionsGrid.dataSource as any).push(newRow);
    //     this.exceptionsGrid.refresh();
    //     setTimeout(() => {
    //         this.exceptionsGrid.selectRow(newIndex);
    //         this.exceptionsGrid.startEdit();
    //     }, 100);
    // }

    // showItemPopup() {
    //     this.showItemAdvancedSearchPopup = true;
    // }

    // onItemAdvancedSearchPopupClose(data: any) {
    //     this.showItemAdvancedSearchPopup = false;
    //     const rowIndex = this.exceptionsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
    //     this.exceptionsGrid.selectRow(rowIndex);
    //     setTimeout(() => {
    //         this.exceptionsGrid.startEdit();
    //         this.skipBeginEditEvent = true;
    //         setTimeout(() => {
    //             this.itemsObject.value = data.item;
    //             this.itemsObject.dataSource = [{
    //                 text: `${data.item} | ${data.itemDescription}`,
    //                 value: data.item,
    //                 ...data
    //             }];
    //         }, 400);
    //     }, 300);
    // }

    // addAdvancedSearchIcon() {
    //     const itemAnchor = document.createElement('a');
    //     itemAnchor.classList.add('item-advance-search-icon');
    //     itemAnchor.setAttribute('href', 'javascript:');
    //     itemAnchor.setAttribute('title', 'Advanced Search');
    //     itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
    //     const icon = document.createElement('i');
    //     icon.classList.add('fas');
    //     icon.classList.add('fa-info');
    //     itemAnchor.appendChild(icon);
    //     this.itemsField.parentElement.parentElement.append(itemAnchor);
    // }

    // initItemsDDL() {
    //     return {
    //         create: () => {
    //             this.itemsField = document.createElement('input');
    //             return this.itemsField;
    //         },
    //         read: () => {
    //             if (this.itemsObject) {
    //                 return this.itemsObject.value;
    //             }
    //         },
    //         destroy: () => {
    //             this.itemsObject.destroy();
    //         },
    //         write: (args) => {
    //             this.itemsObject = new ComboBox({
    //                 dataSource: this.itemsList,
    //                 fields: { value: 'value', text: 'text' },
    //                 value: args.rowData.itemCode,
    //                 cssClass: 'generic-items-dropdown-list',
    //                 allowFiltering: true,
    //                 popupWidth: '350px',
    //                 filterType: 'Contains',
    //                 filtering: args => { this.onItemUpdate.next(args.text); },
    //                 focus: args => { this.itemsObject.showPopup() },
    //                 change: (args: any) => {
    //                     const item = this.itemsData.find(item => item.itemCode === args.itemData.value);
    //                     this.itemDescriptionObject.value = args.itemData.description || args.itemData.itemDescription;
    //                     this.itemQuantityObject.max = item ? item.quantityShippedInInventoryUnit : undefined;
    //                 }
    //             });
    //             this.itemsObject.appendTo(this.itemsField);
    //             this.addAdvancedSearchIcon();
    //         }
    //     }
    // }

    // initItemDescription() {
    //     return {
    //         create: () => {
    //             this.itemDescriptionField = document.createElement('input');
    //             return this.itemDescriptionField;
    //         },
    //         read: () => {
    //             if (this.itemDescriptionObject) {
    //                 return this.itemDescriptionObject.value;
    //             }
    //         },
    //         destroy: () => {
    //             this.itemDescriptionObject.destroy();
    //         },
    //         write: (args) => {
    //             this.itemDescriptionObject = new TextBox({
    //                 value: args.rowData.itemDescription,
    //                 enabled: false
    //             });
    //             this.itemDescriptionObject.appendTo(this.itemDescriptionField);
    //         }
    //     }
    // }

    // initItemQuantity() {
    //     return {
    //         create: () => {
    //             this.itemQuantityField = document.createElement('input');
    //             return this.itemQuantityField;
    //         },
    //         read: () => {
    //             if (this.itemQuantityObject) {
    //                 return this.itemQuantityObject.value;
    //             }
    //         },
    //         destroy: () => {
    //             this.itemQuantityObject.destroy();
    //         },
    //         write: (args) => {
    //             this.itemQuantityObject = new NumericTextBox({
    //                 value: args.rowData.quantity,
    //                 showSpinButton: false,
    //                 min: 1,
    //                 created: args => {
    //                     this.itemQuantityField.onkeyup = event => {
    //                         if ( event.keyCode >= 48 && event.keyCode <=57 || event.keyCode >= 96 && event.keyCode <= 105 ) {
    //                             this.itemQuantityObject.value = +event.target.value;
    //                         }
    //                     }
    //                 }
    //             });
    //             this.itemQuantityObject.appendTo(this.itemQuantityField);
    //         }
    //     }
    // }

    // initItemReason() {
    //     return {
    //         create: () => {
    //             this.exceptionReasonField = document.createElement('input');
    //             return this.exceptionReasonField;
    //         },
    //         read: () => {
    //             if (this.exceptionReasonObject) {
    //                 return this.exceptionReasonObject.value;
    //             }
    //         },
    //         destroy: () => {
    //             this.exceptionReasonObject.destroy();
    //         },
    //         write: (args) => {

    //             this.exceptionReasonObject = new DropDownList({
    //                 dataSource: this.reasonDDL.filter(item => item.value !== 'R001' && item.value !== 'R005'),
    //                 fields: { value: 'value', text: 'text' },
    //                 placeholder: 'Select Reason',
    //                 value: args.rowData.reasonCode,
    //                 allowFiltering: true,
    //                 filterType: 'Contains',
    //                 change: (changeArgs: any) => {
    //                     args.rowData.reasonCode = changeArgs.itemData.value;
    //                 }
    //             });
    //             this.exceptionReasonObject.appendTo(this.exceptionReasonField);
    //         }
    //     }
    // }
}
